<template>
  <div>
    <v-card flat tile class="mt-4">
      <v-card-text class="py-0 mt-2 px-2">
        <v-container fluid class="px-0 pt-0">
          <v-row no-gutters>
            <v-col cols="12" lg="12" class="pa-0">
              <v-toolbar elevation="0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="grey" @click="showFilter =! showFilter">
                      <v-icon size="25" color="error" v-if="showFilter"> mdi-filter-off </v-icon>
                      <v-icon size="25" color="primary" v-else> mdi-filter-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ showFilter ? $t('hideFilter') : $t('showFilter') }}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="success" :disabled="!filterTableItems.length"
                      @click="generateXL">
                      <v-icon size="27"> mdi-file-excel </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('excel')}} </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="error" :disabled="!filterTableItems.length" @click="generatePDF">
                      <v-icon size="27"> mdi-file-powerpoint </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('pdf')}} </span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="openSendEmailDialog" :disabled="!filterTableItems.length">
                      <v-icon size="27"> mdi-email-send </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('share')}} </span>
                </v-tooltip>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" lg="3" class="pa-0 px-xl-3" v-if="showFilter">
              <v-card tile elevation="1" flat>
                <v-card-title class="primary white--text title py-3"> {{ $t('filterActions') }} </v-card-title>
                <v-card-text class="py-3">
                  <v-form ref="filterActionsForm" v-model="filterActionForm">
                    <v-layout row wrap class="px-2 pt-2">
                      <v-flex lg12 class="pb-2">
                        <v-select label="Select" outlined dense hide-details :items="modules" item-text="name" item-value="_id"
                        v-model="filterFormObj.module_id" clearable :no-data-text="$t('startTyping')"></v-select>
                      </v-flex>
                      <v-flex lg6 class="pr-1 pb-2">
                        <v-menu :close-on-content-click="false" v-model="filterFormObj.startDateMenu" transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field outlined hide-details :rules="$_requiredValidation" v-model="filterFormObj.start" :label="$t('from')" prepend-inner-icon="mdi-calendar" dense
                            readonly v-on="on" @focus="filterFormObj.startDatePicker = $formatter.formatDate(filterFormObj.start, 'DD.MM.YYYY', 'YYYY-MM-DD')"></v-text-field>
                          </template>
                          <v-date-picker @input="filterFormObj.startDateMenu = false; filterFormObj.start = $formatter.formatDate(filterFormObj.startDatePicker, 'YYYY-MM-DD', 'DD.MM.YYYY')"
                          v-model="filterFormObj.startDatePicker" style="height: 435px;"></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex lg6 class="pl-1 pb-2">
                        <v-menu :close-on-content-click="false" v-model="filterFormObj.endDateMenu" transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field outlined hide-details :rules="$_requiredValidation" v-model="filterFormObj.end" :label="$t('to')" prepend-inner-icon="mdi-calendar" dense
                            readonly v-on="on" @focus="filterFormObj.endDatePicker = $formatter.formatDate(filterFormObj.end, 'DD.MM.YYYY', 'YYYY-MM-DD')"></v-text-field>
                          </template>
                          <v-date-picker @input="filterFormObj.endDateMenu = false; filterFormObj.end = $formatter.formatDate(filterFormObj.endDatePicker, 'YYYY-MM-DD', 'DD.MM.YYYY')"
                          v-model="filterFormObj.endDatePicker" style="height: 435px;"></v-date-picker>
                        </v-menu>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="runReport" :loading="loading"> {{ $t('runReport') }} </v-btn>
                  <v-btn color="error" @click="filterTableItems = []"> {{ $t('clear') }} </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" :lg="showFilter ? 9 : 12" :class="[showFilter ? 'pl-2' : 'pl-0', 'py-0 pr-0']" ref="filteredResult">
              <v-card tile elevation="1" class="py-3" :loading="loading">
                <table class="v-datatable v-table theme--light table table-striped bordered--table table-bordered table-hover padding-remove dense_table custom-table-style" style="border-collapse: collapse; margin: 0 auto" width="98%">
                  <thead>
                    <tr>
                      <th v-for="(header, index) in filterTableHeaders" :key="index" :class="[index === 8 ? 'hideOnPdf' : '', 'text-center py-2']" width="16.66%">
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="filterTableItems.length">
                    <tr v-for="(item, index) in filterTableItems" :key="index" class="text-center">
                      <td width="15%"> {{ item.name }} </td>
                      <td width="5%"> {{ item.from }} </td>
                      <td width="5%"> {{ item.to }} </td>
                      <td> {{ item.description }} </td>
                      <td width="15%"> {{ item.status }} </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6" class="py-5 text-center"> {{ $t('noData') }} </td>
                    </tr>
                  </tbody>
                </table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog width="400" v-model="sendEmailDialog">
      <v-card>
        <v-card-title class="pt-3 pb-2 px-4 primary white--text"> {{ $t('sendReport') }} </v-card-title>
        <v-card-text class="pa-3">
          <v-form ref="sendEmailForm">
            <v-container fluid class="pa-0">
              <v-row no-gutters>
                <v-col cols="12" lg="12" class="pb-0">
                  <v-combobox v-model="sendEmailDialogForm.to"  hide-selected :rules="[validateEmailField]"
                    :label="$t('to')" multiple persistent-hint small-chips outlined dense hide-details></v-combobox>
                </v-col>
                <v-col cols="12" lg="12" class="pb-0">
                  <v-text-field outlined dense hide-details :label="$t('subject')" v-model="sendEmailDialogForm.subject"
                  :rules="$_requiredValidation" class="my-2"></v-text-field>
                </v-col>
                <v-col cols="12" lg="12">
                  <v-select outlined dense hide-details :label="$t('fileType')" v-model="sendEmailDialogForm.filetype"
                  :rules="$_requiredValidation" :items="listSentEmailOptions" item-text="title" item-value="value"></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="sendEmail" :loading="loading"> {{ $t('share') }} </v-btn>
          <v-btn color="error" @click="closeShareDialog"> {{ $t('cancel') }} </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      filterTableItems: [],
      filterFormObj: {},
      showFilter: true,
      filterActionForm: false,
      sendEmailDialog: false,
      sendEmailDialogForm: {},
      moduleData: null,
      fieldSearchKey: null,
      bookingObjs: [],
      customerSearchKey: null,
      customerObjs: [],
      modules: [],
      // Invoice data
      allInvoiceData: [],
      loading: false
    }
  },
  mounted () {
    this.getAllModules()
    this.filterFormObj = {
      start: moment().startOf('month').format('DD.MM.YYYY'),
      end: moment().endOf('month').format('DD.MM.YYYY')
    }
  },
  watch: {
    fieldSearchKey (value) {
      if (value) this.setModuleSearchData(value, 'bookingObjs', this.moduleData.name)
    },
    customerSearchKey (value) {
      if (value) this.setModuleSearchData(value, 'customerObjs', 'Account')
    }
  },
  computed: {
    ...mapState(['userDetails']),
    filterTableHeaders () {
      return [{
        text: this.$t('select'),
        value: 'module',
        sortable: false,
        align: 'center'
      }, {
        text: this.$t('from'),
        value: 'from',
        sortable: false,
        align: 'center'
      }, {
        text: this.$t('to'),
        value: 'to',
        sortable: false,
        align: 'center'
      }, {
        text: this.$t('description'),
        value: 'description',
        sortable: false,
        align: 'center'
      }, {
        text: this.$t('status'),
        value: 'status',
        sortable: false,
        align: 'center'
      }]
    },
    listSentEmailOptions () {
      return [{
        title: this.$t('onlyPdf'),
        value: 1
      }, {
        title: this.$t('onlyXlsx'),
        value: 2
      }, {
        title: this.$t('both'),
        value: 3
      }]
    }
  },
  methods: {
    openSendEmailDialog () {
      if (this.$refs.sendEmailForm) this.$refs.sendEmailForm.reset()
      this.sendEmailDialog = true
    },
    closeShareDialog () {
      this.$refs.sendEmailForm.reset()
      this.sendEmailDialog = false
    },
    validateEmailField (value) {
      if (value && Array.isArray(value) && value.length === 0) return 'Required.'
      if (value && value.length) {
        var regex = /\S+@\S+\.\S+/
        var result = true
        value.forEach(email => {
          if (!regex.test(email) && result) result = false
        })
        return !!result || 'Required.'
      }
      return !!value || 'Required.'
    },
    getAllModules () {
      const query = [{
        $match: {
          IsBookable: true
        }
      }]
      this.$bookingApi.execute('post', 'customerportalopenapi/module_query', query)
        .then((response) => {
          this.modules = response.data
        })
    },
    getAllInvoice () {
      this.$bookingApi.execute('get', 'bookings/get_tripletex_invoices')
        .then((response) => {
          this.allInvoiceData = response.data
        })
    },
    invoicedownload (item) {
      this.$bookingApi.execute('get', `bookings/download_tripletex_invoice/${item.id}`)
        .then((response) => {
          window.open(process.env.VUE_APP_URL + 'files/download/?fileName=' + response.data, '_blank')
        })
    },
    setModuleSearchData (value, array, moduleName) {
      this.$bookingApi.execute('get', `moduledata/${moduleName}/search?searchterm=${value}`)
        .then(response => {
          this[array] = response.data
        })
    },
    runReport () {
      this.filterFormObj.from = this.$formatter.formatDate(this.filterFormObj.start, 'DD.MM.YYYY', 'YYYY-MM-DDT00:00')
      this.filterFormObj.to = this.$formatter.formatDate(this.filterFormObj.end, 'DD.MM.YYYY', 'YYYY-MM-DDT23:59')
      this.filterFormObj.customerids = [this.userDetails._id]
      this.loading = true
      this.filterTableItems = []
      this.$bookingApi.execute('post', `customerportalopenapi/filter_bookings?module_id=${this.filterFormObj.module_id || ''}`, this.filterFormObj)
        .then(response => {
          this.filterTableItems = []
          response.data.forEach((booking) => {
            this.filterTableItems.push({
              name: booking.modulerecords && booking.modulerecords.length ? booking.modulerecords.map((item) => { return item.data.name }).join(', ') : '',
              customername: booking.customername,
              from: booking.data.from ? this.$formatter.formatDate(this.$formatter.fromUtcToLocal(booking.data.from, 'DD.MM.YYYYTHH:mm:ss'), 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') : '',
              to: booking.data.from ? this.$formatter.formatDate(this.$formatter.fromUtcToLocal(booking.data.from, 'DD.MM.YYYYTHH:mm:ss'), 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') : '',
              description: booking.data.description || '',
              status: booking.status_text || 'Unknown'
            })
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendEmail () {
      if (this.$refs.sendEmailForm.validate()) {
        const { to, subject, filetype } = this.sendEmailDialogForm
        this.sendEmailDialogForm.moduleid = this.filterFormObj.module_id || ''
        this.sendEmailDialogForm.share = { to, subject }
        if (filetype !== 1) this.sendEmailDialogForm.filter = this.filterFormObj
        if (filetype !== 2) this.sendEmailDialogForm.htmlcontent = { content: this.getPDFContent() }
        this.loading = true
        this.$bookingApi.execute('post', 'customerportalopenapi/share_booking_reports', this.sendEmailDialogForm)
          .then((response) => {
            this.sendEmailDialog = false
            this.sendEmailDialogForm = {}
            const isMailSentSuccessfully = response.data
            this.$root.$emit('snackbar', { snackbar: true, color: isMailSentSuccessfully ? 'success' : 'error', text: isMailSentSuccessfully ? this.$t('mailSentSuccess') : this.$t('mailSentFailed') })
          }).finally(() => {
            this.loading = false
          })
      }
    },
    generatePDF () {
      this.$store.commit('showLoader')
      const filename = 'test'
      var getBase64Content = { content: this.getPDFContent(), filename }
      this.loading = true
      this.$bookingApi.execute('post', 'files/generate_pdf', getBase64Content)
        .then((response) => {
          const fileName = response.data
          this.$store.commit('hideLoader')
          window.open(process.env.VUE_APP_URL + 'files/download/?fileName=' + fileName, '_blank')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPDFContent () {
      const styleLink = `<link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet" />
        <style>
        .report_header{ background: #354759 !important; color: white; }
        .table { font-family: initial; }
        .padding-remove { padding: 0 }
        .material-report > tbody > tr > td, .material-report > tbody > tr > th {
          padding: 1px;
        }
        tbody tr    { page-break-inside:avoid; }
        tbody tr td { word-break: break-word; }
        .hideOnPdf { visibility: hidden !important }
        </style>`
      const getHTMLContent = `<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width" />
        <meta charset="utf-8"/>${styleLink}</head><body><div class="container">${this.$refs.filteredResult.innerHTML}</div></body></html>`
      return btoa(unescape(encodeURIComponent(getHTMLContent)))
    },
    generateXL () {
      this.loading = true
      this.$bookingApi.execute('post', `customerportalopenapi/generate_booking_report_xlsx?module_id=${this.filterFormObj.module_id || ''}`, this.filterFormObj)
        .then((response) => {
          window.open(process.env.VUE_APP_URL + 'files/download/?fileName=' + response.data, '_blank')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style>
  .custom-table-style th, .custom-table-style td {
    border: 1px solid rgba(0,0,0,0.12) !important;
  }
  td, th {
    padding: 5px;
  }
</style>
