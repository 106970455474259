import { loadLanguageAsync } from '@/lang/config'
export default {
  viewTo (state, obj) {
    if (state.calendarSettings && state.calendarSettings.tenantname) {
      loadLanguageAsync(state.calendarSettings.defaultlanguage || 'no', state.calendarSettings.tenantname).then(() => {
        state.view = obj
      })
    } else {
      loadLanguageAsync(state.calendarSettings.defaultlanguage || 'no', state.domainName)
        .then(() => {
          state.view = obj
        })
      state.view = obj
    }
  },
  calendarSettings (state, obj) {
    state.calendarSettings = obj
  },
  setPortalInfo (state, obj) {
    state.portalInfo = obj
  },
  setUserDetails (state, obj) {
    state.userDetails = obj
  },
  enablePageLoader (state) {
    state.showPageLoader = true
  },
  disablePageLoader (state) {
    state.showPageLoader = false
  },
  portalConfig (state, data) {
    state.portalConfig = data
  },
  showDeleteDialog: (stateObj, data) => {
    stateObj.deletePayload = data
    stateObj.showDialog = true
  },
  hideDialog (stateObj) {
    stateObj.showDialog = false
  },
  showOrHideOverlayLoader (stateObj, status) {
    stateObj.showOverlayLoader = status
  },
  setDomainName (state, data) {
    state.domainName = data
  },
  setListOfUsers (state, data) {
    state.listOfUsers = data
  },
  setListOfChecklistTemplates (state, obj) {
    state.listOfChecklistTemplates = obj
  }
}
