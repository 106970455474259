<template>
  <v-container fluid class="pa-0">
    <v-row class="pa-1 py-2">
      <v-col cols="12" lg="12" class="py-0">
        <div>
          <v-row no-gutters v-if="fieldsLoading">
            <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
            <v-sheet class="mt-2">
              <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
            </v-sheet>
            </v-col>
          </v-row>
          <v-card outlined v-else class="mt-2">
            <v-card-title class="transparent font-weight-bold py-3" :style="xsOnly ? 'position: sticky; top: 0; z-index: 11;' : ''">
              <v-btn color="primary" outlined dark @click="$store.commit('viewTo', { page: 'portfolio' })" class="mr-5" fab x-small>
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              {{ !bookingId ? $t('createBooking') : $t('updateBooking') }}
              <v-spacer></v-spacer>
              <v-btn color="success" @click="confirmCreateUpdateHandler()" :disabled="bookingStatus === 'approved'" v-if="objectIds" :loading="loading">{{ bookingId ? $t('update') : $t('book') }}</v-btn>
            </v-card-title>
            <v-progress-linear indeterminatecolor="success" height="5px" :active="loader"></v-progress-linear>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="9">
                  <v-row no-gutters>
                    <v-col cols="12" lg="3">
                      <v-select outlined dense :label="formDetails.ismultiselect ? $t(modulePlural) : $t(moduleSingular)" :multiple="formDetails.ismultiselect"
                        v-model="objectIds" :items="listOfModuleObjects" :disabled="!objectSelection" item-text="data.name" item-value="_id"
                        @change="loadMetaData()" hide-details :rules="formDetails.ismultiselect ? $_multiSelectValidation : $_requiredValidation">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2 mb-2"></v-divider>
                  <module-render :listOfFields="listOfFields" :fields="fields" ref="formReferences" :moduleName="moduleName"
                  :isBooking="true"></module-render>
                </v-col>
                <v-col cols="12" lg="3">
                  <module-meta :key="renderMeta" :showProfile="true"></module-meta>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </div>
      </v-col>
      <v-dialog v-model="rulesErrorMsgDialog" width="700px">
        <v-card>
          <v-card-title :style="'background-color:#e65c5c;color:white'">
            {{ $t('bookingFailed') }}
            <v-spacer></v-spacer>
            <v-icon color="white" @click="rulesErrorMsgDialog = false">mdi-close-circle-outline</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list dense>
            <template v-for="(item, index) in listOfErrorMessages">
              <v-list-item :key="index">
                <v-list-item-content>
                  <v-list-item-title v-html="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="bookingConfirmDialog" width="500" persistent>
        <v-card>
          <v-card-title class="primary darken-1 white--text pa-4 pb-3">
            <span class="body-1 font-weight-medium"> {{ $t('confirmation') }} </span>
            <v-spacer></v-spacer>
            <v-icon color="white" size="20" @click="bookingConfirmDialog = false">mdi-close-circle-outline</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-5 text-center">
            <span class="font-weight-medium h3"> {{ $t('bookingConfirmation') }} </span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-flex class="text-center">
              <v-btn color="primary" @click="confirmedBookingHandler" class="mr-2">{{ $t('yes') }}</v-btn>
              <v-btn color="error" @click="bookingConfirmDialog = false">{{ $t('no') }}</v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import { checklistApi } from '@/plugins/axios_settings'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  data () {
    return {
      moduleName: '',
      moduleObj: {
        _id: ''
      },
      fieldsLoading: false,
      objectSelection: true,
      bookingId: null,
      listOfFields: [],
      fields: {},
      // items: [],
      loading: false,
      checklistId: '',
      formId: '',
      listOfChecklist: [],
      model: 'tab-0',
      objectIds: '',
      isBooking: true,
      listOfModuleObjects: [],
      rulesErrorMsgDialog: false,
      listOfErrorMessages: [],
      formDetails: {},
      renderMeta: 0,
      bookingStatus: '',
      bookingConfirmDialog: false,
      bookingFormId: null,
      loader: false,
      from: null,
      to: null,
      moduleSingular: '',
      modulePlural: '',
      attachmentsChecklist: [],
      restrictions: []
    }
  },
  components: {
    ModuleRender: () => import('@/components/modules/ModuleFormRender'),
    'module-meta': () => import('@/components/ModuleMeta.vue')
  },
  computed: {
    ...mapState(['view', 'userDetails', 'calendarSettings'])
  },
  mounted () {
    this.moduleName = this.calendarSettings.modulename || ''
    this.moduleSingular = `Module_${this.calendarSettings.modulename}`
    this.moduleplural = `Module_Plural_${this.calendarSettings.modulename} (s)`
    this.checkHasLoggedIn()
    this.$root.$on('downloadBookingAttachment', (query) => {
      this.$bookingApi.execute('get', `bookingsopenapi/download_file/${this.bookingId}${query}`).then((response) => {
        window.open(`${process.env.VUE_APP_URL}/files/download?fileName=${response.data}`, '_blank')
      })
    })
    this.$root.$on('deleteBookingAttachment', (query) => {
      this.$bookingApi.execute('delete', `bookingsopenapi/delete_file/${this.bookingId}${query}`)
    })
    this.$root.$on('loader', (data) => {
      this.loader = data
    })
    this.$root.$on('loadObjects', (data) => {
      if (data) {
        this.objectSelection = true
        this.getListOfModulesObjects(data.from, data.to, this.bookingFormId)
      } else this.objectSelection = false
    })
  },
  methods: {
    checkHasLoggedIn () {
      const hasUserId = this.userDetails._id
      if (hasUserId) {
        this.bookingId = this.view.meta?.bookingID || ''
        this.getForm()
      } else this.$store.commit('viewTo', { page: 'login' })
    },
    getFieldsForForm (listOfChecklist) {
      this.fieldsLoading = true
      this.$bookingApi.execute('get', 'bookingsopenapi/get_form_fields').then(result => {
        if (result.data) {
          this.bookingFormId = result.data[0].bookingformid
          const filteredFormFields = result.data.filter((item) => !item.adminonlyfield)
          this.constructModuleFields(filteredFormFields)
          if (this.bookingId) {
            this.getSingleRecordHandler(listOfChecklist)
          } else {
            this.listOfChecklist = listOfChecklist
            setTimeout(() => {
              this.listOfChecklist.forEach((x, i) => {
                this.model = `tab-${i}`
              })
              this.model = 'tab-0'
            }, 500)
          }
          this.fieldsLoading = false
        }
      })
    },
    loadMetaData () {
      const ids = this.formDetails.ismultiselect ? this.objectIds : (Array.isArray(this.objectIds) ? [this.objectIds[0]] : [this.objectIds])
      this.$root.$emit('getModuleId', ids)
    },
    getSingleRecordHandler (listOfChecklist) {
      this.fieldsLoading = true
      this.$bookingApi.execute('get', `bookingsopenapi/get_booking/${this.bookingId}`).then(response => {
        this.bookingStatus = response.data.status
        this.customizeValue(response.data.data)
        setTimeout(() => {
          this.objectIds = this.formDetails.ismultiselect ? response.data.objectids : response.data.objectids[0]
        }, 250)
        this.getChecklistValues(listOfChecklist)
        const { bookingID } = this.view.meta
        if (bookingID) {
          const { from, to } = response.data.data
          const fromTo = {
            from: this.$formatter.formatDate(from, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DDTHH:mm'),
            to: this.$formatter.formatDate(to, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DDTHH:mm')
          }
          this.getListOfModulesObjects(fromTo.from, fromTo.to, this.formDetails._id)
        }
      }).finally(() => {
        this.fieldsLoading = false
      })
    },
    getChecklistValues (listOfChecklist) {
      this.$bookingApi.execute('get', `bookingsopenapi/get_checklists/${this.bookingId}`).then(response => {
        const cheklistIds = response.data.map(x => x.checklist_id)
        // checklists/get_multiple
        checklistApi.post('checklists/get_multiple', cheklistIds).then(result => {
          if (result && result.data.length > 0) {
            listOfChecklist.forEach((item) => {
              const checklistId = result.data.find(x => x.form_id === item.form_id)
              item.data = checklistId && checklistId.data ? JSON.parse(checklistId.data) : {}
              item.checklist_id = checklistId ? checklistId._id : null
            })
            setTimeout(() => {
              this.listOfChecklist = listOfChecklist
              this.listOfChecklist.map((x, i) => {
                this.model = `tab-${i}`
              })
              this.model = 'tab-0'
            })
          }
        })
      })
    },
    getListOfModulesObjects (from, to, formId) {
      this.setRestrictions({ from, to })
      const isValid = moment(from, 'YYYY-MM-DDTHH:mm').isBefore(moment(to, 'YYYY-MM-DDTHH:mm'))
      const { modulename, calendarsetting } = this.calendarSettings
      if (modulename && isValid) {
        let url = `bookingsopenapi/get_module_data/${modulename}`
        if (from && to && formId) {
          if (calendarsetting.allowbookingonsamedateandtiming) url = `bookingsopenapi/get_module_data/${modulename}`
          else url = `bookingsopenapi/get_module_data/${modulename}?formId=${formId || ''}&&from=${from}&&to=${to}`
        }
        if (this.bookingId) url += calendarsetting.allowbookingonsamedateandtiming ? `?booking_id=${this.bookingId}` : `&booking_id=${this.bookingId}`
        this.loader = true
        this.$bookingApi.execute('get', url)
          .then(response => {
            if (response.data && response.data.length && this.formDetails && this.formDetails.moduleobjectids.length) {
              const list = response.data.filter(element => this.formDetails.moduleobjectids.includes(element._id))
              this.listOfModuleObjects = this.$formatter.cloneVariable(list)
              if (this.listOfModuleObjects.length) {
                const { roomId } = this.view.meta
                let result
                // logic to keep selected objects when time changed
                let payload = true
                const objectIds = !!this.objectIds && Array.isArray(this.objectIds) ? this.objectIds : [this.objectIds]
                objectIds.forEach((room) => {
                  if (payload) payload = !!this.listOfModuleObjects.find((item) => item._id === room)
                })
                if (!payload) { // this condition will satisfy when the selected room is not in the received list
                  this.objectIds = this.formDetails.ismultiselect ? [] : null
                  if (roomId && Array.isArray(roomId)) {
                    result = []
                    roomId.forEach((room) => {
                      var obj = this.listOfModuleObjects.find((item) => item._id === room)
                      if (obj) result.push(obj._id)
                    })
                    this.objectIds = this.formDetails.ismultiselect ? result : result[0]
                  } else if (roomId) {
                    result = this.listOfModuleObjects.find((x) => x._id === roomId)
                    if (result) this.objectIds = this.formDetails.ismultiselect ? [result._id] : result._id
                    else {
                      this.objectIds = this.formDetails.ismultiselect ? [this.listOfModuleObjects[0]._id] : this.listOfModuleObjects[0]._id
                      this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: `${modulename} is changed due to selected ${modulename}'s unavailability!` })
                    }
                  } else {
                    this.objectIds = this.formDetails.ismultiselect ? [this.listOfModuleObjects[0]._id] : this.listOfModuleObjects[0]._id
                  }
                }
                setTimeout(() => {
                  this.$root.$emit('getModuleId', this.objectIds)
                }, 200)
                this.renderMeta++
              } else {
                this.objectIds = this.formDetails.ismultiselect ? [] : null
                setTimeout(() => {
                  this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: `${this.$t('no')} ${modulename} ${this.$t('available_at_selected_dateOrTime')}!` })
                  this.$root.$emit('getModuleId', null)
                }, 200)
              }
            } else {
              this.objectIds = this.formDetails.ismultiselect ? [] : null
              setTimeout(() => {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: `${this.$t('no')} ${modulename} ${this.$t('available_at_selected_dateOrTime')}!` })
                this.$root.$emit('getModuleId', null)
              }, 200)
            }
            this.loader = false
          })
      } else {
        // this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'Not allowed to enter same DateTime in both from & to input fields!' })
      }
    },
    getForm () {
      this.$bookingApi.execute('get', 'bookingsopenapi/get_form').then(response => {
        this.formDetails = response.data
        this.$bookingApi.execute('get', 'bookingsopenapi/get_checklist_templates')
          .then(response => {
            const { selectedDatesForBooking, bookingID } = this.view.meta
            this.getFieldsForForm(response.data)
            if (selectedDatesForBooking) {
              this.from = selectedDatesForBooking.from
              this.to = selectedDatesForBooking.to
              const from = this.$formatter.formatDate(selectedDatesForBooking.from, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DDTHH:mm')
              const to = this.$formatter.formatDate(selectedDatesForBooking.to, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DDTHH:mm')
              if (!bookingID) this.getListOfModulesObjects(from, to, this.formDetails._id)
            } else {
              const today = moment().format('DD.MM.YYYY')
              this.from = today + ' ' + moment().add(1, 'h').format('HH:00')
              this.to = today + ' 23:59'
              if (!bookingID) this.getListOfModulesObjects((moment().format('YYYY-MM-DD') + 'T00:00'), (moment().format('YYYY-MM-DD') + 'T23:59'), this.formDetails._id)
            }
          })
      })
    },
    async setRestrictions (fromTo) {
      this.restrictions = []
      this.restrictions = await this.constructRestrictionList(fromTo, this.calendarSettings.calendarsetting, false)
    },
    confirmCreateUpdateHandler () {
      let isValid = this.$formatter.validateFormat(this.fields.from, 'DD.MM.YYYY HH:mm') && this.$formatter.validateFormat(this.fields.to, 'DD.MM.YYYY HH:mm')
      const model = {
        from: this.$formatter.formatDate(this.fields.from, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DDTHH:mm'),
        to: this.$formatter.formatDate(this.fields.to, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DDTHH:mm')
      }
      if (isValid) isValid = moment(model.from, 'YYYY-MM-DDTHH:mm').isBefore(moment(model.to, 'YYYY-MM-DDTHH:mm'))
      // const dateTime = this.$formatter.formatDate(model.from, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD HH:mm')
      if (this.$refs.formReferences.$refs.validateForm.validate() && (this.formDetails.ismultiselect ? this.objectIds && this.objectIds.length : this.objectIds)) {
        if (model && isValid && moment(model.from, 'YYYY-MM-DDTHH:mm').isSameOrAfter(moment().format('YYYY-MM-DDTHH:mm'))) {
          const { allowBooking, error } = this.$formatter.checkTheDateAndTimeAvailablity(model, this.restrictions)
          if (allowBooking) {
            if (!this.bookingId) this.bookingConfirmDialog = true
            else this.createOrUpdateBookingHandler()
          } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: error?.title || 'restrictedDateOrTime_ValidationMsg' })
        } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'notAllowedToBookOnthisDateorTimes' })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    confirmedBookingHandler () {
      this.bookingConfirmDialog = false
      this.createOrUpdateBookingHandler()
    },
    async createOrUpdateBookingHandler () {
      this.$store.commit('showOrHideOverlayLoader', true)
      var result = {}
      const modelData = this.reconstructModuleFieldValues()
      let model = {
        customerid: this.userDetails._id,
        bookingformid: this.listOfFields[0].bookingformid,
        data: modelData,
        objectids: this.formDetails.ismultiselect ? this.objectIds : [this.objectIds],
        status: this.bookingId ? this.bookingStatus : undefined,
        _id: this.bookingId ? this.bookingId : undefined
      }
      this.listOfFields.forEach(x => {
        if (x.type === 17) {
          for (var i in model.data) {
            if (i === x.name) {
              const files = model.data[i]
              if (files && files.length) {
                var formData = new FormData()
                files.forEach(file => {
                  formData.append(file.name, file)
                })
                result[x.name] = formData
              }
            }
          }
        }
      })
      await this.uploadAttachments(result, model)
        .then((data) => {
          model = data
          this.$bookingApi.execute(this.bookingId ? 'put' : 'post', this.bookingId ? `bookingsopenapi/update_booking/${this.bookingId}` : 'bookingsopenapi/create_booking', model).then((response) => {
            if (response.data.issbmitted) {
              this.saveChecklist(response.data.booking._id)
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.bookingId ? 'updateSuccess' : 'addedSucess' })
              this.$store.commit('viewTo', { page: 'portfolio' })
            } else {
              this.listOfErrorMessages = response.data.errors
              this.rulesErrorMsgDialog = true
            }
          }).finally(() => {
            this.$store.commit('showOrHideOverlayLoader', false)
          })
        })
    },
    uploadAttachments (result, model) {
      return new Promise((resolve) => {
        var attachmentFieldsCount = Object.keys(result).length
        if (!attachmentFieldsCount) resolve(model)
        for (var i in result) {
          this.$bookingApi.execute('post', 'bookingsopenapi/upload_files', result[i]).then(response => {
            model.data[`${i}`] = response.data
            if (model.data[`${i} oldAttachments`] && model.data[`${i} oldAttachments`].length) {
              model.data[`${i}`] = [...model.data[`${i} oldAttachments`], ...model.data[`${i}`]]
              delete model.data[`${i} oldAttachments`]
            }
            model.data[`${i}`] = JSON.stringify(model.data[`${i}`])
            --attachmentFieldsCount
            if (attachmentFieldsCount === 0) resolve(model)
          })
        }
      })
    },
    getAllValuesFromModule (model, index) {
      const options = []
      this.$bookingApi.execute('get', `bookingsopenapi/get_module_data/${model.default_value.selectedModule}`).then(response => {
        if (response.data.length > 0) {
          response.data.forEach(element => {
            options.push({ name: element.data.name, _id: element._id, ...element.data })
          })
        }
        this.listOfFields[index].default_value.options = options
      })
    },
    saveChecklist (bookingId) { // Getting checklists data and saving
      const moduleName = this.calendarSettings.modulename
      const formValues = []
      if (this.listOfChecklist.length > 0) {
        this.listOfChecklist.forEach((element) => {
          const data = {}
          data.form_id = element.form_id
          data.title = element.name
          data._id = ''
          data.checklist_template_id = element._id
          data.data = ''
          formValues.push(data)
        })
        checklistApi.post('checklists/bulk_save', formValues).then(response => {
          if (!this.bookingId) {
            const model = []
            response.data.forEach((item, index) => {
              model.push({
                booking_id: bookingId,
                checklist_id: item._id,
                module: moduleName,
                name: item.title,
                form_id: item.form_id,
                checklist_template_id: formValues[index].checklist_template_id
              })
            })
            this.$bookingApi.execute('post', 'bookingsopenapi/create_checklist', model)
          }
        })
      }
    },
    uploadDocumentHandler (propId, files, checklistId) {
      var formData = new FormData()
      if (files != null && files.length > 0) {
        for (const file of files) {
          formData.append(file.name, file)
        }
        checklistApi.post(`documents/upload?checklist_id=${checklistId}&property_id=${propId}`, formData)
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('downloadBookingAttachment')
    this.$root.$off('deleteBookingAttachment')
    this.$root.$off('loader')
    this.$root.$off('loadObjects')
  }
}
</script>
<style scoped>
html:not([data-scroll='0']) body {
 background: red;
}
</style>
