/* eslint-disable camelcase */
const componentStatusList = [
  {
    text: 'yes',
    value: 'yes'
  },
  {
    text: 'no',
    value: 'no'
  },
  {
    text: 'notApplicable',
    value: 'not_applicable'
  }
]
const listOfFormTypes = [
  {
    text: 'Text field',
    value: 1
  }, {
    text: 'Text area',
    value: 2
  }, {
    text: 'Checkbox',
    value: 3
  }, {
    text: 'Radio',
    value: 4
  }, {
    text: 'Select',
    value: 5
  }, {
    text: 'Date',
    value: 6
  }, {
    text: 'Time',
    value: 7
  }, {
    text: 'Switch',
    value: 8
  }, {
    text: 'File',
    value: 9
  }, {
    text: 'Survey Component',
    value: 10
  }, {
    text: 'Divider',
    value: 11
  }, {
    text: 'Grid',
    value: 12
  }, {
    text: 'Signature',
    value: 13
  }, {
    text: 'Panel',
    value: 14
  }, {
    text: 'Invent Component',
    value: 15
  }, {
    text: 'DataGrid',
    value: 18
  }, {
    text: 'HTML',
    value: 16
  }, {
    text: 'Label',
    value: 17
  }, {
    text: 'Advanced Survey Component',
    value: 19
  }
]

const standardFields = [{
  type: 1,
  name: 'Text field',
  icon: 'mdi-textbox'
}, {
  type: 2,
  name: 'Text area',
  icon: 'mdi-format-align-left'
}, {
  type: 3,
  name: 'Checkbox',
  icon: 'mdi-checkbox-marked-outline'
}, {
  type: 4,
  name: 'Radio',
  icon: 'mdi-radiobox-marked'
}, {
  type: 5,
  name: 'Select',
  icon: 'mdi-playlist-check'
}, {
  type: 6,
  name: 'Date',
  icon: 'mdi-calendar-today'
}, {
  type: 7,
  name: 'Time',
  icon: 'mdi-calendar-clock'
}, {
  type: 9,
  name: 'File',
  icon: 'mdi-attachment'
}, {
  type: 11,
  name: 'Divider',
  icon: 'mdi-minus'
}, {
  type: 12,
  name: 'Grid',
  icon: 'mdi-minus'
}, {
  type: 18,
  name: 'DataGrid',
  icon: 'mdi-view-grid-plus'
}, {
  type: 16,
  name: 'HTML',
  icon: 'mdi-language-html5'
}, {
  type: 17,
  name: 'Label',
  icon: 'mdi-label-outline'
}]
const advancedFields = [{
  type: 10,
  name: 'Survey Component',
  icon: 'mdi-view-compact',
  isAdvanced: true
}, {
  type: 13,
  name: 'Signature',
  icon: 'mdi-message-draw',
  isAdvanced: true
}, {
  type: 14,
  name: 'Panel',
  icon: 'mdi-view-agenda',
  isAdvanced: true
}, {
  type: 15,
  name: 'Invent Component',
  icon: 'mdi-newspaper-variant-outline',
  isAdvanced: true
}, {
  type: 19,
  name: 'Advanced Survey Component',
  icon: 'mdi-view-compact',
  isAdvanced: true
}]
standardFields.forEach(element => {
  element.isStandard = true
})
/* DataGrid Component additions */
const fieldTypeConstants = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  LABEL: 'label',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  RADIOBOX: 'radiobox'
}
const dataGridFieldTypes = [
  {
    text: 'Text',
    value: fieldTypeConstants.STRING
  }, {
    text: 'Number',
    value: fieldTypeConstants.NUMBER
  }, {
    text: 'Checkbox',
    value: fieldTypeConstants.CHECKBOX
  }, {
    text: 'Label',
    value: fieldTypeConstants.LABEL
  }, {
    text: 'Date',
    value: fieldTypeConstants.DATE
  }, {
    text: 'Select',
    value: fieldTypeConstants.SELECT
  }, {
    text: 'Radiobox',
    value: fieldTypeConstants.RADIOBOX
  }
]
/* --- */
export { componentStatusList, listOfFormTypes, standardFields, advancedFields, dataGridFieldTypes, fieldTypeConstants }
