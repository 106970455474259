import { bookingApi, checklistApi } from '@/plugins/axios_settings'
import cookie from 'vue-cookie'
import state from './state'
export default {
  getPortalInfo ({ commit }, refId) {
    return new Promise((resolve, reject) => {
      bookingApi.get(`externalportals/get_portal_info/${refId}`)
        .then((response) => {
          bookingApi.defaults.headers[response.data.type === 'booking_portal' ? 'x-booking-ui-hv' : 'x-cp-auth-token'] = response.data.code
          commit('setPortalInfo', response.data)
          resolve()
        })
    })
  },
  getCalendarSettings ({ commit }, portalType) {
    return new Promise((resolve, reject) => {
      bookingApi.get('bookingsopenapi/get_calender_setting')
        .then((result) => {
          commit('calendarSettings', result.data)
          const { showportfoliopagefirst } = result.data.calendarsetting
          const loggedInUserDetails = cookie.get(`${process.env.VUE_APP_NAME}_BOOKING_APP_USERDETAIL`)
          if (loggedInUserDetails) {
            commit('setUserDetails', JSON.parse(loggedInUserDetails))
            commit('viewTo', { page: portalType === 'booking_portal' ? 'portfolio' : 'dashboard' })
          } else {
            commit('viewTo', { page: portalType !== 'booking_portal' ? 'dashboard' : showportfoliopagefirst ? 'portfolio' : 'login' })
          }
          commit('disablePageLoader')
          resolve(result.data)
        })
    })
  },
  deleteHandler ({ commit }, model) {
    return new Promise((resolve, reject) => {
      const modelObj = { data: { ids: model.ids } }
      const deleteAPI = model.fromCheckList ? checklistApi : bookingApi
      deleteAPI.delete(model.url, modelObj)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getListOfUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      const url = state.portalInfo.type === 'booking_portal' ? 'bookingsopenapi/get_users' : 'customerportalopenapi/get_users'
      bookingApi.get(url)
        .then((response) => {
          commit('setListOfUsers', response.data)
          resolve()
        })
    })
  }
}
