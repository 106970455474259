export default {
  methods: {
    // Common validation rules handler
    rulesHandler (item, value) {
      if (item.validation) {
        if (!value) return this.getValidation(item.validation)
        else if (item.validationscript) {
          let validationScript = this.decodeStringTobase64(item.validationscript)
          validationScript = `var valid;\n${validationScript}\n return valid`
          // eslint-disable-next-line
          let validationFunction = new Function('instance', 'input', 'data', validationScript)
          const result = validationFunction(this, value, this.fields)
          return result ? [result] : []
        }
      }
    },
    getValidation (validate, type = null) {
      if (validate === 'required') {
        if (type) return this.$_multiSelectValidation
        else return this.$_requiredValidation
      } else return null
    }
  }
}
