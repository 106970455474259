<template>
  <div class="pt-4">
    <form-render :checklistTemplateObj="checklistTemplateObj" :formId="checklistObj.formId" :module="module" propId="record_id" :checklistId="checklistObj.checklistId" :formName="checklistObj.formName" :itemId="itemId" v-if="!$formatter.isEmptyObject(checklistTemplateObj)"></form-render>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  props: ['checklistObj', 'moduleId', 'bookingId', 'alreadySavedChecklistIds', 'fromBooking', 'moduleName'],
  data () {
    return {
      modelObj: {},
      module: this.moduleId,
      filterObj: 'ORDER_CHECKLIST_INDEX',
      reInit: 0,
      payload: {},
      dialog: false,
      itemId: '',
      loading: false,
      checklistTemplateObj: {}
    }
  },
  components: {
    'form-render': () => import('@/components/checklist/Actions')
  },
  computed: {
    ...mapGetters(['getuserDetails', 'userDetails']),
    ...mapState(['calendarSettings'])
  },
  mounted () {
    this.checklistTemplateObj = this.$formatter.cloneVariable(this.checklistObj)
    this.$eventBus.$on('chemicalModel', (data) => {
      this.modelObj = data
      this.reInit++
    })
    // this.$eventBus.$on('checklistDialog', (data) => {
    //   this.formId = data.form_id
    //   this.checklistId = null
    //   this.formName = data.form_name
    //   this.dialog = true
    // })
    this.$eventBus.$on('updatechecklistDialog', (data) => {
      this.dialog = true
      this.checklistTemplateObj = data
    })
    this.$eventBus.$on('closeChecklistDialog', () => {
      this.$eventBus.$emit('clearChecklist')
      // this.$root.$emit('closeChecklistDialog')
    })
    this.$eventBus.$on('setChecklistId', (data, canClose) => {
      const currentData = this.$formatter.cloneVariable(this.checklistTemplateObj)
      // this.checklistTemplateObj = data
      const model = []
      // if (!this.alreadySavedChecklistIds.includes(data.form_id)) {
      model.push({
        booking_id: this.bookingId,
        checklist_id: data.checklist_id,
        module: this.moduleName,
        name: currentData.name,
        form_id: data.form_id,
        checklist_template_id: currentData.checklist_template_id,
        pdftemplatepath: currentData.pdftemplatepath,
        templateoriginalname: currentData.templateoriginalname,
        _id: currentData._id || undefined
      })
      this.$bookingApi.execute('post', 'bookingsopenapi/create_checklist', model).then((response) => {
        if (!model._id) this.checklistTemplateObj._id = response.data[0]._id
        this.$root.$emit('updatedBookingChecklist', canClose)
      })
      // }
      // else {
      //   console.log(this)
      //   this.$root.$emit('updatedBookingChecklist', canClose)
      // }
      // } else if (canClose) this.closeDialogHandler()
    })
    this.$eventBus.$on('showLoading', () => {
      this.loading = true
    })
    this.$eventBus.$on('hideLoading', () => {
      this.loading = false
    })
  },
  methods: {
    closeDialogHandler () {
      this.dialog = false
      this.$eventBus.$emit('clearChecklist')
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('chemicalModel')
    this.$eventBus.$off('checklistDialog')
    this.$eventBus.$off('updatechecklistDialog')
    this.$eventBus.$off('closeChecklistDialog')
    this.$eventBus.$off('setChecklistId')
    this.$eventBus.$off('hideLoading')
    this.$eventBus.$off('showLoading')
  }
}
</script>

<style>

</style>
