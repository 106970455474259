<template>
  <div>
    <v-sheet :class="[`${ xsOnly ? 'px-0' : '' }`, isCustomerPortal ? 'px-2 py-1' : '']" :elevation="0"
    style="font-size: smaller !important;" v-show="renderCalendar">
      <v-card elevation="2" tile>
        <v-card-title :class="[isCustomerPortal ? 'pt-2 pb-3' : 'py-2', 'px-3 primary']">
          <v-container fluid class="pa-0">
            <v-row>
              <v-col cols="12" lg="12" xl="12" sm="12" md="12" :class="[$vuetify.breakpoint.sm ? '' : 'text-center', 'px-0 py-2']">
                <v-layout align-center class="mt-1 mt-xl-2 pa-2 pa-lg-0">
                  <v-flex class="mx-xl-4 mx-lg-2 mx-sm-1 text-left" lg2 md2 xl2 sm2 xs2>
                    <v-btn v-if="xsOnly" fab x-small elevation="1" outlined dark @click="today">
                      <v-icon dark size="18"> mdi-calendar-today </v-icon>
                    </v-btn>
                    <v-btn v-else @click="today" outlined dark :class="isCustomerPortal ? '' : 'mb-n10 mb-sm-n7 mb-md-n4 mb-lg-n6 mb-xl-n8'"> {{ $t('today') }} </v-btn>
                    <v-tooltip bottom v-if="!xsOnly">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="xsOnly" fab x-small elevation="1" outlined dark class="ml-2" absolute
                        style="margin: 2px 0 0 0;" v-on="on" v-bind="attrs" @click="refreshCalendar">
                          <v-icon dark size="18"> mdi-calendar-refresh-outline </v-icon>
                        </v-btn>
                        <v-btn small outlined dark :class="isCustomerPortal ? 'ml-2 pt-1 mt-0' : 'ml-sm-2 pt-1 mt-0'" height="36"
                        v-on="on" v-bind="attrs" v-else absolute @click="refreshCalendar">
                          <v-icon> mdi-calendar-refresh-outline </v-icon>
                        </v-btn>
                      </template>
                      <span> {{ $t('refresh') }} </span>
                    </v-tooltip>
                  </v-flex>
                  <v-flex class="text-center mt-lg-1" lg7 md7 xl7 sm7 xs10>
                    <v-btn class="mx-1 mr-lg-1 mr-xl-0" outlined fab color="white" x-small @click="previous" elevation="1">
                      <v-icon> mdi-chevron-left </v-icon>
                    </v-btn>
                    <span class="font-weight-medium white--text subtitle-1 mx-0 mx-lg-1 ml-xl-3 mr-xl-2"> {{ calendarTitle }} </span>
                    <v-btn class="mx-1" outlined fab color="white" x-small @click="next" elevation="1">
                      <v-icon> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex lg3 md3 xl3 xs1 sm3 :class="[xsOnly ? 'pr-8' : '', 'mx-xl-4 mx-lg-2 mx-sm-1']">
                    <v-select dark :items="listOfViews" v-model="calendarView" outlined dense hide-details color="primary"
                    :class="isCustomerPortal ? '' : 'my-1 mb-n10 mb-sm-n9 mb-md-n4 mb-lg-n6 mb-xl-n8'" :label="$t('view')" v-if="!xsOnly"></v-select>
                    <v-menu bottom origin="center center" transition="scale-transition" v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark v-bind="attrs" v-on="on" fab x-small outlined elevation="1">
                          <v-icon dark> mdi-eye </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="(item, i) in listOfViews" :key="i" @click="calendarView = item.value">
                          <v-list-item-title> {{ item.text }} </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-flex>
                </v-layout>
                <v-layout :class="xsOnly ? 'pb-1' : 'mt-2 mt-sm-0 mt-xl-1'" :style="xsOnly ? 'width: 95vw; overflow-x: auto;' : ''">
                  <v-flex class="mx-2" lg2 md2 sm2 xl2></v-flex>
                  <v-flex v-if="!isCustomerPortal" class="d-flex align-center justify-center white--text body-1 pl-16 pl-sm-2 px-lg-0 pl-lg-4" lg7 md7 sm7 xl7>
                    <div>
                      <v-badge color="white" content="2" offset-x="20" offset-y="21" overlap>
                        <template #badge>
                          <span class="black--text font-weight-bold"> {{ specificEventCounts.unavailable || 0 }} </span>
                        </template>
                        <v-chip class="ma-2 py-3" color="green darken-3" dark x-small>
                          <span> {{ $t('unavailableDates')}} </span>
                        </v-chip>
                      </v-badge>
                    </div>
                    <div>
                      <v-badge color="white" content="2" offset-x="20" offset-y="21" overlap>
                        <template #badge>
                          <span class="black--text font-weight-bold"> {{ specificEventCounts.restricted_dates || 0 }} </span>
                        </template>
                        <v-chip class="ma-2 py-3" color="grey darken-2" dark x-small>
                          <span> {{ $t('restricted_dates')}} </span>
                        </v-chip>
                      </v-badge>
                    </div>
                    <div>
                      <v-badge color="white" content="2" offset-x="20" offset-y="21" overlap>
                        <template #badge>
                          <span class="black--text font-weight-bold"> {{ specificEventCounts.holidays || 0 }} </span>
                        </template>
                        <v-chip class="ma-2 py-3" color="orange darken-2" dark x-small>
                          <span> {{ $t('holidays')}} </span>
                        </v-chip>
                      </v-badge>
                    </div>
                    <div class="mr-2">
                      <v-badge color="white" content="2" offset-x="20" offset-y="21" overlap>
                        <template #badge>
                          <span class="black--text font-weight-bold"> {{ specificEventCounts.booked || 0 }} </span>
                        </template>
                        <v-chip class="ma-2 py-3" color="blue darken-3" dark x-small>
                          <span> {{ $t('booked_dates')}} </span>
                        </v-chip>
                      </v-badge>
                    </div>
                  </v-flex>
                  <v-flex lg3 md3 sm3 xl3></v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text class="admin-calendar pa-2">
          <full-calendar id="user_calendar" :config="config" :events="events" ref="calendar" :key="calendarKey"></full-calendar>
        </v-card-text>
      </v-card>
    </v-sheet>
    <v-dialog v-model="userDialog" width="1500" persistent :key="reInit">
      <v-card>
        <v-card-title :style="moduleObj.color ? `background-color: ${moduleObj.color}` : 'primary'"
        :class="[moduleObj.color ? '' : 'primary', canEdit ? '' : 'py-3', 'mx-auto white--text px-3 py-1']" flat>
          <v-container fluid class="pa-0">
            <v-row no-gutters align="center">
              <v-col cols="12" lg="4" md="4" sm="6" class="pl-lg-1 font-weight-bold text-capitalize"> {{ $t('booking') }} <v-chip class="mx-2"> {{ getStatus(moduleObj.status) }} </v-chip></v-col>
              <v-col cols="12" lg="7" md="6" sm="4" class="text-right d-flex justify-end">
                <v-select style="max-width: 300px" outlined hide-details dense color="black" background-color="white"
                :items="listOfUserStatus" v-if="canEdit" item-text="name" item-value="_id" @change="bookingCancelDialog = true"
                v-model="status"></v-select>
              </v-col>
              <v-col cols="12" lg="1" md="2" sm="2" class="text-right">
                <v-tooltip bottom v-if="!isCustomerPortal">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn fab icon v-on="on" v-bind="attrs" @click="editBooking" v-show="canEdit">
                      <v-icon class="white--text"> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span> {{ $t('edit') }} </span>
                </v-tooltip>
                <v-icon class="white--text" @click="closeDialogHandler"> mdi-close-circle-outline </v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <template>
          <v-tabs v-model="model" centered>
            <v-tab v-for="i in listOfTabs" :key="i.id" :href="`#tab-${i.id}`">
              {{ $t(i.label) }}
            </v-tab>
          </v-tabs>
        </template>
        <v-tabs-items v-model="model">
          <v-tab-item v-for="i in listOfTabs" :key="i.id" :value="`tab-${i.id}`">
            <v-card flat>
              <v-card-text>
                <template v-if="model === 'tab-1'">
                  <v-row>
                    <v-col cols="12">
                      <v-row v-if="userDetails && userDetails.data && userDetails.data.name">
                        <v-col cols="12" md="4" :class="moduleObj.color ? `${moduleObj.color}--text` : 'primary--text'" class="my-auto py-1">
                          <span style="font-weight: bold;">{{ $t('customer') }}</span>
                        </v-col>
                        <v-col cols="12" md="8" class="py-1">
                          {{  userDetails.data.name  }}
                        </v-col>
                      </v-row>
                      <v-row v-if="moduleObj.moduleDatas">
                        <v-col cols="12" md="4" :class="moduleObj.color ? `${moduleObj.color}--text` : 'primary--text'" class="my-auto py-1">
                          <span style="font-weight: bold;">{{ $t('booked') }}  {{ isCustomerPortal ? moduleObj.modulename : calendarSettings.modulename }}(s)</span>
                        </v-col>
                        <v-col cols="12" md="8" class="py-1">
                          {{ moduleObj.moduleDatas.length > 1 ? moduleObj.moduleDatas.join(', ') : moduleObj.moduleDatas[0] }}
                        </v-col>
                      </v-row>
                      <v-row v-for="(item, i) in moduleData" :key="i">
                        <template v-if="item.type !== 11">
                          <v-col cols="12" md="4" class="py-1"><span class="mr-2 font-weight-bold" :class="moduleObj.color ? `${moduleObj.color}--text` : 'primary--text'">{{ $t(`${item.label}`) }}</span></v-col>
                          <v-col cols="12" md="8" class="py-1" v-if="[6, 16].includes(item.type)">{{ $formatter.formatDate(($formatter.fromUtcToLocal(item.data, 'DD.MM.YYYYTHH:mm:ss')), 'YYYY-MM-DDTHH.mm', 'DD.MM.YYYY HH:mm') }}</v-col>
                          <v-col cols="12" md="8" class="py-1" v-else-if="[10].includes(item.type)">
                            <v-img :src="item.data" height="130" contain width="200"></v-img>
                          </v-col>
                          <v-col cols="12" md="8" class="py-1" v-else-if="[5].includes(item.type)">
                            {{item.data && Array.isArray(item.data) ? item.data.join(', ') : (item.data || '')}}
                          </v-col>
                          <v-col cols="12" md="8" class="py-1" v-else-if="[17].includes(item.type)">
                            <v-row v-if="item.data">
                              <v-col lg="6" v-for="(files, fileIndex) in JSON.parse(item.data)" :key="`file_${fileIndex}`">
                                <v-card outlined height="50">
                                  <div class="attchment">
                                    <v-row class="mt-0 py-0">
                                      <v-col lg="2">
                                        <v-icon class="ml-2" size="18">mdi-file</v-icon>
                                      </v-col>
                                      <v-col lg="6" class="text-truncate py-0 d-flex align-center justify-center">
                                        <span class="pl-3 caption">{{files.originalfilename || files.OriginalFileName }}</span><br><br>
                                      </v-col>
                                      <v-col lg="3" class="px-0">
                                        <v-layout>
                                          <v-flex>
                                            <v-icon size="18" color="success" @click="downloadAttachmet(item.fieldname, files)">mdi-download</v-icon>
                                          </v-flex>
                                          <v-flex>
                                            <v-icon size="18" color="error" @click="deleteAttachmet(item.fieldname, files)">mdi-close-circle-outline</v-icon>
                                          </v-flex>
                                        </v-layout>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="8" class="py-1" v-else-if="[3].includes(item.type)">
                            {{ item.data && ["true", true].includes(item.data) ? $t('yes') : $t('no') }}
                          </v-col>
                          <v-col cols="12" md="8" class="py-1" v-else> {{ item.data }} </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
                <template v-if="model === 'tab-2'">
                  <v-card outlined :style="moduleObj.color ? `border-top: 2px solid ${moduleObj.color} !important`: ''" style="max-height:600px;overflow-y:scroll">
                    <v-card-title :class="moduleObj.color ? `${moduleObj.color}--text` : 'primary--text'" class="pa-1 px-2">
                      <v-btn color="purple" dark @click="addComments = true, autoFocusOnField()" :disabled="addComments">
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('addComments')  }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-menu offset-y :close-on-content-click="true">
                        <template v-slot:activator="{ on }">
                          <v-btn fab v-on="on" small text >
                            <v-icon :class="moduleObj.color ? `${moduleObj.color}--text` : 'primary--text'">mdi-filter</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, index) in activitiesFilterList" :key="index" @click="getFilteredByActiities(moduleObj._id, item.value)">
                            <v-list-item-title>{{ $t(`${item.text}`) }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-card-title>
                    <v-divider :class="moduleObj.color ? moduleObj.color : 'primary'"></v-divider>
                    <v-card-text class="scrolling">
                      <template v-for="(item, index) in commentListItems">
                        <v-list-item three-line :key="index">
                          <v-list-item-content>
                            <v-list-item-title v-if="item.userid" class="font-weight-bold mb-1">{{ item.username }}</v-list-item-title>
                            <v-list-item-title v-else-if="item.customerid" class="font-weight-bold mb-1">{{ item.customername }}</v-list-item-title>
                            <v-list-item-subtitle class="black--text mb-2">
                              {{ `${$t('createdOn')}` }} {{ `${$formatter.formatDate(item.created_at, 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm')}` }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.readonly" class="black--text">
                              {{ item.comment }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="black--text" v-else-if="!item.isEditEnable && !item.readonly" v-html="item.comment">
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                              <ckeditor :editor="editor" :config="editorConfig" ref="editor" v-model="activitiesComment.update" placeholder="test"></ckeditor>
                              <v-layout v-if="activitiesComment.update.length" justify-end>
                                <v-icon color="success" @click="saveOrUpdateComment(item._id); commentEditingMode = false"> mdi-check </v-icon>
                                <v-icon color="error" @click="item.isEditEnable = false; commentEditingMode = false"> mdi-close </v-icon>
                              </v-layout>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-icon v-if="!item.readonly && !item.isEditEnable && [item.userid, item.customerid].includes(userDetails._id)" class="ml-2 mb-8" @click="switchCommentAsEditable(index)">mdi-pencil</v-icon>
                        </v-list-item>
                        <v-divider :key="`divider${index}`"></v-divider>
                      </template>
                    </v-card-text>
                    <v-card-actions>
                      <v-list-item-subtitle class="my-2" v-if="addComments">
                        <ckeditor :editor="editor" :config="editorConfig" ref="editor" v-model="activitiesComment.save" placeholder="test"></ckeditor>
                        <v-layout justify-end>
                          <v-btn icon x-small class="mr-1">
                            <v-icon size="20" color="success" @click="saveOrUpdateComment('save')">mdi-check</v-icon>
                          </v-btn>
                          <v-btn icon x-small>
                            <v-icon size="20" color="error" @click="activitiesComment = { save: '', update: '' }, addComments = false">mdi-close</v-icon>
                          </v-btn>
                        </v-layout>
                      </v-list-item-subtitle>
                    </v-card-actions>
                  </v-card>
                </template>
                <template v-if="model === 'tab-3'">
                  <v-switch class="pa-0 ma-0" v-if="listOfChecklistData.length"
                    v-model="expandAll" @change="expandOrCollapse()" inset :label="$t('expandAll')"
                    ></v-switch>
                  <template v-if="!listOfChecklistData.length">
                    <v-alert prominent text type="info" class="mt-2" >
                    <v-row align="center">
                      <v-col class="grow">No Checklist Added</v-col>
                    </v-row>
                  </v-alert>
                  </template>
                  <v-expansion-panels class="mb-6" v-model="checklistPanel" v-if="listOfChecklistData.length" multiple>
                    <v-expansion-panel v-for="field in listOfChecklistData" :key="field._id">
                      <v-expansion-panel-header color="black" class="white--text">
                        {{ field.name }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters class="mt-3">
                        <v-col cols="12" class="text-right">
                          <v-btn color="primary" small @click.stop="editChecklist(field)" class="mr-2">
                            <v-icon>
                              {{ 'mdi-pencil' }}
                            </v-icon>
                            {{ $t('edit') }}
                          </v-btn>
                          <v-btn color="purple" small @click.stop="downloadChecklist(field.checklist_id, field.form_id, field.name, field)" dark v-if="field.checklist_id">
                            <v-icon>
                              {{ loading ? 'mdi-spin mdi-loading' : 'mdi-download' }}
                            </v-icon>
                            {{ $t('download') }}
                          </v-btn>
                        </v-col>
                        </v-row>
                        <v-row v-for="fieldItem in field.listOfFields" :key="fieldItem._id">
                          <v-col cols="12" lg="3">
                            <span class="font-weight-bold">{{ fieldItem.label }}</span>
                          </v-col>
                          <v-col cols="12" lg="9">
                            <template v-if="fieldItem.type === 13">
                              <v-img :src="field.listOfData[fieldItem.name]" max-height="150" max-width="250" alt="Image"></v-img>
                            </template>
                            <template v-else-if="fieldItem.type === 9">
                              <v-row no-gutters>
                                <template v-for="(document) in checkListAttachments[field.checklist_id]" >
                                  <v-col cols="12" lg="3" md="2" sm="3" v-if="document.property_name === fieldItem.name" :key="document._id" class="pa-0 pl-2 mt-1">
                                    <v-card outlined>
                                      <v-card-subtitle class="pa-1 text-center text-truncate">
                                        <v-layout>
                                          <v-flex lg2>
                                            <v-icon :size="30" :color="mimeTypeOfAttachment(document).color">{{ mimeTypeOfAttachment(document).icon }}</v-icon>
                                          </v-flex>
                                          <v-flex lg8 class="text-center text-truncate d-flex align-center">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on }">
                                                <span v-on="on" class="font-weight-bold"> {{ document.file_original_name }} </span>
                                              </template>
                                              <span>{{ document.file_original_name }}</span>
                                            </v-tooltip>
                                          </v-flex>
                                          <v-flex lg2>
                                            <v-btn icon color="info" @click="downloadDocument(document._id)">
                                              <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                          </v-flex>
                                        </v-layout>
                                      </v-card-subtitle>
                                    </v-card>
                                  </v-col>
                                </template>
                              </v-row>
                            </template>
                            <template v-else>
                              {{ getChecklistvalue(fieldItem, field.listOfData[fieldItem.name]) }}
                            </template>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-card-text>
              <v-divider :class="moduleObj.color ? moduleObj.color : 'primary'"></v-divider>
              <v-card-actions class="py-7" v-if="moduleObj.status !== 'closed'">
                <v-row class="mx-3 justify-center">
                  <v-btn class="red white--text" @click="userDialog = addComments = false;">{{ $t('close') }}</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <v-dialog v-model="bookingCancelDialog" width="400">
      <v-card>
        <v-card-title class="primary white--text">
          {{ $t('message') }}
          <v-spacer></v-spacer>
          <v-icon class="white--text">mdi-alert</v-icon>
        </v-card-title>
        <v-card-text class="py-2 px-3">
           {{ $t('bookingStatusMsg') }}
          <!-- <v-form ref="bookingCancel">
            <v-textarea rows="2" outlined :label="$t('message')" v-model="cancelMsg"></v-textarea>
          </v-form> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn class="mx-2 primary white--text" @click="cancelTheBooking">{{ $t('yes') }}</v-btn>
          <v-btn class="red white--text" @click="bookingCancelDialog = false">{{ $t('no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Checklist Dialog -->
    <v-dialog v-model="checklistDialog" hide-overlay transition="dialog-bottom-transition" persistent fullscreen>
      <v-card>
        <v-toolbar dark :color="userDetails.systemconfig && userDetails.systemconfig.themecolor ? userDetails.systemconfig.themecolor : 'primary'">
          <h3 class="mb-2">{{ $t('updateChecklist') }}</h3>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab small dark>
            <v-icon :color="userDetails.systemconfig && userDetails.systemconfig.textcolor ? userDetails.systemconfig.textcolor : 'white'" @click="closeChecklistDialog(true)">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
          <checklist-form :checklistObj="checklistObj" v-if="checklistDialog" :moduleId="moduleObj._id" :bookingId="bookingId" :alreadySavedChecklistIds="alreadySavedChecklistIds" :moduleName="calendarSettings.modulename"></checklist-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/locale/nb'
import moment from 'moment'
import CONSTANTS from '@/assets/json/constants.json'
import Editor from '../ckeditor'
import helpers from '@/utils/actions'

// eslint-disable-next-line
import { checklistApi, document_url } from '@/plugins/axios_settings'
import { mapState } from 'vuex'
export default {
  props: ['height', 'roomId'],
  data () {
    return {
      loading: false,
      reInit: 0,
      expandAll: false,
      dialog: false,
      userDialog: false,
      renderCalendar: false,
      calendarKey: 0,
      addComments: false,
      // showFormLoader: false,
      isCustomerPortal: null,
      bookingCancelDialog: false,
      specificEventCounts: {},
      checklistDialog: false,
      bookingModuleList: [],
      events: [],
      listOfChecklistData: [],
      calendarView: sessionStorage.getItem('calendarView') || 'month',
      calendarTitle: '',
      moduleObj: {},
      moduleData: {},
      bookingUserID: '',
      bookingsList: [],
      config: {
        eventLimit: 3,
        height: this.height || 567,
        firstDay: 1,
        defaultView: sessionStorage.getItem('calendarView') || 'month',
        displayEventTime: false,
        editable: false,
        selectable: true,
        droppable: true,
        sync: true,
        dragScroll: true,
        locale: this.$i18n.locale === 'no' ? 'nb' : this.$i18n.locale,
        eventRender: (event, element, view) => {
          if (view.type === 'month' && event.noDisplayOnMonth) element[0].classList.add('displayNone')
          element[0].title = element[0].text || element[0].textContent
        },
        select: (start, end, jsEvent, view) => {
          if (!this.isCustomerPortal) {
            let from = this.$formatter.formatDate(start.format('YYYY-MM-DD HH.mm'), 'YYYY-MM-DD HH.mm', 'YYYY-MM-DDTHH:mm')
            let to = this.$formatter.formatDate(end.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', 'YYYY-MM-DDTHH:mm')
            if (this.calendarView === 'month') to = moment(to, 'YYYY-MM-DDTHH:mm').subtract(1, 'd').format('YYYY-MM-DDT23:59')
            const isToday = moment(start).isSame(moment(), 'day')
            if (this.calendarView === 'month' && isToday) from = this.$formatter.formatDate(moment().add(1, 'h').format('DD.MM.YYYY HH.00'), 'DD.MM.YYYY HH.mm', 'YYYY-MM-DDTHH:mm')
            let allowed = moment(moment(start.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm')).isSameOrAfter(moment().format(`YYYY-MM-DD ${this.calendarView === 'month' ? '00:00' : 'HH:mm'}`))
            if (allowed) {
              const { allowBooking } = this.$formatter.checkTheDateAndTimeAvailablity({ from, to }, this.events.filter((item) => item.isRestricted))
              allowed = allowBooking
            }
            if (allowed) {
              const selectedDatesForBooking = {
                from: (this.calendarView === 'month' && isToday) ? moment().add(1, 'h').format('DD.MM.YYYY HH:00') : start.format('DD.MM.YYYY HH:mm'),
                to: this.calendarView === 'month' ? end.subtract(1, 'd').format('DD.MM.YYYY 23:59') : end.format('DD.MM.YYYY HH:mm')
              }
              this.$store.commit('viewTo', {
                page: 'booking',
                meta: {
                  bookingID: '',
                  selectedDatesForBooking,
                  roomId: this.roomId
                }
              })
            } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'notAllowedToBookOnthisDateorTimes' })
          }
        },
        eventClick: (event) => {
          if (!this.isCustomerPortal) {
            if (event.customerid === this.bookingUserID) {
              this.moduleObj = {}
              this.events.find(ele => {
                if (ele._id === event._id) {
                  this.moduleObj = ele
                  this.getSingleRecordFromApi(ele._id)
                }
              })
            }
          } else {
            this.moduleObj = event
            this.getSingleRecordFromApi(event._id)
          }
        }
      },
      activitiesFilterList: [{
        text: 'show_all_activities',
        value: 'show_all_activities'
      }, {
        text: 'show_activities_comments',
        value: 'show_activities_comments'
      }],
      commentListItems: [],
      commentEditingMode: false,
      editor: Editor,
      editorConfig: {
        removePlugins: ['ImageUpload', 'ElementsPath'],
        sharedSpaces: {
          top: 'toolbarLocation'
        },
        toolbar: {
          items: ['bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
        }
      },
      activitiesComment: { save: '', update: '' },
      listOfStatus: [],
      listOfUserStatus: [],
      status: '',
      canEdit: false,
      model: false,
      listOfChecklists: [],
      checkListAttachments: {},
      bookingId: null,
      alreadySavedChecklistIds: [],
      checklistPanel: []
    }
  },
  components: {
    FullCalendar,
    'checklist-form': () => import('./Checklist.vue')
  },
  mounted () {
    this.isCustomerPortal = this.portalInfo.type === 'booking_customer_portal'
    this.bookingUserID = this.userDetails._id
    if (!this.isCustomerPortal) {
      const moduleName = this.calendarSettings.modulename
      this.$bookingApi.execute('get', `bookingsopenapi/get_module_data/${moduleName}`).then(result => {
        if (result.data) this.bookingModuleList = result.data
        this.constructBookingAsEvent(this.$formatter.cloneVariable(this.bookingsList))
        this.init()
      })
      this.getListOfStatus()
    } else this.init()
    this.$root.$on('calendarInit', () => {
      this.bookingUserID = null
      this.init()
    })
    this.$root.$on('updatedBookingChecklist', (canClose) => {
      if (canClose) this.closeChecklistDialog()
      this.getChecklistDetails(this.moduleObj)
    })
  },
  computed: {
    ...mapState(['calendarSettings', 'userDetails', 'portalInfo']),
    listOfViews () {
      return [
        { value: 'month', text: this.$t('month') },
        { value: 'agendaWeek', text: this.$t('week') },
        { value: 'agendaDay', text: this.$t('day') }
      ]
    },
    listOfTabs () {
      return [{
        id: 1,
        label: 'details'
      }, {
        id: 2,
        label: 'activities'
      }, {
        id: 3,
        label: 'checklist'
      }]
    }
  },
  watch: {
    calendarView (val) {
      this.$refs.calendar.fireMethod('changeView', val)
      sessionStorage.setItem('calendarView', val)
      this.setTitle()
      if (this.isCustomerPortal) this.getCPBookings()
    }
  },
  methods: {
    getChecklistTemplates () {
      const url = this.isCustomerPortal ? 'customerportalopenapi/get_checklist_templates' : 'bookingsopenapi/get_checklist_templates'
      this.$bookingApi.execute('get', url).then(response => {
        this.listOfChecklists = response.data
        this.$store.commit('setListOfChecklistTemplates', this.listOfChecklists)
      })
    },
    init () {
      if (this.isCustomerPortal) this.customerPortalDataHandler()
      else {
        this.setTitle()
        this.renderCalendar = true
      }
      this.calendarKey++
    },
    next () {
      this.$refs.calendar.fireMethod('next')
      this.setTitle()
      if (this.isCustomerPortal) this.getCPBookings()
    },
    today () {
      this.$refs.calendar.fireMethod('today')
      this.setTitle()
      if (this.isCustomerPortal) this.getCPBookings()
    },
    previous () {
      this.$refs.calendar.fireMethod('prev')
      this.setTitle()
      if (this.isCustomerPortal) this.getCPBookings()
    },
    refreshCalendar () {
      if (this.isCustomerPortal) this.getCPBookings()
      else {
        this.$bookingApi.execute('get', 'bookingsopenapi/get_calender_setting')
          .then((response) => {
            this.$store.commit('calendarSettings', response.data)
            this.setTitle()
          })
      }
    },
    async setTitle (isUpdate) {
      this.events = []
      this.loading = true
      const calendarObj = this.$refs.calendar && this.$refs.calendar.fireMethod('getView')
      if (calendarObj) this.calendarTitle = calendarObj.title
      if (!this.isCustomerPortal) {
        const start = calendarObj.start
        const end = calendarObj.end
        const payload = {
          from: this.$formatter.formatDate(start.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', 'YYYY-MM-DDTHH:mm'),
          to: this.$formatter.formatDate(end.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', 'YYYY-MM-DDTHH:mm')
        }
        if (!isUpdate) this.getAllFormBookings(payload)
        const result = await this.constructRestrictionList(payload, this.calendarSettings.calendarsetting, true, calendarObj?.name === 'month')
        this.events.push(...result)
        const dates = this.events.filter((item) => item.isRestricted && !item.isHoliday)
        var value = 0
        dates.forEach((item) => {
          const date = this.$formatter.formatDate(item.start, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD')
          if (moment(date, 'YYYY-MM-DD').isAfter(moment(payload.from, 'YYYY-MM-DDTHH:mm')) && moment(date, 'YYYY-MM-DD')[calendarObj?.name === 'month' ? 'isBefore' : 'isSameOrBefore'](moment(payload.to, 'YYYY-MM-DDTHH:mm'))) value++
        })
        this.$set(this.specificEventCounts, 'restricted_dates', value)
      }
      this.loading = false
    },
    switchCommentAsEditable (index) {
      this.commentListItems.map(x => { x.isEditEnable = false })
      this.commentListItems[index].isEditEnable = true
      this.commentEditingMode = true
      this.activitiesComment.update = this.commentListItems[index].comment
    },
    getSingleRecordFromApi (id) {
      const url = this.isCustomerPortal ? `customerportalopenapi/get_booking/${id}` : `bookingsopenapi/get_booking/${id}`
      this.$bookingApi.execute('get', url).then(async (response) => {
        this.bookingId = response.data._id
        this.getChecklistTemplates()
        if (response.data.objectids) {
          this.moduleObj.moduleDatas = []
          await this.getListOfStatus(this.isCustomerPortal ? this.moduleObj.bookingformid : '')
            .then(() => {
              if (this.moduleObj.status) {
                const hasStatus = this.listOfStatus.find(x => x._id === this.moduleObj.status)
                this.status = this.moduleObj.status
                if (hasStatus) {
                  this.canEdit = !hasStatus.isclosed
                  this.moduleObj.color = hasStatus.color
                } else this.canEdit = false
              }
            })
          response.data.objectids.forEach(id => {
            this.bookingModuleList.forEach(ele => {
              if (ele._id === id) {
                this.moduleObj.moduleDatas.push(ele.data.name)
              }
            })
          })
        }
        this.getChecklistDetails(response.data)
        this.getCommentActivities(id)
        this.userDialog = true
        this.fetchFormFieldsList(response.data.bookingformid).then(() => {
          this.constructModuleData(response.data.data)
        })
      })
      this.$forceUpdate()
    },
    constructModuleData (modelData) {
      var result = {}
      this.formFieldsList.forEach(x => {
        if (!x.adminonlyfield) {
          result[x.name] = { fieldname: x, type: x.type, data: modelData[x.name], label: x.label }
          if (x.type === 5) {
            const defaultvalue = x.default_value ? JSON.parse(x.default_value) : {}
            if (!this.$formatter.isEmptyObject(defaultvalue)) {
              defaultvalue.name = x.name
              if (defaultvalue.selectType === 'default') result[x.name].data = this.getValues(defaultvalue, modelData)
              else if (defaultvalue.selectType === 'module') result[x.name].data = this.getModuleData(defaultvalue.selectedModule, modelData[x.name], x.name)
              else result[x.name].data = modelData[x.name]
            }
          }
        }
      })
      this.moduleData = result
    },
    getValues (item, data) {
      if (item.is_multiselect) {
        const ids = data[item.name] ? data[item.name] : []
        var result = item.options.filter(x => {
          if (ids.includes(x.value)) {
            x.label = this.$t(x.label)
            return x
          }
        })
        const name = result.map(e => e.label).join(',')
        return name
      } else {
        const result = item.options.find(x => x.value === data[item.name])
        if (result) return this.$t(result.label)
      }
    },
    getModuleData (moduleName, id, fieldname) {
      id = this.$formatter.isArray(id) ? id : [id]
      if (id) {
        const ids = id
        const queries = [
          {
            $project: {
              _id: {
                $toString: '$_id'
              },
              'Data.name': 1,
              'Data.email': 1
            }
          },
          { $match: { _id: { $in: ids } } }
        ]
        const url = this.portalInfo.type === 'booking_portal' ? `bookingsopenapi/module_data_query/${moduleName}` : `customerportalopenapi/module_data_query/${moduleName}`
        this.$bookingApi.execute('post', url, queries).then(response => {
          let selectValue = ''
          if (response && response.data.length > 0) {
            if (response.data.length === 1) selectValue = response.data[0].data.name
            else selectValue = response.data.map(x => x.data.name).join(',')
            this.moduleData[fieldname].data = selectValue
            return selectValue
          }
        })
      }
    },
    getAllFormBookings (fromTo) {
      const payload = { ...fromTo }
      payload.with_deleted_status = false
      if (this.roomId) payload.bookingobjectids = [this.roomId]
      this.$store.commit('showOrHideOverlayLoader', true)
      this.$bookingApi.execute('post', 'bookingsopenapi/get_all_form_bookings', payload)
        .then(response => {
          this.bookingsList = response.data
          this.constructBookingAsEvent(response.data)
        }).finally(() => {
          this.$store.commit('showOrHideOverlayLoader', false)
        })
    },
    constructBookingAsEvent (bookings) {
      this.events = this.events.filter(x => !x.isBooking)
      bookings.forEach(ele => {
        ele.isBooking = true
        if (ele.data) {
          ele.data.from = ele.data.from ? this.$formatter.fromUtcToLocal(ele.data.from, 'DD.MM.YYYYTHH:mm:ss') : null
          ele.data.to = ele.data.to ? this.$formatter.fromUtcToLocal(ele.data.to, 'DD.MM.YYYYTHH:mm:ss') : null
          ele.start = ele.data.from ? moment(ele.data.from).format('YYYY-MM-DDTHH:mm') : ''
          if (this.calendarView === 'month') ele.end = ele.data.to ? moment(ele.data.to).format('YYYY-MM-DDT23:59') : ''
          else ele.end = ele.data.to ? moment(ele.data.to).format('YYYY-MM-DDTHH:mm') : ''
          const bookingObject = []
          ele.title = ''
          if (ele.customerid === this.userDetails._id) {
            ele.objectids.forEach(id => {
              this.bookingModuleList.find(ele => {
                if (ele._id === id) bookingObject.push(ele.data.name)
              })
            })
            ele.title = bookingObject.join(', ')
          }
          if (!ele.title) ele.title = this.$t('booked')
          const from = this.$formatter.formatDate(ele.data.from, 'YYYY-MM-DDTHH.mm', 'HH:mm')
          const to = this.$formatter.formatDate(ele.data.to, 'YYYY-MM-DDTHH.mm', 'HH:mm')
          ele.title = ele.title + ' (' + from + ' - ' + to + ')'
          if (ele.customerid === this.bookingUserID) {
            ele.color = 'blue'
            if (ele.status_text) ele.title = `${ele.title} - ${ele.status_text}`
          } else {
            ele.isReserved = true
            ele.color = '#388E3C'
          }
          this.events.push(ele)
        }
      })
      const userbookings = bookings.filter((item) => item.customerid === this.bookingUserID).length
      this.specificEventCounts.booked = userbookings
      this.specificEventCounts.unavailable = bookings.length - userbookings
    },
    editBooking () {
      this.$store.commit('viewTo', {
        page: 'booking',
        meta: {
          roomId: this.moduleObj.objectids,
          bookingID: this.moduleObj._id
          // selectedDatesForBooking: {
          //   from: this.$formatter.formatDate(this.moduleObj.start, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY HH.mm'),
          //   to: this.$formatter.formatDate(this.moduleObj.end, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY HH.mm')
          // }
        }
      })
    },
    cancelTheBooking () {
      this.userDialog = false
      this.addComments = false
      const url = this.isCustomerPortal ? 'customerportalopenapi/cancel_booking' : 'bookingsopenapi/cancel_booking'
      this.$bookingApi.execute('put', `${url}/${this.moduleObj._id}?status=${this.status}`).then(() => {
        this.status = null
        if (this.isCustomerPortal) {
          this.events = []
          this.getCPBookings()
        } else this.setTitle()
      })
      this.bookingCancelDialog = false
    },
    getCommentActivities (id) {
      const url = this.isCustomerPortal ? 'customerportalopenapi/get_activities' : 'bookingsopenapi/get_activities'
      this.$bookingApi.execute('get', `${url}/${id}`).then(response => {
        response.data.forEach(ele => { ele.isEditEnable = false })
        this.commentListItems = response.data
      })
    },
    getFilteredByActiities (id, filter) {
      const type = filter === 'show_activities_comments' ? 'nonreadonly' : 'all'
      let url = this.isCustomerPortal ? `customerportalopenapi/get_activities/${id}` : `bookingsopenapi/get_activities/${id}`
      url = type === 'all' ? url : `${url}/?activity_type=${type}`
      this.$bookingApi.execute('get', `${url}`).then(response => {
        this.commentListItems = response.data
      })
    },
    saveOrUpdateComment (item) {
      const method = item === CONSTANTS.SAVE ? CONSTANTS.POST : CONSTANTS.PUT
      let url = null
      if (this.isCustomerPortal) url = method === 'post' ? '/customerportalopenapi/create_booking_activity' : `/customerportalopenapi/update_booking_activity/${item}`
      else url = method === 'post' ? '/bookingsopenapi/create_booking_activity' : `/bookingsopenapi/update_booking_activity/${item}`
      const model = { comment: method === 'post' ? this.activitiesComment.save : this.activitiesComment.update, bookingid: this.moduleObj._id, readonly: false, customerid: this.userDetails._id }
      this.$bookingApi.execute(method, url, model).then(response => {
        if (response) {
          this.getCommentActivities(this.moduleObj._id)
          this.activitiesComment = { save: '', update: '' }
        }
      })
    },
    customerPortalDataHandler () {
      this.renderCalendar = true
      const calendarObj = this.$refs.calendar && this.$refs.calendar.fireMethod('getView')
      if (calendarObj) this.calendarTitle = calendarObj.title
      this.getCPBookings()
    },
    getListOfStatus (formId) {
      return new Promise((resolve, reject) => {
        const url = formId ? `customerportalopenapi/get_statuses/${formId}` : 'bookingsopenapi/get_statuses'
        this.$bookingApi.execute('get', url)
          .then(response => {
            this.listOfStatus = response.data
            this.listOfUserStatus = response.data.filter(x => !!x.isavailableonlyforcustomer)
            resolve(response.data)
          })
          .catch((err) => reject(err))
      })
    },
    getCPBookings () {
      this.loading = true
      const userDetail = JSON.parse(this.$cookie.get(`${process.env.VUE_APP_NAME}_BOOKING_APP_USERDETAIL`))
      const calendarObj = this.$refs.calendar && this.$refs.calendar.fireMethod('getView')
      const from = calendarObj.start.format('YYYY-MM-DD')
      const to = calendarObj.end.format('YYYY-MM-DD')
      this.events = []
      if (userDetail) {
        this.$bookingApi.execute('get', `customerportalopenapi/get_customer_bookings/${userDetail._id}?from=${from}&to=${to}`)
          .then(response => {
            if (response.data) {
              const bookingList = this.$formatter.cloneVariable(response.data)
              bookingList.forEach(booking => {
                booking.data.from = booking.data.from ? this.$formatter.fromUtcToLocal(booking.data.from, 'DD.MM.YYYYTHH:mm:ss') : null
                booking.data.to = booking.data.to ? this.$formatter.fromUtcToLocal(booking.data.to, 'DD.MM.YYYYTHH:mm:ss') : null
                booking.start = booking.data.from ? this.$formatter.formatDate(booking.data.from, 'DD.MM.YYYYTHH:mm:ss', 'YYYY-MM-DD') : ''
                booking.end = booking.data.to ? this.$formatter.formatDate(booking.data.to, 'DD.MM.YYYYTHH:mm:ss', 'YYYY-MM-DDT23:59:59') : ''
                var bookingObject = []
                booking.objectids.forEach(id => {
                  this.bookingModuleList.find(ele => {
                    if (ele._id === id) bookingObject.push(ele.data.name)
                  })
                })
                const bookingTitle = booking.modulerecords.map((item) => item.data.name).join(',')
                booking.title = bookingTitle + ' - ' + (booking.status_text === 'null' ? this.$t('unknown') : (booking.status_text || this.$t('unknown')))
                booking.color = booking.status_color || 'blue'
                this.events.push(booking)
              })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    fetchFormFieldsList (id) {
      return new Promise((resolve) => {
        const url = this.isCustomerPortal ? `customerportalopenapi/get_form_fields/${id}` : 'bookingsopenapi/get_form_fields'
        this.$bookingApi.execute('get', url).then((response) => {
          this.formFieldsList = response.data
          resolve()
        })
      })
    },
    deleteAttachmet (fieldname, fileObj) {
      this.$bookingApi.execute('delete', `bookingsopenapi/delete_file/${this.moduleObj._id}?fieldname=${fieldname}&&fileguid=${fileObj.physicalpath || fileObj.PhysicalPath}`).then((response) => {
        this.constructModuleData(response.data.data)
        this.reRender++
      })
    },
    downloadAttachmet (fieldname, fileObj) {
      this.$bookingApi.execute('get', `bookingsopenapi/download_file/${this.moduleObj._id}?fieldname=${fieldname}&&fileguid=${fileObj.physicalpath || fileObj.PhysicalPath}`).then((response) => {
        window.open(`${process.env.VUE_APP_URL}/files/download?fileName=${response.data}`, '_blank')
      })
    },
    getStatus (status) {
      const statusObj = this.listOfStatus.find(x => x._id === status)
      if (statusObj) {
        return statusObj.name
      } else return 'Unknown'
    },
    closeDialogHandler () {
      this.userDialog = false
      this.addComments = false
      this.expandAll = false
      this.checklistPanel = []
      this.commentEditingMode = false
      this.listOfChecklistData = []
      this.model = 'tab-0'
    },
    autoFocusOnField () {
      setTimeout(() => {
        var div = document.getElementsByClassName('ck-editor__editable_inline')
        if (div && div[div.length - 1]) div[div.length - 1].focus()
      }, 200)
    },
    closeChecklistDialog (saveChecklist) {
      if (saveChecklist) this.$root.$emit('saveChecklists')
      else {
        this.checklistDialog = false
        this.checklistObj = {}
      }
    },
    editChecklist (checklistObj) {
      this.checklistObj = checklistObj
      // if (!checklistId) this.checklistObj.booking_id = this.bookingId
      this.checklistDialog = true
    },
    getChecklistDetails (model) {
      let listOfFormFields = []
      const listOfChecklistData = []
      this.loading = true
      this.$bookingApi.execute('get', `${this.isCustomerPortal ? 'customerportalopenapi' : 'bookingsopenapi'}/get_checklists/${model._id}`).then((response) => {
        const ids = response.data.map(x => x.form_id)
        const checklistIds = response.data.map(x => x.checklist_id)
        const existingIds = this.$formatter.cloneVariable(ids)
        this.alreadySavedChecklistIds = existingIds
        this.listOfChecklists.forEach(x => {
          if (!ids.includes(x.form_id)) ids.push(x.form_id)
        })
        if (checklistIds.length) {
          this.getChecklistDocumentsHandler(checklistIds).then(result => {
            this.checkListAttachments = result
          })
        }
        const openedChecklistPanelIndices = this.$formatter.cloneVariable(this.checklistPanel)
        checklistApi.post('formproperties/get_by_forms', ids).then(result => {
          listOfFormFields = result.data
          const ids = checklistIds
          checklistApi.post('checklists/get_multiple', ids).then(checklist => {
            response.data.forEach(element => {
              let data = {}
              const checklistData = checklist.data.find(x => x.form_id === element.form_id)
              if (checklistData && checklistData.data) {
                data = JSON.parse(checklistData.data)
              }
              const localChecklistObj = this.listOfChecklists.find(x => x.form_id === element.form_id)
              listOfChecklistData.push({
                listOfFields: listOfFormFields.filter(x => x.form_id === element.form_id),
                checklist_template_id: element.checklist_template_id,
                listOfData: data,
                name: element.name,
                form_id: element.form_id,
                checklist_id: checklistData ? checklistData._id : undefined,
                _id: element._id,
                pdftemplatepath: localChecklistObj ? localChecklistObj.pdftemplatepath : null,
                templateoriginalname: element.templateoriginalname
              })
            })
            this.listOfChecklists.forEach(x => {
              const checklistObj = response.data.find(y => x.form_id === y.form_id)
              if (!checklistObj) {
                listOfChecklistData.push({
                  listOfFields: listOfFormFields.filter(field => field.form_id === x.form_id),
                  listOfData: {},
                  name: x.name,
                  form_id: x.form_id,
                  checklist_template_id: x._id,
                  pdftemplatepath: x.pdftemplatepath,
                  templateoriginalname: x.templateoriginalname
                })
              }
            })
            console.log(listOfChecklistData)
            this.listOfChecklistData = listOfChecklistData
            if (this.listOfChecklistData.length) {
              if (this.listOfChecklistData.length === 1) {
                this.checklistPanel = this.listOfChecklistData.map((x, i) => i)
                this.expandAll = true
              } else this.checklistPanel = openedChecklistPanelIndices.length ? openedChecklistPanelIndices : []
            }
          })
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getChecklistvalue (field, value) {
      if (value) {
        const defaultValue = field.default_value ? JSON.parse(field.default_value) : ''
        const options = defaultValue.options
        switch (field.type) {
          case 3:
            if (defaultValue.checkboxtype === 1) {
              return value ? this.$t('yes') : this.$t('no')
            } else {
              const optionvalue = options.filter(x => value.includes(x.value))
              if (optionvalue && optionvalue.length) return optionvalue.map(x => x.label).join(',')
            }
            break
          case 4:
          case 5:
            if (defaultValue.is_multiselect) {
              const optionvalue = options.filter(x => value.includes(x.value))
              if (optionvalue && optionvalue.length) return optionvalue.map(x => x.label).join(',')
            } else {
              const optionvalue = options.find(x => x.value === value)
              if (optionvalue && optionvalue.value) return optionvalue.label
            }
            break
          default: return value
        }
      } else return ''
    },
    getChecklistDocumentsHandler (ids) {
      return new Promise((resolve) => {
        var result = {}
        ids.forEach(id => {
          checklistApi.get(`/documents/get_checklist_documents/${id}`)
            .then((response) => {
              if (response && response.data) {
                result[id] = response.data
                if (Object.keys(result).length === ids.length) resolve(result)
              }
            })
        })
      })
    },
    // eslint-disable-next-line camelcase
    mimeTypeOfAttachment ({ file_original_name, file_guid }) {
      let image = ''
      const ext = file_original_name.split('.').pop()
      const extension = ext ? ext.toString().toLowerCase() : ''
      switch (extension) {
        case 'png':
        case 'jpeg':
        case 'jpg':
        case 'gif':
          // eslint-disable-next-line camelcase
          image = { icon: 'mdi-file-image', color: null, url: `${document_url}/Documents/${file_guid}?t=${new Date().getTime()}` }
          break
        default:
          image = { icon: 'mdi-file-document', color: null, url: null }
          break
      }
      return image
    },
    downloadDocument (id) {
      checklistApi.get(`documents/download/${id}`, this.token).then(response => {
        const filename = response.data
        window.open(`${checklistApi.defaults.baseURL}files/download?fileName=${filename}`, '_blank')
      })
    },
    expandOrCollapse () {
      if (this.expandAll) {
        this.listOfChecklistData.forEach((item, index) => this.checklistPanel.push(index))
      } else this.checklistPanel = []
    },
    downloadChecklist (checklistId, formId, formName, field) {
      this.loading = true
      helpers.downloadReportHelper(checklistId, formId, formName, process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN, true, field._id, field.pdftemplatepath)
        .then(() => {
          this.loading = false
        })
    }
  },
  beforeDestroy () {
    this.$root.$off('calendarInit')
    this.$root.$off('updatedBookingChecklist')
  }
}
</script>

<style>
@import '~fullcalendar/dist/fullcalendar.css';
.dot {
  /* content: "\00B7"; */
  height: 10px;
  width: 15px;
}
.booked {
  color: blue !important;
  background-color: blue !important;
}
.holiday {
  color: orange !important;
  background-color: orange !important;
}
.restricted {
  color: #C0C0C0 !important;
  background-color: #C0C0C0 !important;
}
.unavailableDates {
  color: #43ED50 !important;
  background-color: #43ED50 !important;
}
.custom-span-comment  {
  margin-left: 85%;
}
.scrolling {
  overflow-y: auto !important;
}
.displayNone {
  display: none !important;
}
</style>
