<template>
  <div class="d-flex justify-center">
    <v-card :width="xsOnly ? '380' : '420'" elevation="17">
      <v-card-title class="primary white--text py-3">
        <v-icon dark class="mr-2"> mdi-login </v-icon>
        <span class="subtitle font-weight-black"> {{ $t('signIn') }} </span>
        <v-spacer></v-spacer>
        <v-btn fab small v-if="portalInfo.type === 'booking_portal' && showportfoliopagefirst">
          <v-icon dark @click="$store.commit('viewTo', { page: 'portfolio' })"> mdi-book-information-variant </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-8 pt-4">
        <template v-if="calendarSettings && calendarSettings.calendarsetting && calendarSettings.calendarsetting.loginformtype !== 2">
          <v-form ref="loginForm" class="px-11">
            <v-text-field v-model="loginObj.name" :label="$t('name')" outlined dense hide-details class="py-1"
              :rules="$_requiredValidation" validate-on-blur></v-text-field>
            <v-text-field v-model="loginObj.email" :label="$t('email')" outlined dense hide-details class="py-1"
              :rules="$_emailAndRequiredValidation" validate-on-blur></v-text-field>
            <div>
              <v-btn block color="success" class="mt-2" @click="loginHandler()" :loading="loginObj.loading"> {{ $t('login') }} </v-btn>
            </div>
          </v-form>
        </template>
        <label class="d-flex justify-center mt-3 font-weight-black"
          v-if="calendarSettings && calendarSettings.calendarsetting && (calendarSettings.calendarsetting.loginformtype === 3 || calendarSettings.calendarsetting.loginformtype === 0)">
          ( {{ $t('or') }} )
        </label>
        <template v-if="(calendarSettings && calendarSettings.calendarsetting) ? calendarSettings.calendarsetting.loginformtype !== 1 : true">
          <v-btn tile label @click.prevent="isIos ? iosLogin('loginWithGoogle') : loginWithGoogle()" class="grey lighten-4 d-inline-flex mx-2 my-4" width="300" elevation="1" outlined>
            <v-img class="mt-1 pr-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" aria-hidden="true">
                <path fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path>
                <path fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path>
                <path fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path>
                <path fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path>
              </svg>
            </v-img>
            <span class="font-weight-bold body-2 pr-10"> {{ $t('signInWithGoogle') }} </span>
          </v-btn>
          <v-btn tile class="primary font-weight-bold body-2" @click="isIos ? iosLogin('getMicrosoftAuthUrl') : getMicrosoftAuthUrl()" :loading="loading" width="300">
            <v-icon class="mr-2"> mdi-microsoft </v-icon>
            <span> {{ $t('signInWithMicrosoft') }} </span>
          </v-btn>
        </template>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" width="550" persistent>
      <v-card>
        <v-card-title class="px-4 py-3 error white--text">
          <span class="body-1 font-weight-medium"> Popup permission </span>
          <v-spacer></v-spacer>
          <v-icon dark> mdi-alert </v-icon>
        </v-card-title>
        <v-card-text class="px-2 py-4 text-center">
          <span> Make sure that you have enabled popup permission for this site in your browser settings to continue to login. </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="iosLogin(false)">
            <span> Yes, I understand </span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  data () {
    return {
      loginObj: {},
      dialog: false,
      loading: false,
      restricteduserdomains: [],
      applyrestrictiononuserdomain: null,
      selectedLoginFunctionname: null,
      showportfoliopagefirst: false
    }
  },
  computed: {
    ...mapGetters(['calendarSettings']),
    ...mapState(['portalInfo']),
    isIos () {
      return (/iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase()))
    }
  },
  beforeMount () {
    if (this.calendarSettings) {
      const { calendarsetting } = this.calendarSettings
      this.showportfoliopagefirst = calendarsetting?.showportfoliopagefirst
    }
  },
  mounted () {
    window.onmessage = (message) => {
      const { data } = message
      if (data.authFrom && data.err) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: data.err })
      } else if (data.authFrom && data.value) {
        if (data.authFrom === 'microsoft') this.loginHandler(data.value)
        if (data.authFrom === 'google' || data.authFrom === 'facebook') {
          const model = {
            name: data.value.name,
            email: data.value.email,
            profile: data.value.picture
          }
          this.loginHandler(model)
        }
      }
    }
  },
  methods: {
    iosLogin (fname) {
      this.dialog = !!fname
      if (fname) this.selectedLoginFunctionname = fname
      else this[this.selectedLoginFunctionname]()
    },
    loginWithGoogle () {
      this.$bookingApi.execute('get', 'sociallogin/get_google_auth_url')
        .then(({ data: url }) => {
          window.open(url, '_blank', 'left=100, top=140, toolbar=yes, status=yes, menubar=yes, scrollbars=no, resizable=yes, copyhistory=no, height=400, width=500')
          // const popup = window.open(response.data, '_blank', 'left=100, top=140, toolbar=yes, status=yes, menubar=yes, scrollbars=no, resizable=yes, copyhistory=no, height=400, width=500')
          // if (!popup || popup.closed || typeof popup === 'undefined' || typeof popup.closed === 'undefined') {
          //   this.dialog = true
          // }
        })
    },
    getFacebookAuthUrl () {
      this.$bookingApi.execute('get', 'sociallogin/get_facebook_auth_url')
        .then((response) => {
          window.open(response.data, '_blank', 'left=100, top=140, toolbar=yes, status=yes, menubar=yes, scrollbars=no, resizable=yes, copyhistory=no, height=400, width=500')
        })
    },
    loginHandler (data) {
      let allowToLogin = true
      if (!data) allowToLogin = this.$refs.loginForm && this.$refs.loginForm.validate()

      const model = {}
      if (allowToLogin) {
        model.name = (data && data.name) ? data.name : this.loginObj.name
        model.email = (data && data.email) ? data.email : this.loginObj.email
        model.domain = model.email.split('@')
        if (this.calendarSettings && this.calendarSettings.calendarsetting) {
          const { applyrestrictiononuserdomain, restricteduserdomains } = this.calendarSettings.calendarsetting
          if (applyrestrictiononuserdomain) {
            allowToLogin = this.checkByDomain(restricteduserdomains, model.domain[1], true)
            if (!allowToLogin) {
              this.$store.commit('viewTo', { page: 'login' })
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'unauthorizedEntry' })
            }
          }
        }
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })

      if (allowToLogin) {
        const url = this.portalInfo.type === 'booking_portal' ? 'bookingsopenapi/get_or_create_customer' : 'customerportalopenapi/get_or_create_customer'
        model.loginfrom = data ? 2 : 1
        this.loginObj.loading = true
        this.$bookingApi.execute('post', url, model)
          .then(({ data }) => {
            if (data) {
              this.$store.dispatch('getListOfUsers')
              if (model.profile) data.profile = model.profile
              this.setLoginValues(data)
              this.$store.commit('viewTo', { page: this.portalInfo.type === 'booking_portal' ? 'portfolio' : 'dashboard' })
            }
          })
          .catch((err) => this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: err }))
          .finally(() => {
            this.loginObj.loading = false
          })
      }
    },
    setLoginValues (data) {
      this.$store.commit('setUserDetails', data)
      this.$cookie.set(`${process.env.VUE_APP_NAME}_BOOKING_APP_USERDETAIL`, JSON.stringify(data), { expires: this.$formatter.getOneHourExpiryTime() })
    },
    getMicrosoftAuthUrl () {
      this.$bookingApi.execute('get', 'sociallogin/get_microsoft_auth_url')
        .then((response) => {
          window.open(response.data.url, '_blank', 'left=100, top=140, toolbar=yes, status=yes, menubar=yes, scrollbars=no, resizable=yes, copyhistory=no, height=400, width=500')
        })
        .catch((err) => {
          this.$store.commit('viewTo', { page: 'login' })
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: err })
        })
    }
  }
}
</script>
