import { mapState } from 'vuex'
export default {
  props: ['showProfile', 'height', 'theme', 'isForPortfolio'],
  data () {
    return {
      roomDetails: [],
      moduleFields: null,
      cardData: [],
      profileCard: [],
      api_baseurl: process.env.VUE_APP_IMAGE_URL,
      tenantName: '',
      noDetails: false
    }
  },
  computed: {
    ...mapState(['calendarSettings'])
  },
  mounted () {
    this.tenantName = this.calendarSettings.tenantname || ''
    this.$root.$on('getModuleId', (roomId) => {
      this.roomDetails = []
      this.cardData = []
      if (roomId) {
        if (Array.isArray(roomId) && this.$formatter.isArrayHasData(roomId)) {
          this.noDetails = false
          this.getRoomDetails(roomId)
        } else if (Array.isArray(roomId) && !this.$formatter.isArrayHasData(roomId)) this.noDetails = true
        else {
          this.noDetails = false
          this.getRoomDetails([roomId])
        }
      } else this.noDetails = true
    })
  },
  methods: {
    getRoomDetails (roomIds) {
      const moduleName = this.calendarSettings.modulename
      if (moduleName && roomIds.length) {
        this.$bookingApi.execute('get', `bookingsopenapi/get_module_fields/${moduleName}`)
          .then(response => {
            this.moduleFields = response.data
            this.$bookingApi.execute('post', `bookingsopenapi/get_module_data_by_ids/${moduleName}`, roomIds)
              .then(result => {
                if (result.data) {
                  result.data.forEach((item, index) => {
                    this.profileCard[index] = {}
                    this.profileCard[index].profileimage = item.profileimage
                    this.roomDetails[index] = item.data
                  })
                }
              }).then(() => {
                this.constructData()
              })
          })
      }
    },
    constructData () {
      if (this.moduleFields.length && this.roomDetails.length) {
        this.roomDetails.forEach((room, index) => {
          if (!this.cardData[index]) this.cardData[index] = []
          this.moduleFields.forEach((moduleField) => {
            const moduleValue = room[moduleField.name] ? room[moduleField.name] : ''
            if (!['description', 'short_description', 'location_link'].includes(moduleField.name) && this.isForPortfolio) {
              const defaultValue = JSON.parse(moduleField.default_value) || {}
              const options = defaultValue.options || []
              switch (moduleField.type) {
                case 5:
                  if (defaultValue.selectType === 'module') {
                    if (moduleValue && moduleValue.length) {
                      this.$bookingApi.execute('get', `bookingsopenapi/get_module_data_by_id/${defaultValue.selectedModule}/${moduleValue}`)
                        .then((response) => {
                          if (response.data) {
                            if (!this.cardData[index]) this.cardData[index] = []
                            this.cardData[index].push({ label: moduleField.label, value: response.data.data.name, is_show: moduleField.show_in_booking, type: moduleField.type })
                          }
                        })
                    } else this.cardData[index].push({ label: moduleField.label, value: '', is_show: moduleField.show_in_booking })
                  } else {
                    if (defaultValue.is_multiselect) {
                      const result = options.filter(x => moduleValue.includes(x.value))
                      if (result && result.length) {
                        this.cardData[index].push({
                          label: moduleField.label,
                          value: result.map(x => x.label).join(','),
                          is_show: moduleField.show_in_booking
                        })
                      }
                    } else {
                      const result = options.find(x => x.value === moduleValue)
                      if (result) {
                        this.cardData[index].push({ label: moduleField.label, value: result.label, is_show: moduleField.show_in_booking })
                      }
                    }
                  }
                  break
                case 4:
                  this.cardData[index].push({ label: moduleField.label, value: moduleValue ? options.find(item => item.value === moduleValue).label : '', is_show: moduleField.show_in_booking })
                  break
                default:
                  if (moduleField.type !== 15 && !Array.isArray(moduleValue)) {
                    if (moduleField.type === 6 || moduleField.type === 16) {
                      let date = this.$formatter.fromUtcToLocal(moduleValue, 'DD.MM.YYYYTHH:mm:ss')
                      date = this.$formatter.formatDate(date, 'YYYY-MM-DDTHH:mm:ss', (moduleField.type === 6 ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'))
                      this.cardData[index].push({ label: moduleField.label, value: date, is_show: moduleField.show_in_booking })
                    } else {
                      this.cardData[index].push({ label: moduleField.label, value: moduleValue, type: moduleField.type, is_show: moduleField.show_in_booking })
                    }
                  }
                  break
              }
            } else if (!this.isForPortfolio) {
              const defaultValue = JSON.parse(moduleField.default_value) || {}
              const options = defaultValue.options || []
              switch (moduleField.type) {
                case 5:
                  if (defaultValue.selectType === 'module') {
                    if (moduleValue && moduleValue.length) {
                      this.$bookingApi.execute('get', `bookingsopenapi/get_module_data_by_id/${defaultValue.selectedModule}/${moduleValue}`)
                        .then((response) => {
                          if (response.data) {
                            if (!this.cardData[index]) this.cardData[index] = []
                            this.cardData[index].push({ label: moduleField.label, value: response.data.data.name, is_show: moduleField.show_in_booking })
                          }
                        })
                    } else this.cardData[index].push({ label: moduleField.label, value: '', is_show: moduleField.show_in_booking })
                  } else {
                    if (defaultValue.is_multiselect) {
                      const result = options.filter(x => moduleValue.includes(x.value))
                      if (result && result.length) {
                        this.cardData[index].push({
                          label: moduleField.label,
                          value: result.map(x => x.label).join(','),
                          is_show: moduleField.show_in_booking
                        })
                      }
                    } else {
                      const result = options.find(x => x.value === moduleValue)
                      if (result) {
                        this.cardData[index].push({ label: moduleField.label, value: result.label, is_show: moduleField.show_in_booking })
                      }
                    }
                  }
                  break
                case 4:
                  this.cardData[index].push({ label: moduleField.label, value: moduleValue ? options.find(item => item.value === moduleValue).label : '', is_show: moduleField.show_in_booking })
                  break
                default:
                  if (moduleField.type !== 15 && !Array.isArray(moduleValue)) {
                    if (moduleField.type === 6 || moduleField.type === 16) {
                      let date = this.$formatter.fromUtcToLocal(moduleValue, 'DD.MM.YYYYTHH:mm:ss')
                      date = this.$formatter.formatDate(date, 'YYYY-MM-DDTHH:mm:ss', (moduleField.type === 6 ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'))
                      this.cardData[index].push({ label: moduleField.label, value: date, is_show: moduleField.show_in_booking })
                    } else {
                      this.cardData[index].push({ label: moduleField.label, value: moduleValue, type: moduleField.type, is_show: moduleField.show_in_booking })
                    }
                  }
                  break
              }
            }
          })
        })
        this.$forceUpdate()
        this.$root.$emit('loader', false)
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('getModuleId')
  }
}
