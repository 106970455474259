<template>
  <div v-if="cardData.length">
    <v-card color="transparent" class="pl-2" flat :max-height="height" style="overflow-y: auto; overflow-x: hidden;">
      <v-card-title class="grey--text text--darken-2 pl-0 pt-0 font-weight-black"> {{ $t('moreInfo') }}: </v-card-title>
      <v-card-text class="pa-2">
        <template v-for="(item, index) in cardData[0]">
          <v-layout row wrap :key="index" align-center class="pb-xl-2 mb-2"
          v-if="item.label !== 'name' && item.type !== 11 && item.is_show">
            <v-flex xl3 lg5 class="pb-2">
              <v-btn fab elevation="1" outlined x-small class="mb-1 mx-1">
                <v-icon size="21"> mdi-information </v-icon>
              </v-btn>
              <span class="ml-xl-1 font-weight-bold black--text" style="font-size: 1rem;"> {{ $t(item.label) }}: </span>
            </v-flex>
            <v-flex xl3 lg5 v-if="item.type === 3" class="font-weight-black">
              {{ item.value ? $t('yes') : $t('no') }}
            </v-flex>
            <v-flex xl3 lg5 v-else-if="item.type === 6" class="font-weight-black">
              {{ $formatter.formatDate(item.value, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYY') }}
            </v-flex>
            <v-flex xl3 lg5 v-else-if="item.type === 10 || item.type === 17">
              <img :src="item.value" width="70%" height="45px" alt="Image" v-if="item.value">
            </v-flex>
            <v-flex xl3 lg5 v-else class="font-weight-black"> {{ item.value }} </v-flex>
          </v-layout>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import mixin from '@/mixins/modulemeta.js'
export default {
  props: ['height'],
  mixins: [mixin]
}
</script>
