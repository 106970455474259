<template>
  <v-form ref="validateForm" v-if="showForm">
    <v-row class="formRender">
      <!-- <slot name="combo"></slot> -->
       <!-- :cols="item.columns ? item.columns : '12'" :md="item.columns ? item.columns : (item.type === 10 || item.type === 11 || item.type === 12 || item.type === 14) ? '12' : '6'" -->
      <template v-for="(item, index) in listOfFields">
        <v-col v-if="!item.isPanelField && (item.conditionscript ? item.isShowElement ? item.isShowElement(fields, self) : () => {} : item.isShow)"
          cols="12"
          :xl="item.columns"
          :lg="item.columns"
          :md="item.type === 10 || item.type === 11 || item.type === 12 || item.type === 14 ? '12' : '4'"
          :sm="(item.type === 9 || item.type === 11 || item.type === 12) ? '12' : '4'"
          xs="12"
          :key="item._id">
          <template v-if="item.type === 1">
            <v-text-field outlined :label="$t(item.label)" v-model="fields[item.name]" :placeholder="item.placeholder" dense :autofocus="index === 0"
              :id="`_${item.name}`" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
              :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
              @change="item.name === 'name' ? checkForDuplicateNames(fields[item.name], moduleName, item, index, fields._id) : checkForValidationOrFunction(item, fields[item.name], index)">
            </v-text-field>
          </template>
          <template v-if="item.type === 2">
            <v-textarea outlined  :label="$t(item.label)" v-model="fields[item.name]" :placeholder="item.placeholder" dense
              :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
              :id="`_${item.name}`" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
              @change="checkForValidationOrFunction(item, fields[item.name], index)">
            </v-textarea>
          </template>
          <template v-if="item.type === 3">
            <v-row v-if="item.default_value && item.default_value.is_multiselect">
              <v-col class="pa-0 pl-2" cols="12">
                <label>{{ $t(item.label) }}:</label>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0 pl-2" cols="12" lg="12" v-if="item.default_value && !item.default_value.is_multiselect">
                <v-checkbox  :label="$t(item.label)" class="pt-0" v-model="fields[item.name]" :id="`_${item.name}`"
                  :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  @change="checkForValidationOrFunction(item, fields[item.name], index)">
                </v-checkbox>
              </v-col>
              <v-col class="pa-0 pl-2" cols="12" lg="12" v-else>
                <template>
                  <v-checkbox  v-model="fields[item.name]" :label="options.label" :value="options.value" :key="optionkey" v-for="(options, optionkey) in item.default_value.options" :id="`_${item.name}`"
                    :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                    @change="checkForValidationOrFunction(item, fields[item.name], index)">
                  </v-checkbox>
                </template>
              </v-col>
            </v-row>
          </template>
          <template v-if="item.type === 4">
            <label>{{ $t(item.label) }}:</label>
            <v-radio-group row  v-model="fields[item.name]"  :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" :id="`_${item.name}`"
              @change="checkForValidationOrFunction(item, fields[item.name], index)">
              <v-radio v-for="(options, index) in item.default_value.options" :key="index" :label="options.label" :value="options.value"></v-radio>
            </v-radio-group>
          </template>
          <template v-if="item.type === 5">
            <template v-if="item.default_value.selectType === 'api'">
              <v-autocomplete outlined :items="item.default_value.options" :label="$t(item.label)"  v-model="fields[item.name]" :key="autoCompleteRender"
                dense :multiple="item.default_value.is_multiselect" :item-text="item.default_value.name" :item-value="item.default_value.name"
                :placeholder="item.placeholder" clearable
                :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" autocomplete="off" :ref="`searchInput${index}`"
                :id="`_${item.name}`"  @keyup="getValuesFromAPI(`searchInput${index}`, item.default_value, index)"
                @change="checkForValidationOrFunction(item, fields[item.name], index)"
               >
              </v-autocomplete>
            </template>
            <template v-else-if="item.default_value.selectType === 'module'">
              <v-autocomplete outlined :items="item.default_value.options" :label="$t(item.label)" :ref="`autocompleteRef${index}`" :key="autoCompleteRender" dense
                :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" v-model="fields[item.name]" :multiple="item.default_value.is_multiselect"
                autocomplete="off" :placeholder="item.placeholder" clearable :id="`_${item.name}`"
                @change="checkForValidationOrFunction(item, fields[item.name], index)"
                @keyup="isBooking ? '' : getValuesForModule($event, item.default_value, index)"
                item-text="name" item-value="_id">
                <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('startTyping') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
              </v-autocomplete>
            </template>
            <template v-else-if="item.default_value.selectType === 'default'">
              <v-autocomplete outlined :items="item.default_value.options" :label="$t(item.label)" dense
                :item-text="val => $t(val.label)" item-value="value"
                :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable
                :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" :id="`_${item.name}`"
                @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-autocomplete>
            </template>
            <template v-else-if="item.default_value.selectType === 'custom'">
              <template v-if="moduleName === 'Account'">
                <v-combobox outlined :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" :items="item.customOptions"
                  :item-text="item.optionName" :item-value="item.optionValue"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable
                  :label="$t(item.label)" dense :ref="`customSearch_${index}`" :loading="item.loading" :id="`_${item.name}`"
                  @change="checkForValidationOrFunction(item, fields[item.name], index)">
                </v-combobox>
              </template>
              <v-autocomplete outlined :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []" v-else
                :item-text="item.optionName" :item-value="item.optionValue" :items="item.customOptions"
                :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable
                :label="$t(item.label)" dense :ref="`customSearch_${index}`" :loading="item.loading" :id="`_${item.name}`"
                @change="checkForValidationOrFunction(item, fields[item.name], index)">
              </v-autocomplete>
            </template>
          </template>
          <template v-if="item.type === 6">
            <v-menu v-model="item.date_menu" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on }">
                <v-text-field outlined  v-model="fields[item.name]" :placeholder="item.placeholder" dense clearable
                  prepend-icon="mdi-calendar" :label="$t(item.label.trim())" readonly v-on="on" @click:clear="fields[item.name] = null" :id="`_${item.name}`"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  @change="checkForValidationOrFunction(item, fields[item.name], index)">
                </v-text-field>
              </template>
              <v-date-picker v-model="item.date_value" @input="fields[item.name] = parseDate(item.date_value), item.date_menu = false" style="height: 430px;"></v-date-picker>
            </v-menu>
          </template>
          <template v-if="item.type === 7">
            <v-menu :ref="`time_menu_${index}`" v-model="item.time_menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="fields[item.name]"
              transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field outlined  v-model="fields[item.name]" :placeholder="item.placeholder" clearable dense prepend-icon="mdi-timer" :id="`_${item.name}`"
                  :label="$t(item.label)" readonly v-on="on"
                  :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  @click:clear="fields[item.name] = null">
                </v-text-field>
              </template>
              <v-time-picker v-if="item.time_menu" v-model="fields[item.name]" format="24hr" full-width
                @click:minute="$refs[`time_menu_${index}`][0].save(fields[item.name])"
                @change="checkForValidationOrFunction(item, fields[item.name], index)">
              </v-time-picker>
            </v-menu>
          </template>
          <template v-if="item.type === 8">
            <v-file-input outlined :label="$t(item.label)" v-model="fields[item.name]"  :placeholder="item.placeholder" dense :id="`_${item.name}`"
              :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
              :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
              @change="checkForValidationOrFunction(item, fields[item.name], index)">
            </v-file-input>
          </template>
          <template v-if="item.type === 9">
            <v-subheader v-if="item.label" class='pa-0'><strong>{{ $t(item.label) }}:</strong></v-subheader>
            <v-divider></v-divider>
          </template>
          <template v-if="item.type === 10">
            <v-subheader v-if="item.label" class='pa-0'><strong>{{ $t(item.label) }}:</strong></v-subheader>
            <VueSignaturePad width="100%" height="180px" class="signature--pad" :ref="`signaturePad_${index}`" />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn fab x-small dark v-on="on" class="mt-1 bg-indigo" @click="clearThisSignature(index)">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('reset') }}</span>
            </v-tooltip>
          </template>
          <template v-if="item.type === 11">
            <v-card class="pa-1">
            <v-card-title class="pa-1 caption font-weight-bold">
              {{ $t(item.label) }}
              <v-spacer></v-spacer>
            </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-1">
                <module-render :listOfFields="item.panel_fields" ref="formReference" :fields="fields"></module-render>
              </v-card-text>
            </v-card>
          </template>
          <template v-if="item.type === 12">
            <v-text-field outlined  :label="$t(item.label)" :placeholder="item.placeholder" v-model="fields[item.name]"
              :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : $_emailValidation"
              dense @change="checkForValidationOrFunction(item, fields[item.name], index)" :id="`_${item.name}`"
              :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false">
            </v-text-field>
          </template>
          <template v-if="item.type === 13">
            <v-text-field type="number" outlined  :label="$t(item.label)" :placeholder="item.placeholder" :id="`_${item.name}`"
              :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
              :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
              @change="checkForValidationOrFunction(item, fields[item.name], index)"
              v-model="fields[item.name]" dense>
            </v-text-field>
          </template>
          <template v-if="item.type === 14">
            <v-text-field outlined  :label="$t(item.label)" :placeholder="item.placeholder" v-model="fields[item.name]" dense
              :id="`_${item.name}`" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
              :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
              @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-text-field>
          </template>
          <template v-if="item.type === 15">
            <v-autocomplete outlined :items="getUsers" :label="$t(item.label)" :multiple="item.default_value.is_multiselect" dense v-model="fields[item.name]"
              clearable  item-text="name" item-value="_id" :placeholder="item.placeholder"
              :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
              :id="`_${item.name}`" :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
              @change="checkForValidationOrFunction(item, fields[item.name], index)"></v-autocomplete>
          </template>
          <template v-if="item.type === 16">
            <v-menu v-model="item.dateTimeMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
              <template  v-slot:activator="{ on }">
                <v-text-field slot="activator" v-model="fields[item.name]" :id="`_text${item.name}`" :clearable="item.name !== 'from' && item.name !== 'to'"
                  :validate-on-blur="item.validateon ? item.validateon === 'blur' ? true : false : false"
                  :label="$t(item.label)" prepend-icon="mdi-timetable" v-on="on" outlined dense hide-details
                  :rules="$formatter.isArray(rulesHandler(item, fields[item.name])) ? rulesHandler(item, fields[item.name]) : []"
                  @click:clear="fields[item.name] = null; $forceUpdate(); $root.$emit('loadObjects', false)"
                  @input="onChangeText(item, index)"
                  @focus="item.dateTimePicker = $formatter.formatDate(fields[item.name], 'DD.MM.YYYY HH.mm', 'YYYY-MM-DD'); ['from', 'to'].includes(item.name) ? item.timePicker_hour = $formatter.formatDate(fields[item.name], 'DD.MM.YYYY HH.mm', 'HH') || '00' : null; ['from', 'to'].includes(item.name) ? item.timePicker_minute = $formatter.formatDate(fields[item.name], 'DD.MM.YYYY HH.mm', 'mm') || '00' : null  "></v-text-field>
              </template>
              <v-tabs v-model="item.active" color="primary lighten-1" dark centered slider-color="white" grow>
                <v-tab ripple>
                  <v-icon color="white" class="pr-2">mdi-calendar</v-icon>
                </v-tab>
                <v-tab ripple :disabled="!item.dateTimePicker">
                  <v-icon color="white" class="pr-2">mdi-clock-outline</v-icon>
                </v-tab>
                <v-tab-item>
                  <v-date-picker v-model="item.dateTimePicker" color="primary lighten-1" no-title :id="`_${item.name}`"
                  :min="isBooking && item.name === 'to' ? $formatter.formatDate(fields.from, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DD')
                  : isBooking && item.name === 'from' ? $formatter.formatDate(now, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD') : ''"
                  @change="item.active = 1; isBooking && item.name === 'from' ? fields.to = null : ''" style="height: 320px"
                  ></v-date-picker>
                  <!-- <v-date-picker v-model="item.dateTimePicker" color="primary lighten-1" no-title :id="`_${item.name}`" @change="item.active = 1"
                  :min="isBooking && item.name === 'to' ? $formatter.formatDate(fields.from, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DD') : ''"
                  :max="isBooking && item.name === 'from' ? $formatter.formatDate(fields.to, 'DD.MM.YYYY HH.mm', 'YYYY-MM-DD') : ''"></v-date-picker> -->
                </v-tab-item>
                <v-tab-item>
                  <v-card v-if="item.name === 'from' || item.name === 'to'" max-height="270" style="overflow-y: auto;">
                    <v-card-text class="pa-3 py-8">
                      <v-layout>
                        <v-flex class="px-auto mx-auto d-flex justify-center">
                          <v-autocomplete v-model="item.timePicker_hour" outlined dense hide-details style="max-width: 100px" :items="hours" hint="hour" @change="onChangeItemOneSix(item, null, `${item.timePicker_hour || '00'}:${item.timePicker_minute || '00'}`, false)"></v-autocomplete>
                          <span class="mx-3 font-weight-black my-auto headline">:</span>
                          <v-autocomplete v-model="item.timePicker_minute" outlined dense hide-details style="max-width: 100px" :items="minutes" hint="minute" @change="onChangeItemOneSix(item, null, `${item.timePicker_hour || '00'}:${item.timePicker_minute || '00'}`)"></v-autocomplete>
                        </v-flex>
                      </v-layout>
                      <!-- <v-list>
                        <v-list-item-group v-model="item.timePicker">
                          <v-list-item v-for="(time, index) in selectTimePickerValues" :key="index" :value="time.value" @click="onChangeItemOneSix(item, null, time.value)">
                            <v-list-item-content>
                              <div class="text-center"> {{ time.text }} </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list> -->
                    </v-card-text>
                  </v-card>
                  <v-card flat v-else>
                    <v-time-picker format="24hr" :close-on-content-click="false" v-model="item.timePicker" color="primary lighten-1" @change="onChangeItemOneSix(item, index)"
                      :min="isBooking && item.name === 'from' && item.dateTimePicker === $formatter.formatDate(now, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD') ? $formatter.formatDate(now, 'YYYY-MM-DDTHH:mm', 'HH:mm') : ''">
                    </v-time-picker>
                    <!-- <v-time-picker format="24hr" :close-on-content-click="false" v-model="item.timePicker" color="primary lighten-1" @change="onChangeItemOneSix(item)"
                    :min="isBooking && fields.from !== fields.to && item.name === 'to' ? $formatter.formatDate(fields.from, 'DD.MM.YYYY HH.mm', 'HH:mm') : ''"
                    :max="isBooking && fields.from !== fields.to && item.name === 'from' ? $formatter.formatDate(fields.to, 'DD.MM.YYYY HH.mm', 'HH:mm') : ''">
                    </v-time-picker> -->
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-menu>
          </template>
          <template v-if="item.type === 17">
            <v-file-input outlined :label="$t(item.label)" v-model="fields[item.name]" :placeholder="item.placeholder" dense
              :id="`_${item.name}`" :ref="`attachment`" multiple>
            </v-file-input>
            <div class="text-end" v-if="fields[`${item.name} oldAttachments`] && fields[`${item.name} oldAttachments`].length">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon dark v-bind="attrs" v-on="on"> <v-icon color="primary">mdi-file</v-icon> </v-btn>
                </template>
                <v-sheet elevation="10">
                  <v-sheet class="py-3 primary text-center" dark>
                    <span>Attachments List</span>
                  </v-sheet>
                  <div class="pa-4">
                    <v-card small v-for="(file, index) in fields[`${item.name} oldAttachments`]" :key="index" width="200" outlined class="my-1">
                      <v-row class="mt-0 py-0">
                        <v-col lg="2" class="py-1">
                          <v-icon class="mx-1" size="18">mdi-file</v-icon>
                        </v-col>
                        <v-col lg="6" class="text-truncate py-0 d-flex justify-center mt-2">
                          <span class="pl-3 caption py-0">{{ file.originalfilename || file.OriginalFileName }}</span><br><br>
                        </v-col>
                        <v-col lg="3" class="px-0 py-1">
                          <v-layout>
                            <v-flex>
                              <v-icon size="18" color="success" @click="downloadAttachmet(item.name, file)">mdi-download</v-icon>
                            </v-flex>
                            <v-flex>
                              <v-icon size="18" color="error" @click="deleteAttachment(item.name, file); fields[`${item.name} oldAttachments`].splice(index, 1)">mdi-close-circle-outline</v-icon>
                            </v-flex>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-sheet>
              </v-menu>
            </div>
          </template>
        </v-col>
      </template>
    </v-row>
  </v-form>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import customScript from '../../mixins/customScriptHandling'
export default {
  mixins: [customScript],
  props: {
    listOfFields: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Object,
      default: () => {}
    },
    isBooking: {
      type: Boolean,
      default: false
    },
    moduleName: String,
    propValue: String
  },
  data () {
    return {
      reRender: 0,
      autoCompleteRender: 0,
      deleteDocumentId: 0,
      deleteDocumentName: '',
      updateDocument: {},
      updateFilePicker: [],
      updateDocumentProp: '',
      showForm: false,
      self: this,
      now: moment().format('YYYY-MM-DDTHH:mm')
    }
  },
  components: {
    ModuleRender: () => import('./ModuleFormRender')
  },
  mounted () {
    this.$root.$on('callRerender', () => {
      this.reRender++
    })
    this.listOfFields.forEach((element, index) => {
      let validationScript = ''
      element.isShow = true
      element.loading = false
      element.validationCallback = [true]
      // Simple Conditional
      if (element.fielddisplayconditionwhen) {
        const foundElementIndex = this.listOfFields.findIndex(x => x.name === element.fielddisplayconditionwhen)
        if (foundElementIndex !== -1) {
          this.listOfFields[foundElementIndex].hideField = element.name
          this.listOfFields[foundElementIndex].hideConditions = {
            condition: element.fielddisplaycondition,
            value: element.fielddisplayconditionvalue
          }
        }
      }
      // Conditional Script manipulation
      if (element.conditionscript) {
        let conditionScript = this.decodeStringTobase64(element.conditionscript)
        conditionScript = `var show=true; \n${conditionScript} \n return show`
        // eslint-disable-next-line
        element.isShowElement = new Function('data', 'instance', conditionScript)
      }
      // Validation Script manipulation
      if (element.validationscript) {
        if (element.validateon === 'blur') {
          validationScript = this.decodeStringTobase64(element.validationscript)
          this.addValidationMethod(`${element.name}_validation`, validationScript)
          element.validationCallback = `${element.name}_validation`
        } else {
          element.validationCallback = [true]
        }
      }
      // Logical manipulation
      if (element.logicscripts && element.logicscripts.length > 0) {
        element.logicscripts.forEach(logic => {
          const logicScript = this.decodeStringTobase64(logic.logicscript)
          if (element.type === 5 && logic.logicevent === 'change') {
            element[logic.logicname] = logicScript
          } else if (element.type === 3 && logic.logicevent === 'change') {
            element[logic.logicname] = logicScript
          } else {
            element[logic.logicname] = logicScript
            // eslint-disable-next-line
            setTimeout(() => {
              const elementItem = this.$el.querySelector(`#_${element.name}`)
              if (elementItem) {
                elementItem.addEventListener(logic.logicevent, (event) => {
                  this.eventHandler(event, index, logic)
                })
              }
            }, 1000)
          }
        })
      }
    })
    this.showForm = true
    const fromItem = this.listOfFields.find((item) => item.name === 'from')
    const toItem = this.listOfFields.find((item) => item.name === 'to')
    if (this.listOfFields && this.listOfFields.length && this.fields.from && fromItem) this.checkForValidationOrFunction(fromItem, this.fields.from, null)
    if (this.listOfFields && this.listOfFields.length && this.fields.to && toItem) this.checkForValidationOrFunction(toItem, this.fields.to, null)
  },
  computed: {
    ...mapGetters(['getUsers']),
    hours: function () {
      const hours = []
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          hours.push(`0${i}`)
        } else {
          hours.push(`${i}`)
        }
      }
      return hours
    },
    minutes () {
      const minutes = []
      for (let i = 0; i < 60; i++) {
        if (i < 10) {
          minutes.push(`0${i}`)
        } else {
          minutes.push(`${i}`)
        }
      }
      return minutes
    },
    selectTimePickerValues () {
      const array = [{ text: '00.00', value: '00:00' }]
      let text, value
      for (let i = 0; i < 95; i++) {
        text = moment(array[array.length - 1].text, 'HH.mm').add(15, 'm').format('HH.mm')
        value = text.replace('.', ':')
        array.push({ text, value })
      }
      return array
    }
  },
  methods: {
    getValidation (validate, type = null) {
      if (validate === 'required') {
        if (type) return this.$_multiSelectValidation
        else return this.$_requiredValidation
      } else return null
    },
    onChangeItemOneSix (item, index, timePicker, dontCloseMenu = true) {
      if (dontCloseMenu) item.dateTimeMenu = false
      this.fields[item.name] = this.$formatter.formatDateTimeForPicker(item.dateTimePicker, timePicker || item.timePicker)
      if (this.fields.from && this.fields.to) {
        var from = this.$formatter.formatDate(this.fields.from, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm')
        var to = this.$formatter.formatDate(this.fields.to, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm')
        this.$root.$emit('loadObjects', { from, to })
        this.checkForValidationOrFunction(item, this.fields[item.name], index)
      }
    },
    onChangeText (item, index) {
      const isValidDate = this.$formatter.validateFormat(this.fields[item.name])
      if (isValidDate) {
        this.listOfFields[index].dateTimePicker = this.$formatter.formatDate(this.fields[item.name], 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD')
        this.listOfFields[index].timePicker = this.$formatter.formatDate(this.fields[item.name], 'DD.MM.YYYY HH:mm', 'HH:mm')
        if ((item.name === 'from' || item.name === 'to') && this.fields.from && this.fields.to) {
          var from = this.$formatter.formatDate(this.fields.from, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm')
          var to = this.$formatter.formatDate(this.fields.to, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm')
          this.$root.$emit('loadObjects', { from, to })
        } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'InvalidDateTime' })
        // this.checkForValidationOrFunction(item, this.fields[item.name], index)
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'InvalidDateTimeFormat' })
        // this.fields[item.name] = ''
        // this.listOfFields[index].dateTimePicker = null
        // this.listOfFields[index].timePicker = null
      }
    },
    // Extension get method
    getProperty (key) {
      if (this.moduleName === 'Account' && key === 'name') return this.fields[key] ? this.fields[key].navn : ''
      else return this.fields[key]
    },
    addHoursToDate (value, hours, format) {
      return this.$formatter.addHoursToDate(value, hours, format)
    },
    // Extension set method
    setProperty (key, value) {
      this.fields[key] = value
    },
    showAlert (type, message) {
      this.$root.$emit('snackbar', { snackbar: true, color: type, text: message })
    },
    getList (field) {
      const result = this.listOfFields.find(x => x.name === field)
      if (result && result.type === 5) {
        let list = []
        if (result.default_value && result.default_value.options && result.default_value.options.length > 0) list = result.default_value.options
        else list = result.customOptions ? result.customOptions : []
        return list
      } else return []
    },
    // Extension set select options method
    setOptionList (key, value, itemValue) {
      const foundElementIndex = this.listOfFields.findIndex(x => x.name === key)
      if (foundElementIndex !== -1) {
        const item = this.$formatter.cloneVariable(this.listOfFields[foundElementIndex])
        item.customOptions = value
        item.optionName = itemValue
        item.optionValue = itemValue
        this.$set(this.listOfFields, foundElementIndex, item)
      }
    },
    parseDate (date) {
      return this.$formatter.formatDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY')
    },
    getValuesFromAPI (searchText, value, index) {
      this.$root.$emit('getAPIValues', { searchText, value, index })
    },
    getValuesForModule (searchText, value, index) {
      this.$root.$emit('getModuleOptions', { searchText, value, index })
    },
    addNewFilesHandler (propId, propName, files) {
      this.$root.$emit('addNewDocuments', { property_id: propId, prop_name: propName, files })
      this.fields[propName].attachments = null
    },
    clearThisSignature (index) {
      const self = this
      const reference = `signaturePad_${index}`
      self.$refs[reference][0].clearSignature()
    },
    // Common validation method on blur
    addValidationMethod (name, script) {
      this[name] = () => [
        // eslint-disable-next-line
        new Function('input', 'instance', 'data', script)
      ]
    },
    // Manuppulation for Validation on change
    checkForValidationOrFunction (element, value, index) {
      element.conditionscript = () => {}
      // Minumum length validation
      if (element.minlength && value.length < element.minlength) {
        element.validationCallback = [`Minimum length should be ${element.minlength}`]
        this.$set(this.listOfFields, index, element)
        return false
      }
      // Maximum length validation
      if (element.maxlength && value.length > element.maxlength) {
        element.validationCallback = [`Maximum length should be ${element.maxlength}`]
        this.$set(this.listOfFields, index, element)
        return false
      }
      // Validate based on regex
      if (element.regexpattern) {
        var regex = new RegExp(element.regexpattern)
        if (regex.test(value)) element.validationCallback = [true]
        else element.validationCallback = ['Not in expected pattern']
        this.$set(this.listOfFields, index, element)
        return false
      }
      // Validate based on script
      if (element.validationscript && element.validateon !== 'blur') {
        let validationScript = this.decodeStringTobase64(element.validationscript)
        validationScript = `var valid=true;\n${validationScript}\nreturn valid`
        // eslint-disable-next-line
        let validationFunction = new Function('input', 'data', 'instance', validationScript)
        const result = validationFunction(value, this.fields, this.self)
        element.validationCallback = [result]
        this.$set(this.listOfFields, index, element)
        return false
      }
      // Conditional hide
      if (element.hideField) {
        const foundElementIndex = this.listOfFields.findIndex(x => x.name === element.hideField)
        // eslint-disable-next-line
        let checkCondition = element.type === 3 ? element.hideConditions.value == value.toString() : element.hideConditions.value === value // Condition for checkbox
        if (checkCondition) {
          // this.$set(this.listOfFields, foundElementIndex, { ...this.listOfFields[foundElementIndex], isShow: element.hideConditions.condition })
          this.listOfFields[foundElementIndex].isShow = element.hideConditions.condition
        } else this.listOfFields[foundElementIndex].isShow = !element.hideConditions.condition
        this.$forceUpdate()
      }
      // Onchange select logic script
      if ((element.type === 5 && element.logicscripts && element.logicscripts.length > 0) || (element.type === 3 && element.logicscripts && element.logicscripts.length > 0) || (element.type === 16 && element.logicscripts && element.logicscripts.length > 0)) {
        if (element.logicscripts.length > 0) {
          const haschangeEvent = element.logicscripts.find(x => x.logicevent === 'change' || x.logicevent === 'onchange')
          if (haschangeEvent) {
            // eslint-disable-next-line
            let logicalFunction = new Function('instance', 'input', 'data', element[haschangeEvent.logicname])
            logicalFunction(this, value, this.fields)
          }
        }
      }
      this.$set(this.listOfFields, index, element)
    },
    eventHandler (evt, index, logic) {
      const element = this.$formatter.cloneVariable(this.listOfFields[index])
      // eslint-disable-next-line
      let logicalFunction = new Function('instance', 'input', 'data', element[logic.logicname])
      logicalFunction(this, evt.target.value, this.fields)
    },
    deleteAttachment (fieldname, fileObj) {
      this.$root.$emit('deleteBookingAttachment', `?fieldname=${fieldname}&&fileguid=${fileObj.physicalpath || fileObj.PhysicalPath}`)
      // condition to be added for live reomving
    },
    downloadAttachmet (fieldname, fileObj) {
      this.$root.$emit('downloadBookingAttachment', `?fieldname=${fieldname}&&fileguid=${fileObj.physicalpath || fileObj.PhysicalPath}`)
    }
  },
  beforeDestroy () {
    this.$root.$off('callRerender')
  }
}
</script>
<style>
.attachment-icon-delete {
  float: right !important;
}
</style>
