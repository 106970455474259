<template>
  <div class="pa-10">
    <v-alert dense prominent text type="error" class="pa-10">
      <v-row align="center">
        <v-col class="grow">
          <strong> {{ view.meta.message || 'Error!' }} </strong>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['view'])
  }
}
</script>
