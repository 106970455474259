export default {
  calendarSettings (state) {
    return state.calendarSettings
  },
  deletePayload (state) {
    return state.deletePayload
  },
  getUsers (state) {
    return state.listOfUsers
  }
}
