import moment from 'moment'
export default {
  methods: {
    formsValuesRestructure (aliasFields, formId, checklistId, fields, i, formName) {
      fields.values = []
      const model = { title: '', data: [], form_id: formId, _id: checklistId }
      aliasFields.forEach((element, index) => {
        if (element.type === 5 && element.default_value && element.default_value.isAPI) {
          if (fields[element.name]) {
            const fieldsValue = fields[element.name]
            const key = element.default_value.name
            fields.values.push({ name: element.name, options: [{ [key]: fieldsValue }] })
          }
        } else if (element.type === 12) {
          fields[element.name] = element.default_value.grid_data.data
        } else if (element.type === 18) {
          fields[element.name] = element.default_value.grid_data.data
        } else if (element.type === 1 && (element.default_value && element.default_value.use_as_title)) {
          model.title = fields[element.name]
        } else if (element.type === 13) {
          const reference = `signaturePad_${element._id}`
          if (this.$refs.checklistReference[i] && this.$refs.checklistReference[i].$refs.checklistaction && this.$refs.checklistReference[i].$refs.checklistaction.$refs[formName].$refs[reference]) {
            const { isEmpty, data } = this.$refs.checklistReference[i].$refs.checklistaction.$refs[formName].$refs[reference][0].saveSignature()
            if (isEmpty) fields[element.name] = ''
            else fields[element.name] = data
          } else {
            fields[element.name] = ''
          }
        } else if (element.type === 15) {
          fields[element.name] = element.default_value
        } else if (element.type === 17) {
          model[element.name] = fields[element.name]
        } else if (element.type === 9) {
          this.attachmentsChecklist.push({
            fieldName: element.name,
            propId: element._id,
            formId: element.form_id,
            value: fields[element.name].attachments
          })
        }
      })
      return fields
    },
    checkByDomain (array, email) {
      return array.includes(email)
    },
    findDuplicateExists (listOfPermissionAssinged) {
      const permissions = listOfPermissionAssinged
      var valueArr = permissions.map(function (item) {
        return item.userOrGroupId
      })
      return valueArr.some(function (item, idx) {
        // eslint-disable-next-line
        return valueArr.indexOf(item) != idx
      })
    },
    getUsername (id) {
      if (id) {
        const userFound = this.getUsers.find(x => x._id === id)
        if (userFound) return userFound.name
        else return ''
      } else return ''
    },
    async constructRestrictionList ({ from, to }, settings, isFromCalendar, isMonthView) {
      const restrictions = []
      const {
        applyrestrictionondates,
        restricteddates,
        applyrestrictiononholidays,
        applyrestictionontimings,
        restrictedtimings,
        restrictedspecificdaystimings,
        restrictedspecificdatetimings
      } = settings
      if (applyrestrictionondates) {
        restricteddates.forEach((item) => {
          restrictions.push({
            start: this.$formatter.formatDate(item.from, 'DD.MM.YYYYTHH.mm', 'YYYY-MM-DDT00:00'),
            end: this.$formatter.formatDate(item.to, 'DD.MM.YYYYTHH.mm', 'YYYY-MM-DDT23:59'),
            title: item.message,
            color: '#616161',
            isRestricted: true
          })
        })
      }
      if (applyrestrictiononholidays) {
        const start = this.$formatter.formatDate(from, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD')
        const end = this.$formatter.formatDate(to, 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD')
        await this.$bookingApi.execute('get', `/bookingsopenapi/get_holidays?from=${start}&to=${end}`)
          .then(response => {
            response.data.forEach(ele => {
              const obj = {
                start: this.$formatter.formatDate(ele.date, 'DD.MM.YYYY', 'YYYY-MM-DDT00:00'),
                end: this.$formatter.formatDate(ele.date, 'DD.MM.YYYY', 'YYYY-MM-DDT23:59'),
                title: ele.name,
                color: '#FB8C00',
                isRestricted: true,
                isHoliday: true
              }
              if (isFromCalendar) this.events.push(obj)
              else restrictions.push(obj)
            })
            if (isFromCalendar) this.$set(this.specificEventCounts, 'holidays', response.data.length)
          })
      }
      if (applyrestictionontimings && !isMonthView) {
        restrictedspecificdatetimings.forEach((item) => {
          const datetimings = this.$formatter.enumerateDaysBetweenDates(moment(item.from, 'DD.MM.YYYYTHH.mm.ss'), moment(item.to, 'DD.MM.YYYYTHH.mm.ss'), 'YYYY-MM-DDT')
          datetimings.forEach((date) => {
            restrictions.push({
              start: date + item.startat,
              end: date + item.endat,
              title: item.message,
              color: '#616161',
              isRestricted: true
            })
          })
        })
        const dates = this.$formatter.enumerateDaysBetweenDates(moment(from, 'YYYY-MM-DDTHH:mm'), moment(to, 'YYYY-MM-DDTHH:mm'), 'YYYY-MM-DD')
        dates.forEach((date) => {
          restrictedtimings.forEach((item) => {
            restrictions.push({
              start: date + 'T' + item.startat,
              end: date + 'T' + item.endat,
              title: item.message,
              color: '#616161',
              isRestricted: true,
              noDisplayOnMonth: true
            })
          })
          restrictedspecificdaystimings.forEach((item) => {
            if (item.day === moment(date, 'YYYY-MM-DD').day()) {
              restrictions.push({
                start: date + 'T' + item.startat,
                end: date + 'T' + item.endat,
                title: item.message,
                color: '#616161',
                isRestricted: true,
                noDisplayOnMonth: true
              })
            }
          })
        })
      }
      return restrictions
    },
    constructModuleFields (list) {
      // Sorting fields by tab order
      list = list.sort(function (a, b) {
        return a.tab_order - b.tab_order
      })
      // Constructing fields with default value
      for (let i = 0; i < list.length; i++) {
        this.fields[list[i].name] = ''
        list[i].default_value = list[i].default_value ? JSON.parse(list[i].default_value) : null
        if (list[i].default_value) {
          if (list[i].type === 3 || list[i].type === 4 || list[i].type === 5) {
            if (list[i].default_value.is_multiselect && !this.moduleObj._id) {
              this.fields[list[i].name] = []
            } else if (list[i].type === 3 && !list[i].default_value.is_multiselect && !this.moduleObj._id) this.fields[list[i].name] = false
            else if (list[i].type !== 3 && !this.moduleObj._id) this.fields[list[i].name] = list[i].default_value.default_value
          } else if (!this.moduleObj._id) {
            if (list[i].type === 6 && list[i].default_value && list[i].default_value.setcurrentdate) {
              this.fields[list[i].name] = this.$formatter.getCurrentDate()
            } else this.fields[list[i].name] = list[i].default_value ? list[i].default_value.default_value : null
          }
          // separete panel fields and other fields
          const getPanelFieldIndex = list.findIndex(x => x._id === list[i].default_value.panelId)
          if (getPanelFieldIndex >= 0) {
            list[i].isPanelField = true
            if (!list[getPanelFieldIndex].panel_fields) list[getPanelFieldIndex].panel_fields = []
            list[getPanelFieldIndex].panel_fields.push(Object.assign({ ...list[i] }, { isPanelField: false }))
          }
        }
        if (list[i].type === 16) {
          if (list[i].name === 'from') {
            this.fields[list[i].name] = this.from ? this.from : null
            this.from = null
          }
          if (list[i].name === 'to') {
            this.fields[list[i].name] = this.to ? this.to : null
            this.to = null
          }
        }
      }
      this.listOfFields = list
      if (this.isBooking) {
        this.listOfFields.forEach((element, index) => {
          if (element.default_value && element.default_value.selectType === 'module') {
            this.getAllValuesFromModule(element, index)
          }
        })
      }
    },
    customizeValue (fieldValues) { // Load form values after checking permission
      this.listOfFields.forEach((element, index) => {
        if (element.default_value && element.type === 5 && element.default_value.is_multiselect) {
          fieldValues[element.name] = fieldValues[element.name] ? fieldValues[element.name] : []
        }
        if (element.type === 5 && element.default_value && element.default_value.selectType === 'module' && !this.isBooking) {
          const value = element.default_value.is_multiselect ? fieldValues[element.name] : [fieldValues[element.name]]
          this.getModuleData(element.default_value.selectedModule, value, index, 'listOfFields')
        }
        if (element.default_value && element.type === 15 && element.default_value.is_multiselect) {
          fieldValues[element.name] = fieldValues[element.name] ? fieldValues[element.name] : []
        }
        if (element.default_value && element.type === 5 && element.default_value.selectType === 'api') {
          element.default_value.options = []
          const text = fieldValues[element.name]
          if (text) {
            const item = {}
            item[`${element.default_value.name}`] = text
            element.default_value.options.push({ [`${element.default_value.name}`]: text })
          }
        }
        if (element.default_value && element.type === 5 && element.default_value.selectType === 'custom') {
          element.customOptions = []
          element.optionName = 'name'
          element.optionValue = 'name'
          const text = this.$formatter.cloneVariable(fieldValues[element.name])
          if (text) {
            const item = {}
            item[`${element.default_value.name}`] = text
            element.customOptions.push({ name: text })
          }
        }
        if (element.type === 10) {
          setTimeout(() => {
            if (this.$refs.formReferences) {
              this.$refs.formReferences.$refs[`signaturePad_${index}`][0].fromDataURL(fieldValues[element.name])
            }
          }, 300)
        }
        if (element.type === 6) {
          if (fieldValues[element.name]) {
            const date = fieldValues[element.name] ? this.$formatter.fromUtcToLocal(fieldValues[element.name], 'DD.MM.YYYYTHH:mm:ss') : null
            fieldValues[element.name] = this.$formatter.formatDate(date, '', 'DD.MM.YYYY')
            element.date_value = this.$formatter.formatDate(fieldValues[element.name], 'DD.MM.YYYY', 'YYYY-MM-DD')
          } else {
            element.date_value = fieldValues[element.name] = null
          }
        }
        if (element.type === 16) {
          if (fieldValues[element.name]) {
            const date = this.$formatter.fromUtcToLocal(fieldValues[element.name], 'DD.MM.YYYYTHH:mm:ss')
            fieldValues[element.name] = this.$formatter.formatDate(date, 'YYYY-MM-DDTHH:mm:ss', 'DD.MM.YYYY HH:mm')
            element.dateTimePicker = this.$formatter.formatDate(date, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')
            element.timePicker = this.$formatter.formatDate(date, 'YYYY-MM-DDTHH:mm:ss', 'HH:mm')
          } else {
            fieldValues[element.name] = element.dateTimePicker = element.timePicker = null
          }
        }
        if (element.type === 17) {
          if (fieldValues[element.name]) {
            var oldAttachments = this.$formatter.cloneVariable(fieldValues[element.name])
            fieldValues[element.name] = null
            fieldValues[`${element.name} oldAttachments`] = JSON.parse(oldAttachments)
          }
        }
      })
      this.fields = fieldValues
    },
    reconstructModuleFieldValues (fromBooking) {
      const model = this.$formatter.cloneVariable(this.fields)
      this.listOfFields.forEach((element, index) => {
        if (element.type === 5 || element.type === 15) {
          if (!fromBooking) {
            if (this.moduleName === 'Account' && element.name === 'name') {
              model[element.name] = model[element.name] ? typeof model[element.name] === 'object' ? model[element.name].navn : model[element.name] : null
            }
            if (model[element.name] === '0') model[element.name] = null
          }
        }
        if (element.type === 10) {
          const { isEmpty, data } = this.$refs.formReferences.$refs[`signaturePad_${index}`][0].saveSignature()
          if (!isEmpty) model[element.name] = data
        }
        if (element.type === 6) {
          model[element.name] = model[element.name] ? this.$formatter.formatDate(model[element.name], 'DD.MM.YYYY', 'YYYY-MM-DDTHH:mm:ss') : null
        }
        if (element.type === 13) {
          model[element.name] = model[element.name] ? parseFloat(model[element.name]) : 0
        }
        if (element.type === 16) {
          model[element.name] = model[element.name] ? this.$formatter.formatDate(model[element.name], 'DD.MM.YYYY HH:mm', 'YYYY-MM-DDTHH:mm:ss') : null
        }
        if (element.type === 17) {
          model[element.name] = this.fields[element.name]
        }
      })
      return model
    },
    bookingAppLogOut () {
      this.$cookie.delete(`${process.env.VUE_APP_NAME}_BOOKING_APP_USERID`)
      this.$cookie.delete(`${process.env.VUE_APP_NAME}_BOOKING_APP_USERDETAIL`)
      this.$store.commit('setUserDetails', {})
      // if (!isBookingPortal) this.$store.commit('viewTo', { page: 'login' })
      // if (showCalendar) {
      //   this.$store.commit('viewTo', { page: 'dashboard' })
      // } else this.$store.commit('viewTo', { page: 'login' })
    },
    // Encode the String
    encodeStringTobase64 (string) {
      if (string) return btoa(string)
    },
    // Decode the String
    decodeStringTobase64 (string) {
      if (string) return atob(string)
    },
    bookingErrorTypes () {
      return [{
        type: ''
      }]
    }
  }
}
