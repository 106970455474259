import moment from 'moment'
import CONSTANTS from '@/assets/json/constants.json'
export default {
  install (Vue) {
    Vue.prototype.$formatter = {
      getOneHourExpiryTime () {
        var now = new Date()
        now.setTime(now.getTime() + 1 * 3600 * 1000)
        return now.toUTCString()
      },
      formatDate (date, fromFormat, toFormat) {
        if (!date) return null
        return moment(date, fromFormat).isValid() ? moment(date, fromFormat).format(toFormat) : null
      },
      cloneVariable (data) {
        if (!data) return null
        return JSON.parse(JSON.stringify(data))
      },
      fromUtcToLocal (date, fromFormat) {
        if (!date) return null
        date = this.formatDate(date, fromFormat, 'DD.MM.YYYY HH:mm')
        return date ? moment.utc(date, 'DD.MM.YYYYTHH:mm:ss').toDate() : null
      },
      isArray (array) {
        return Array.isArray(array)
      },
      groupBy (array, key) {
        return array.reduce(function (total, item) {
          total[item[key]] = total[item[key]] || []
          total[item[key]].push(item)
          return total
        }, {})
      },
      isArrayHasData (array) {
        return array && array.length > 0
      },
      isEmptyObject (obj) {
        return Object.entries(obj).length === 0 && obj.constructor === Object
      },
      formatDateTimeForPicker (datePicker, timePicker) {
        if (datePicker && moment(datePicker).isValid()) datePicker = moment(datePicker).format('DD.MM.YYYY')
        else datePicker = moment().format('DD.MM.YYYY')
        const dateTime = moment(timePicker, 'HH:mm').format('HH:mm')
        return `${datePicker} ${dateTime}`
      },
      validateFormat (value, format) {
        return moment(value, format || 'DD.MM.YYYY HH:mm', true).isValid()
      },
      addHoursToDate (date, hours, format) {
        const dateFormatted = moment(date, format).format('DD.MM.YYYYTHH:mm:ss')
        return moment(dateFormatted, 'DD.MM.YYYYTHH:mm:ss').add(hours, 'hours').format('DD.MM.YYYY HH:mm')
      },
      enumerateDaysBetweenDates (startDate, endDate, format) {
        var now = startDate
        var dates = []
        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format(format || 'DD.MM.YYYY'))
          now.add(1, 'days')
        }
        return dates
      },
      getCurrentDate () {
        return moment().format('DD.MM.YYYY')
      },
      checkTheDateAndTimeAvailablity (model, restrictedList) {
        let allowBooking = true
        let error = null
        restrictedList.forEach(dates => {
          const keys = Object.keys(dates)
          keys.forEach((key) => {
            if (key === CONSTANTS.ISHOLIDAY) {
              const eventStartDate = moment(model.from, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD')
              const eventEndDate = moment(model.to, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD')
              var selectedDatesArr = this.enumerateDaysBetweenDates(moment(eventStartDate, 'YYYY-MM-DD'), moment(eventEndDate, 'YYYY-MM-DD'))
              if (selectedDatesArr.includes(this.formatDate(dates.start, 'YYYY-MM-DD', 'DD.MM.YYYY'))) allowBooking = false
            } else if (key === CONSTANTS.ISRESTRICTED) {
              const modelFromMoment = moment(model.from, 'YYYY-MM-DDTHH:mm').add(1, 'minute')
              const modelToMoment = moment(model.to, 'YYYY-MM-DDTHH:mm').subtract(1, 'minute')
              if (moment(modelFromMoment).isSame(moment(dates.start, 'YYYY-MM-DDTHH:mm')) || moment(modelFromMoment).isSame(moment(dates.end, 'YYYY-MM-DDTHH:mm'))) allowBooking = false
              if (moment(modelToMoment).isSame(moment(dates.start, 'YYYY-MM-DDTHH:mm')) || moment(modelToMoment).isSame(moment(dates.end, 'YYYY-MM-DDTHH:mm'))) allowBooking = false
              if (moment(modelFromMoment).isBetween(moment(dates.start, 'YYYY-MM-DDTHH:mm'), moment(dates.end, 'YYYY-MM-DDTHH:mm')) || moment(modelToMoment).isBetween(moment(dates.start, 'YYYY-MM-DDTHH:mm'), moment(dates.end, 'YYYY-MM-DDTHH:mm'))) allowBooking = false
              if (moment(moment(dates.start, 'YYYY-MM-DDTHH:mm')).isBetween(modelFromMoment, modelToMoment) || moment(moment(dates.end, 'YYYY-MM-DDTHH:mm')).isBetween(modelFromMoment, modelToMoment)) allowBooking = false
            }
          })
          if (!allowBooking) error = dates
        })
        return { allowBooking, error }
      }
    }
  }
}
