<template>
  <div>
    <v-container fluid class="pa-0 fixed">
      <v-row no-gutters>
        <v-col cols="12" lg="12" sm="12">
          <v-toolbar class="pt-1 pa-2 primary text-center" dark dense elevation="1" absolute bottom width="100%">
            <v-spacer></v-spacer>
            <a :href="`tel:${footerInfo.phone}`" class="text-decoration-none" v-if="footerInfo.phone" style="width: 300px;">
              <v-icon color="white" size="22" class="mr-1 mb-1"> mdi-phone </v-icon>
              <span class="white--text" v-if="!xsOnly"> {{ footerInfo.phone }} </span>
            </a>
            <v-spacer></v-spacer>
            <span v-if="footerInfo.companyaddress && !xsOnly" style="max-width: 100%; max-height: 100%; overflow: auto;">
              <v-icon color="white" size="22" class="mr-1 mb-1"> mdi-home-city </v-icon>
              <span class="white--text" v-if="!xsOnly"> {{ footerInfo.companyaddress }} </span>
            </span>
            <v-spacer></v-spacer>
            <a :href="`mailto:${footerInfo.email}`" class="text-decoration-none" v-if="footerInfo.email" style="width: 300px;">
              <v-icon color="white" size="22" class="mr-1 mb-1"> mdi-email </v-icon>
              <span class="white--text" v-if="!xsOnly"> {{ footerInfo.email }} </span>
            </a>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ['footerInfo']
}
</script>
<style scoped>
  .fixed {
    position: absolute;
    bottom: 0;
    left: 0;
  }
</style>
