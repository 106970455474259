<template>
  <form-render
    :checklistTemplateObj="checklistTemplateObj"
    :ref="reference"
    :dataValue="dataValue"
    @back="backToList"
    @update="onSuccessHandler"
    :key="reInit"
    :token="accessToken"
    class="pa-1"
    :isBooking="isBooking">
  </form-render>
</template>
<script>
import FormRender from './ChecklistForm.vue'
import { mapState } from 'vuex'
export default {
  /* checklisttemplateobj will contain checklistemplateobj while creating new
    checklisttemplateobj will contain actual saved checklist obj while updating */
  props: ['formId', 'formName', 'checklistId', 'module', 'propId', 'itemId', 'reference', 'isBooking', 'dataValue', 'checklistTemplateObj'],
  data () {
    return {
      reInit: 0,
      accessToken: process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN,
      // moduleId: this.$route.params.id
      moduleId: '610d23e3302cc325b842850a'
    }
  },
  computed: {
    ...mapState(['listOfChecklistTemplates'])
  },
  components: {
    'form-render': FormRender
  },
  methods: {
    onSuccessHandler (payload) {
      if (!this.isBooking) {
        if (payload.status === 'success') {
          if (this.fromBooking) {
            payload.data.title = this.checklistTemplateObj.name
            this.$eventBus.$emit('setChecklistId', payload.data, payload.canClose)
          } else if (payload.action === 'add' || payload.action === 'update') this.saveChecklistData(payload.data, payload.action, payload.canClose, this.checklistTemplateObj)
        } else if (payload.status === 'error') {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: payload.data.response.data })
        }
      }
    },
    saveChecklistData (checklist, action, canClose, checklistTemplateObj) {
      const recordId = '' // this.$route.params[this.propId]
      let method = ''
      let text = ''
      let checklistObj = {
        module_id: recordId,
        form_id: checklistTemplateObj.form_id,
        checklist_id: checklist._id,
        name: checklistTemplateObj.name,
        module: this.moduleId
      }
      if (checklist._id && action === 'update') {
        method = 'put'
        text = 'updatedSuccess'
      } else {
        method = 'post'
        text = 'addedSucess'
      }
      // While creating we will get checklistemplateobj in this.checklistTemplateObj
      // But during update we need to get value from listOfChecklisttemplate by matching checklistTemplateObj.checklist_template_id
      if (method === 'post') {
        checklistObj = {
          ...checklistObj,
          checklist_template_id: this.checklistTemplateObj._id,
          pdftemplatepath: checklistTemplateObj.pdftemplatepath,
          templateoriginalname: checklistTemplateObj.templateoriginalname
        }
      } else {
        const currentTemplateObj = this.listOfChecklistTemplates.find(x => x._id === checklistTemplateObj.checklist_template_id)
        if (!this.$formatter.isEmptyObject(currentTemplateObj)) {
          checklistObj = {
            ...checklistObj,
            checklist_template_id: currentTemplateObj._id,
            pdftemplatepath: currentTemplateObj.pdftemplatepath,
            templateoriginalname: currentTemplateObj.templateoriginalname
          }
        }
      }
      // Following Logic needs to improved while update also
      this.$bookingApi.execute('post', 'bookingsopenapi/create_checklist', [checklistObj])
        .then((response) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
          if (response.data) this.$eventBus.$emit('setChecklistId', response.data[0], canClose)
        })
    },
    backToList () {
      this.$eventBus.$emit('closeChecklistDialog')
    }
  }
}
</script>
