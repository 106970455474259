const componentStatusList = [
  {
    text: 'Yes',
    value: 'yes'
  },
  {
    text: 'No',
    value: 'no'
  },
  {
    text: 'Not applicable',
    value: 'not_applicable'
  }
]
const listOfFormTypes = [
  {
    text: 'Text field',
    value: 1
  }, {
    text: 'Text area',
    value: 2
  }, {
    text: 'Checkbox',
    value: 3
  }, {
    text: 'Radio',
    value: 4
  }, {
    text: 'Select',
    value: 5
  }, {
    text: 'Date',
    value: 6
  }, {
    text: 'Time',
    value: 7
  }, {
    text: 'Switch',
    value: 8
  }, {
    text: 'File',
    value: 9
  }, {
    text: 'Survey Component',
    value: 10
  }, {
    text: 'Divider',
    value: 11
  }, {
    text: 'Grid',
    value: 12
  }, {
    text: 'Signature',
    value: 13
  }, {
    text: 'Panel',
    value: 14
  }, {
    text: 'Invent Component',
    value: 15
  }, {
    text: 'DataGrid',
    value: 18
  }
]
export { componentStatusList, listOfFormTypes }
