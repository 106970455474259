<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" lg="12" class="text-center pt-2 pt-lg-4 pt-xl-2 mb-n2">
          <p class="text-h2 mb-2 font-weight-black grey--text text--darken-3"> {{ $t(calendarSettings.modulepluralname || '') }} </p>
          <p class="body-1 mb-0 font-weight-medium"> {{ $t('browseOur') }} {{ $t(calendarSettings.modulepluralname) || '' }} {{ $t('andMakeBooking') }} </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="11" class="mx-auto text-center">
          <div v-for="(item, index) in moduleObjects" :key="index" class="d-inline-flex">
            <v-card :width="xsOnly ? 270 : 320" height="390" class="ma-3" style="cursor: pointer;" tile @click="$store.commit('viewTo', { page: 'portfolio_view', meta: { item, apiBaseurl, tenantname: calendarSettings.tenantname, modulename: calendarSettings.modulename } })">
              <v-card-title class="pa-0">
                <img :src="item.profileimage ? `${apiBaseurl}${calendarSettings.tenantname}/module_profiles/${item.profileimage}` : noImage" alt="processdrive" height="222px" width="100%">
              </v-card-title>
              <v-card-text class="py-7 text-center">
                <p class="font-weight-bold grey--text text--darken-2 text-overflow" style="font-size: 1.4rem;" :title="item.data.name"> {{ item.data.name }} </p>
                <p class="title font-weight-black success--text text--darken-2"> {{ item.data.price_info }} </p>
                <p class="mb-0 black--text text-overflow" style="font-size: 1.05rem;" :title="item.data.short_description"> {{ item.data.short_description }} </p>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      moduleName: '',
      moduleObjects: [],
      apiBaseurl: ''
    }
  },
  computed: {
    ...mapState(['calendarSettings'])
  },
  mounted () {
    if (this.calendarSettings) this.moduleName = this.calendarSettings.modulename
    this.getModuleData()
    this.apiBaseurl = process.env.VUE_APP_IMAGE_URL
  },
  methods: {
    getModuleData () {
      this.$store.commit('showOrHideOverlayLoader', true)
      this.$bookingApi.execute('get', 'bookingsopenapi/get_form')
        .then((result) => {
          this.$bookingApi.execute('post', `bookingsopenapi/get_module_data_by_ids/${this.moduleName}`, result.data.moduleobjectids).then(response => {
            this.moduleObjects = response.data
          })
        }).finally(() => {
          this.$store.commit('showOrHideOverlayLoader', false)
        })
    }
  }
}
</script>
<style>
  .text-overflow {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
