<template>
  <div class="px-2 px-lg-7">
    <v-container fluid class="px-0 px-lg-16 pt-3">
      <v-row no-gutters>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-layout>
            <v-flex lg1 md1 sm1 xs1>
              <v-btn @click="$store.commit('viewTo', { page: 'portfolio' })" :fab="xsOnly" :small="xsOnly" color="grey darken-3" dark
              :class="viewObj.short_description ? 'mb-n7 mb-lg-n14' : 'mb-n10'">
                <v-icon class="pr-lg-1"> mdi-arrow-left </v-icon>
                <span v-if="!xsOnly"> {{ $t('back') }} </span>
              </v-btn>
            </v-flex>
            <v-flex lg10 md10 sm10 xs10 :class="[viewObj.short_description ? '' : 'pt-3', 'text-center pb-0 pt-2']">
              <p class="text-h4 mb-2 font-weight-black grey--text text--darken-3"> {{ viewObj.name }} </p>
              <p class="caption mb-0 font-weight-medium pt-0 pb-2"> {{ viewObj.short_description }} </p>
            </v-flex>
            <v-flex lg1 md1 sm1 xs1 class="text-right pr-10 mr-sm-11 pr-lg-0">
              <v-btn @click="$store.commit('viewTo', { page: 'booking', meta: { bookingID: '', fromPortfolio: true, roomId: viewObj._id } })"
              :fab="xsOnly" :small="xsOnly" color="success" dark :class="viewObj.short_description ? 'mb-n7 mb-lg-n14' : 'mb-n10'">
                <v-icon v-if="xsOnly" size="20" class="pl-1"> mdi-send </v-icon>
                <span v-else> {{ $t('bookNow') }} </span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-img :src="viewObj.profileimage ? `${view.meta.apiBaseurl}${view.meta.tenantname}/${viewObj.profileimage}` : noImage" width="100%"
          :height="images.length ? 350 : 482" style="position:relative" :contain="!viewObj.profileimage">
            <v-overlay :value="true" :absolute="true" class="d-flex align-end pl-lg-4" opacity="0.35">
              <p class="subheading font-weight-bold text-overflow" style="letter-spacing: 1px; text-shadow: 1.75px 1.75px black;">{{ viewObj.notes }}</p>
            </v-overlay>
            <template #placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-sheet class="mx-auto" elevation="1" v-if="images.length">
            <v-slide-group class="pa-0" show-arrows>
              <v-slide-item v-for="(image, index) in images" :key="index" class="py-2">
                <img :src="`${view.meta.apiBaseurl}${view.meta.tenantname}/${image.profileimage}`" height="132" width="175" class="pr-1" @click="changeProfileImage(image)" style="cursor: pointer;">
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <p :style="`font-size: 15.5px; max-height: ${xsOnly ? '' : '166px'}; overflow-y: auto;`" class="text-justify py-lg-2 px-2"
          v-if="viewObj.description">
            {{ viewObj.description }}
          </p>
          <module-meta :showProfile="false" :height="viewObj.description ? 300 : 500" :isForPortfolio="true"></module-meta>
        </v-col>
      </v-row>
      <v-row class="mt-xl-5">
        <v-col cols="12" lg="12" md="12" sm="12" class="py-lg-0" v-if="!xsOnly">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" :lg="viewObj.location_link ? '8' : '11'" :md="viewObj.location_link ? '8' : '11'" xl="8"
        class="mx-auto pt-1 pt-lg-3">
          <calendar :height="460" :roomId="viewObj._id"></calendar>
        </v-col>
        <v-col cols="12" lg="4" md="4" xl="4" class="pl-lg-1 py-1 pt-lg-3" v-if="viewObj.location_link">
          <v-card tile class="mb-2">
            <v-card-title class="white--text py-3 primary">
              <v-icon dark class="mr-2"> mdi-map-marker-radius-outline </v-icon>
              <span> {{ $t('location') }} </span>
            </v-card-title>
            <v-card-text class="pa-1 iframe"></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      viewObj: {},
      images: []
    }
  },
  components: {
    'module-meta': () => import('@/components/BookingObjMeta.vue'),
    calendar: () => import('@/views/Dashboard.vue')
  },
  computed: {
    ...mapState(['view'])
  },
  beforeMount () {
    const { item } = this.view.meta
    this.viewObj = this.$formatter.cloneVariable(item.data)
    this.viewObj._id = item._id
    this.getModuleImages(this.viewObj._id)
      .then((images) => {
        this.viewObj.profileimage = item.profileimage ? `module_profiles/${item.profileimage}` : images[0] ? images[0].profileimage : ''
        if (!images.find((image) => image.profileimage === this.viewObj.profileimage)) {
          this.images.unshift({ profileimage: this.viewObj.profileimage, notes: '' })
        }
        this.$forceUpdate()
      })
    setTimeout(() => {
      const iframeContainer = document.querySelector('.iframe')
      if (iframeContainer) {
        iframeContainer.innerHTML = this.viewObj.location_link
        const iframe = iframeContainer.children[0]
        iframe.setAttribute('height', 490)
        iframe.setAttribute('width', '100%')
      }
    }, 0)
  },
  mounted () {
    setTimeout(() => {
      this.$root.$emit('getModuleId', [this.viewObj._id])
    }, 0)
  },
  methods: {
    getModuleImages (id) {
      return new Promise((resolve, reject) => {
        this.$bookingApi.execute('get', `bookingsopenapi/get_module_images/${this.view.meta.modulename}/${id}`)
          .then((response) => {
            if (response.data && response.data.length) {
              response.data.sort(function (a, b) {
                return a.order - b.order
              })
              response.data.forEach((image) => {
                this.images.push({ profileimage: `module_images/${image.fileguid}`, notes: image.notes })
              })
              resolve(this.images)
            } resolve([])
          })
          .catch((err) => reject(err))
      })
    },
    changeProfileImage (image) {
      this.viewObj = Object.assign({}, this.viewObj, { profileimage: image.profileimage, notes: image.notes })
    }
  }
}
</script>
<style>
.bg-indigo {
  background-color: #3f51b5 !important;
}
</style>
