<template>
  <div>
    <template v-if="noDetails">
      <!-- <v-card elevation="1" tile min-height="70vh" width="100%" class="d-flex align-center justify-center"
      style="border: 1px solid grey;">
        <span class="grey--text text--darken-2 font-weight-black"> {{ $t('noDetails') }} </span>
      </v-card> -->
      <v-alert color="info" dark icon="mdi-cloud-alert" prominent outlined :min-height="xsOnly ? '10vh' : '70vh'" width="100%"
      class="d-flex align-center justify-center">
        {{ $t('noRecords') }}
      </v-alert>
    </template>
    <template v-if="cardData.length && !noDetails">
      <template v-for="(card, index) in cardData">
        <v-card elevation="1" :key="index" tile :height="height" class="mb-2"
        :style="`overflow-y: ${height ? 'auto' : ''};`" width="100%">
          <v-card-title class="primary white--text py-2 title">
            {{ $t('details') }}:
            <span class="mx-2 subtitle-1"> {{ card[0].value }} </span>
            <v-spacer></v-spacer>
            <v-icon color="white" size="22"> mdi-alert-circle-outline </v-icon>
          </v-card-title>
          <v-card-title class="pa-1" v-if="profileCard[index].profileimage && showProfile">
            <img width="100%" height="150px" :src="`${api_baseurl}${tenantName}/module_profiles/${profileCard[index].profileimage}`" :alt="card[0].value">
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-2" style="overflow-x: auto;">
            <table>
              <tr v-for="(item, key) in card" :key="key">
                <td class="font-weight-bold subtitle-2" v-if="item.is_show && item.type !== 11"> {{ $t(item.label) }}: </td>
                <td class="font-weight-medium" v-if="item.type === 3 && item.is_show"> {{ item.value ? $t('yes') : $t('no') }} </td>
                <td class="font-weight-medium" v-else-if="(item.type === 10 || item.type === 17) && item.is_show">
                  <img :src="item.value" width="100%" height="45px" alt="Image" v-if="item.value">
                </td>
                <td class="font-weight-medium" v-else-if="item.type === 6 && item.is_show"> {{ $formatter.formatDate(item.value, 'DD.MM.YYYYTHH.mm.ss', 'DD.MM.YYYY') }} </td>
                <td class="font-weight-medium" v-else-if="item.is_show && item.type !== 11"> {{ item.value }} </td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </template>
    </template>
  </div>
</template>
<script>
import mixin from '@/mixins/modulemeta.js'
export default {
  mixins: [mixin]
}
</script>
