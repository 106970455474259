<template>
  <v-app>
    <v-main>
      <template v-if="$store.state.view.page">
        <v-app-bar color="primary" v-if="!isCustomerPortal && $store.state.view && $store.state.view.page !== 'login'">
          <v-spacer v-if="!xsOnly"></v-spacer>
          <v-layout style="flex: 0 1 auto;" class="toolbar_menu main-toolbar">
            <v-toolbar flat color="primary" style="height: 40px !important">
              <v-toolbar-items style="padding-bottom: 20px !important">
                <v-btn dark text elevation="0" tile value="portfolio" @click="redirectTo('portfolio')"
                  :class="view.page === 'portfolio' ? 'v-btn v-item--active v-btn--active v-btn--is-elevated' : ''">
                  <v-icon dark class="mr-1"> mdi-book-information-variant </v-icon>
                  <span v-if="!xsOnly">{{ calendarSettings ? $t(calendarSettings.modulepluralname) || 'Booking' : '' }}</span>
                </v-btn>
                <v-btn dark elevation="0"  text tile value="booking" @click="redirectTo('makeBooking')" v-if="userDetails && userDetails._id"
                    :class="view.page === 'booking' ? 'v-btn v-item--active v-btn--active v-btn--is-elevated' : ''">
                  <v-icon dark class="mr-1"> mdi-calendar-edit </v-icon>
                  <span v-if="!xsOnly">{{ $t('makeBooking') }}</span>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
          </v-layout>
          <v-spacer></v-spacer>
          <span class="white--text" v-if="userDetails && userDetails.data && userDetails.data.name"> {{ $t('hi') }}, <span class="font-weight-bold"> {{ userDetails.data.name }} </span></span>
          <hr aria-orientation="vertical" style="height: 40px;" class="mx-2 ml-lg-4" v-if="userDetails && userDetails.data">
          <template v-if="userDetails && !userDetails._id">
            <v-icon class="mr-2 mr-lg-1" dark @click="redirectTo('login')" v-if="xsOnly"> mdi-login-variant </v-icon>
            <v-btn dark text @click="redirectTo('login')" class="px-0 px-lg-2" v-else>
              <v-icon class="mr-2 mr-lg-1"> mdi-login-variant </v-icon> {{ $t('login') }}
            </v-btn>
          </template>
          <template v-if="userDetails && userDetails._id">
            <v-icon @click="logoutHandler" class="mr-2 mr-lg-1" v-if="xsOnly" dark> mdi-logout </v-icon>
            <v-btn dark text @click="logoutHandler" class="px-0 px-lg-2" v-else>
              <v-icon class="mr-2 mr-lg-1"> mdi-logout </v-icon> {{ $t('logout') }}
            </v-btn>
          </template>
        </v-app-bar>
      </template>
      <v-container fill-height fluid style="background-color: #f1f2f3;" v-if="showPageLoader || !view.page">
        <v-row align="center" justify="center">
          <v-col cols="12" lg="3" align="center">
            <v-img contain width="200" height="100" :src="loadingGif"></v-img>
          </v-col>
        </v-row>
      </v-container>
      <template v-else>
        <v-toolbar width="100%" flat height="60" class="mb-n3" v-if="isCustomerPortal && $store.state.view && $store.state.view.page !== 'login' && view.page !== 'errorPage'">
          <v-toolbar-title>
            <v-icon size="31" class="mb-1 mr-1"> mdi mdi-semantic-web </v-icon>
            <span class="title font-weight-black grey--text text--darken-3 text-capitalize"> {{ moduleLabel || '' }} </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-for="(item, index) in sideMenuItems">
            <v-tooltip bottom v-if="item.show" :key="index">
              <template #activator="{ on, attrs }">
                <v-btn fab dark small :color="item.color" v-on="on" v-bind="attrs" class="mx-2"
                @click="$store.commit('viewTo', { page: item.path, meta: item.meta })">
                  <v-icon size="20"> {{ item.icon }} </v-icon>
                </v-btn>
              </template>
              <span> {{ item.title }} </span>
            </v-tooltip>
          </template>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn class="ml-3" fab small color="error" @click="logoutHandler(true)" v-on="on" v-bind="attrs">
                <v-icon size="20" class="ml-1"> mdi-logout </v-icon>
              </v-btn>
            </template>
            <span> {{ $t('logout') }} </span>
          </v-tooltip>
        </v-toolbar>
        <template v-if="view.page === 'login'">
          <v-layout fill-height align-center justify-center class="grey lighten-5">
            <v-flex lg12 xs11 class="text-center">
              <login :portalInfo="portalInfo"></login>
            </v-flex>
          </v-layout>
        </template>
        <dashboard v-else-if="view.page === 'dashboard'" class="py-1" :portalType="portalInfo.type"></dashboard>
        <booking v-else-if="view.page === 'booking'" :bookingId="view.meta.moduleObjId"></booking>
        <error-page v-if="view.page === 'errorPage'" :message="view.meta.message || 'Error!'"></error-page>
        <reports v-else-if="view.page === 'portal_report'"></reports>
        <portfolio v-else-if="view.page === 'portfolio' && portalInfo.type === 'booking_portal'"></portfolio>
        <portfolio-view v-else-if="view.page === 'portfolio_view'&& portalInfo.type === 'booking_portal'"></portfolio-view>
        <app-footer class="mt-12" :footerInfo="calendarSettings && calendarSettings.systemconfiguration"
          v-if="view.page !== 'login' && calendarSettings && calendarSettings.systemconfiguration && (calendarSettings.systemconfiguration.email || calendarSettings.systemconfiguration.phone || calendarSettings.systemconfiguration.companyaddress)">
        </app-footer>
      </template>
      <template v-if="snackbar">
        <v-snackbar :color="color" v-if="color !== 'apiError'" v-model="snackbar" :timeout="3000" top right class="errorPrompt">
          <v-alert :type="color" dense>
            <v-row align="center" no-gutters>
              <v-col class="grow">
                {{ isException ? text : $t(text) }}
              </v-col>
              <v-col class="shrink pl-4">
                <v-btn text @click="snackbar = false" icon>
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-snackbar>
        <v-dialog v-if="color === 'apiError'" v-model="snackbar" max-width="400px" persistent  transition="dialog-top-transition">
          <v-card>
            <!-- <v-sheet class="v-sheet--offset mx-auto rounded-circle" color="error" elevation="12" height="45">
              <v-icon dark class="d-flex pt-2 align-center">mdi-alert-outline</v-icon>
            </v-sheet> -->
            <v-card-title style="background:#ff5252">
              <v-flex class="d-flex align-center">
                <v-icon color="white" dark x-large>mdi-alert-outline</v-icon>
                <span class="pl-4" style="color:white">{{ $t('error') }}</span>
              </v-flex>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-3">
            <v-flex class="text-center">
              <span class="subtitle-1 font-weight-bold"> {{ isException ? text : $t(text) }} </span>
            </v-flex>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-flex class="text-center">
              <v-btn color="primary" small @click="snackbar = false">
                {{ $t('dismiss') }}
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </template>
      <!-- <v-snackbar v-model="snackbar" :color="color">
        {{ isException ? text : $t(text) }}
        <template v-slot:action="{ attrs }">
          <v-btn text @click="snackbar = false" v-bind="attrs"> {{ $t('close') }} </v-btn>
        </template>
      </v-snackbar> -->
    </v-main>
    <v-overlay :value="showOverlayLoader" absolute>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- DELETE WARNING -->
    <v-dialog v-model="showDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('deleteConfirm') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
        <h4> {{ $t('areYouSure') }} </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" id="deleteConfirm" :loading="loading" @click="confirmDelete">{{ $t('yes') }}</v-btn>
          <v-btn color="primary" id="deleteClose" @click="showDialog=false">{{ $t('no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { bookingApi } from './plugins/axios_settings'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'App',
  data () {
    return {
      text: '',
      snackbar: false,
      isException: false,
      color: '',
      portalConfig: null,
      // constants
      BOOKING_PORTAL: 'booking_portal',
      CUSTOMER_PORTAL: 'booking_customer_portal',
      loading: false,
      showCalendar: false
    }
  },
  components: {
    login: () => import('@/views/Login.vue'),
    dashboard: () => import('@/views/Dashboard.vue'),
    booking: () => import('@/views/Booking.vue'),
    'error-page': () => import('@/views/ErrorPage.vue'),
    reports: () => import('@/views/CustomerPortal/Reports.vue'),
    portfolio: () => import('@/views/Portfolio/Index.vue'),
    'portfolio-view': () => import('@/views/Portfolio/View.vue'),
    'app-footer': () => import('@/components/Footer.vue')
  },
  computed: {
    ...mapState(['view', 'userDetails', 'showPageLoader', 'portalInfo', 'showOverlayLoader']),
    ...mapGetters(['calendarSettings', 'showDialog', 'deletePayload']),
    showDialog: {
      get () {
        return this.$store.state.showDialog
      },
      set (val) {
        if (!val) this.$store.commit('hideDialog')
      }
    },
    moduleLabel () {
      return this.view.page === 'dashboard' ? 'Dashboard' : 'Reports'
    },
    isCustomerPortal () {
      return this.portalInfo.type === this.CUSTOMER_PORTAL
    },
    sideMenuItems () {
      return this.portalConfig ? [{
        title: this.$t('dashboard'),
        icon: 'mdi-calendar-month',
        color: 'indigo',
        path: 'dashboard',
        meta: { moduleLabel: this.$t('dashboard') },
        show: this.portalConfig?.showcalendar && this.view?.page !== 'dashboard'
      }, {
        title: this.$t('report'),
        icon: 'mdi-file-document-multiple-outline',
        color: 'primary',
        path: 'portal_report',
        meta: { isReportsPage: true, moduleLabel: this.$t('report') },
        show: this.portalConfig?.showreports && !this.view?.meta?.isReportsPage
      }] : []
    }
  },
  created () {
    this.$store.commit('enablePageLoader')
    const refId = document.querySelector('div#bflow')
      ? document.querySelector('div#bflow').getAttribute('data-ref')
      : document.querySelector('bflow')?.getAttribute('data-ref')
    // const refId = process.env.VUE_APP_ENV === 'production' ? document.querySelector('div#bflow')?.getAttribute('data-ref') : document.querySelector('bflow')?.getAttribute('data-ref')
    this.$store.dispatch('getPortalInfo', refId).then(() => {
      this.$cookie.set(`${process.env.VUE_APP_NAME}_BOOKING_APP_PORTAL_INFO`, JSON.stringify(this.portalInfo), { expires: this.$formatter.getOneHourExpiryTime() })
      this.$cookie.set(process.env.VUE_APP_BOOKING_HEADER_CODE, this.portalInfo.code, { expires: this.$formatter.getOneHourExpiryTime() })
      if (this.portalInfo.type === this.BOOKING_PORTAL) {
        this.$store.dispatch('getCalendarSettings', this.portalInfo.type)
      } else this.checkUserDetails()
    })
  },
  mounted () {
    this.$root.$on('snackbar', data => {
      this.snackbar = data.snackbar
      this.color = data.color
      this.text = data.text
      this.isException = data.isException
    })
    this.$root.$on('enableCalendar', (data) => {
      this.showCalendar = data
    })
  },
  beforeMount () {
    // error handling
    let text
    bookingApi.interceptors.response.use(
      (response) => {
        return Promise.resolve(response)
      },
      (error) => {
        if (error.response) {
          let exception = ''
          if (error.response.status === 409) {
            setTimeout(() => {
              window.location.reload()
            })
          } else {
            if (error.response.data) exception = `${error.response.data}`
            else exception = `${error.response.statusText}`
            this.$root.$emit('snackbar', { snackbar: true, color: 'apiError', text: exception ? JSON.stringify(exception) : `Http Server has returned ${error.response.status}`, isException: true })
            if (error.response.status === 421) {
              this.$cookie.delete(`${process.env.VUE_APP_NAME}_BOOKING_APP_USERID`)
              this.$store.commit('viewTo', { page: 'errorPage', meta: { message: error.response.data } })
            }
          }
        } else {
          text = error.message
          this.$root.$emit('snackbar', { snackbar: true, color: 'apiError', text: text ? JSON.stringify(text) : `Http Server has returned ${error.response.status}`, isException: true })
        }
        return Promise.reject(error)
      }
    )
  },
  methods: {
    logoutHandler (payload) {
      this.bookingAppLogOut()
      if (payload) this.$store.commit('viewTo', { page: 'login' })
      else {
        this.$cookie.delete(`${process.env.VUE_APP_NAME}_BOOKING_APP_USERDETAIL`)
        this.bookingUserID = null
        this.$root.$emit('enableCalendar', true)
        this.$store.commit('viewTo', { page: 'portfolio' })
      }
    },
    confirmDelete () {
      this.loading = true
      const model = this.deletePayload
      if (model.isSingle) {
        this.$store.dispatch('deleteSingleRecordHandler', model)
          .then(() => {
            this.$eventBus.$emit('deleteSuccess', model.module)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.$store.dispatch('deleteHandler', model)
          .then(() => {
            this.$eventBus.$emit('deleteSuccess', model.module)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    checkUserDetails () {
      const loggedInUserDetails = this.$cookie.get(`${process.env.VUE_APP_NAME}_BOOKING_APP_USERDETAIL`)
      this.setPortalConfig()
        .then(() => {
          if (loggedInUserDetails) {
            this.$store.commit('setUserDetails', JSON.parse(loggedInUserDetails))
            this.$store.commit('viewTo', { page: 'dashboard' })
          } else {
            this.$store.commit('viewTo', { page: 'login' })
          }
        })
      this.$store.commit('disablePageLoader')
    },
    setPortalConfig () {
      return new Promise((resolve, reject) => {
        this.$bookingApi.execute('get', 'customerportalopenapi/get_portal_info')
          .then((response) => {
            const obj = {}
            for (const i in response.data.configurations) {
              obj[i.split('_')[2]] = !!response.data.configurations[i]
            }
            this.portalConfig = obj
            const { domainname } = response.data
            this.$store.commit('setDomainName', domainname)
            this.$store.commit('portalConfig', obj)
            resolve(obj)
          })
          .catch((err) => reject(err))
      })
    },
    redirectTo (module) {
      switch (module) {
        case 'portfolio': this.$store.commit('viewTo', { page: 'portfolio' })
          break
        case 'calendar': this.$store.commit('viewTo', { page: 'dashboard' })
          break
        case 'makeBooking': this.$store.commit('viewTo', { page: 'booking', meta: '' })
          break
        case 'login': this.$store.commit('viewTo', { page: 'login' })
          break
      }
    }
  }
}
</script>
<style lang="scss">
  @import '@/assets/css/app.scss';
</style>
<style>
  html {
    overflow-y: auto !important;
  }
  #bflow::-webkit-scrollbar {
    display: none !important;
  }
.fc-content {
  padding: 2px;
}
td.fc-day.fc-widget-content, th.fc-day-header {
  border: 0.1px solid #EEEEEE !important;
}
.fc-day-header {
  padding: 7px 0 !important;
}
.fc-day-number {
  padding: 7px 7px 0 0 !important;
  font-weight: bold;
}
.fc-button {
  background-color:#1565c0 !important;
  color: white !important;
  background-image: none !important;
  border-radius: 0 !important;
  text-shadow: none !important;
  height: 2.3em !important;
}
.fc-day-grid-event .fc-content {
  text-overflow: ellipsis !important;
}
.fc-unthemed .fc-divider, .fc-popover .fc-header, .fc-list-heading td {
  background: #1565c0!important;
  padding: 8px!important;
  color: white!important;
}
.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #efebeb!important;
}
.fc-unthemed .fc-divider, .fc-popover .fc-header, .fc-list-heading td {
  padding: 0 !important
}
.fc-toolbar button:focus {
  z-index: 0 !important;
}
.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active {
  z-index: 0 !important;
}
.fc-toolbar.fc-header-toolbar {
  display: none !important;
}
.fc-other-month  {
  background-color: #f7f7f7 !important;
}
.fc-title {
  color: white!important;
}
.fc-time {
  color: white!important;
}
.admin-calendar .fc-time {
  color: black !important;
}
*{ text-transform: none !important; }
.custom_black_text label {
  color: black !important;
}
/* Added these codes for invent component style */
.proptable td {
  border: 1px solid #0000001f;
  border-collapse: collapse;
}
.dense_table .v-text-field--full-width > .v-input__control > .v-input__slot {
  min-height: auto !important;
  border-style: none !important;
}
.dense_table .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0 !important;
  font-size: 14px;
  border-style: none !important;
}
.dense_table .v-select > .v-input__control > .v-input__slot {
  padding: 0 !important;
  font-size: 14px;
  border-style: none !important;
}
.dense_table .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: none !important;
}
.dense_table .v-text-field > .v-input__control > .v-input__slot:after {
  border-color: transparent !important;
  border-style: unset !important;
}
.dense_table .v-text-field > .v-input__control > .v-input__slot:before {
  border-style: unset !important;
}
.dense_table:not(.invoiceProducts).v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: none !important;
}
/* data grid custom style */
.scroll-styles {
  overflow: auto;
}
.scroll-styles::-webkit-scrollbar, .datagrid-18-table > .v-data-table__wrapper::-webkit-scrollbar {
  height: 10px;
}
/* Added these codes for invent component style */
/* For Data Grid Text field */
.text_field_no_radius {
  border-radius: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.header-custom-padding > div.v-input__control > div.v-input__slot {
  padding: 0 2px !important;
}
.formRender .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: -18px !important;
}
* {
  font-family: "Open Sans", sans-serif !important;
}
.nobackground--card .theme--light.v-card {
  background-color: inherit !important;
}
.tenant-background {
  background:linear-gradient(#29323c, #485563)
}
/* Added these codes for invent component style */
.bg-indigo {
  background-color: #3f51b5 !important;
}
.whitesmoke {
  background-color: whitesmoke !important;
}
.snackbar .v-alert {
  height: 38px !important;
}
.errorPrompt .v-alert{
  margin-bottom: 7px;
}
.errorPrompt .v-alert__wrapper i {
  padding-top: 5px;
}
.errorPrompt .v-snack__content {
  padding: 4px 10px 0px;
}
</style>
