import axios from 'axios'
// import VueCookie from 'vue-cookie'

// const common = {}
// common['x-booking-ui-hv'] = VueCookie.get(process.env.VUE_APP_BOOKING_HEADER_CODE)
const bookingApi = axios.create({
  baseURL: process.env.VUE_APP_URL
})

const checklistApi = axios.create({
  baseURL: process.env.VUE_APP_CHECKLIST_URL,
  headers: {
    common: {
      'X-AccessToken': process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN
    }
  }
})

// eslint-disable-next-line
const document_url = 'https://checklistapi.apphouse.no/'

// eslint-disable-next-line
export { checklistApi, document_url, bookingApi }
