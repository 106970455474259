export default {
  view: {
    page: ''
  },
  calendarSettings: null,
  showPageLoader: false,
  showOverlayLoader: false,
  portalConfig: null,
  userDetails: {},
  portalInfo: {},
  showDialog: false,
  deletePayload: {},
  domainName: null
}
