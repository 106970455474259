import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import common from './mixins/common'
import helpers from './mixins/index'
import formatter from './plugins/formatter'
import bookingApi from './plugins/api_services'
import { i18n, loadLanguageAsync } from './lang/config'
import VueCookie from 'vue-cookie'
import VueSignaturePad from 'vue-signature-pad'
import eventBus from './plugins/events'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import Sortable from 'sortablejs'

// mixins
const mixins = { common, helpers }
Object.keys(mixins).forEach(key => {
  Vue.mixin(mixins[key])
})

// plugins
const plugins = { formatter, bookingApi, VueCookie, eventBus }
Object.keys(plugins).forEach(key => {
  Vue.use(plugins[key])
})

Vue.use(VueSignaturePad)
Vue.use(CKEditor)
Vue.directive('sortable', {
  inserted: function (el, binding) {
    // eslint-disable-next-line
    new Sortable(el, binding.value || {})
  }
})

Vue.config.productionTip = false
loadLanguageAsync(store.calendarSettings ? store.calendarSettings.defaultlanguage : 'no', store.calendarSettings ? store.calendarSettings.tenantname : '').then(() => {
  new Vue({
    store,
    i18n,
    vuetify,
    eventBus,
    render: h => h(App)
  }).$mount('#app')
})
