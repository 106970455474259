<template>
  <!-- <v-app> -->
    <v-form ref="checkListForm" :key="reRender">
      <!-- <v-container fluid> -->
        <v-progress-linear color="primary accent-4" indeterminate rounded height="6" v-if="fieldsList.length < 0 || progress_loading"></v-progress-linear>
        <v-row v-else>
          <template v-for="(item, index) in fieldsList">
           <v-col v-if="!item.isPanelField" :cols="item.columns && item.type !== 15 ? item.columns : '12'" :md="item.columns && item.type !== 15 ? item.columns : [10, 11, 12, 14, 15, 19].includes(item.type) ? '12' : '6'" :key="index">
              <template v-if="item.type === 1">
                <v-text-field dense outlined hide-details :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" :placeholder="item.placeholder"></v-text-field>
              </template>
              <template v-if="item.type === 2">
                <v-textarea dense outlined hide-details :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" :placeholder="item.placeholder"></v-textarea>
              </template>
              <template v-if="item.type === 3">
                <template v-if="item.default_value.checkboxtype ? item.default_value.checkboxtype === 1 : false">
                  <v-row align="center" class="mx-0">
                    <v-checkbox :id="item.name" class="ma-0 pa-0" hide-details v-model="fields[item.name]" :rules="getValidation(item.validation) || []"></v-checkbox>
                    <label :for="item.name"
                      class="pa-0 ma-0"
                      v-if="item.label">{{item.label}}</label>
                  </v-row>
                </template>
                <template v-else>
                  <v-row class="mx-0">
                    <v-col class="pa-0 pl-2" cols="12">
                      <label>{{ item.label }}:</label>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0" v-if="item.default_value.viewType === 'horizontal'">
                    <v-col cols="12" class="pa-0 scroll-styles">
                      <v-card class="d-flex flex-row" flat tile>
                        <v-card id="datagrid-container" tile flat :width="`${getCheckboxLabelWidth(options)}px`" min-height="60px" :min-width="`${getCheckboxLabelWidth(options)}px`" v-for="(options, index) in item.default_value.options.filter( x => x.isactive)" :key="index">
                          <v-row class="mx-0" no-gutters>
                            <v-checkbox v-if="options.value" class="mx-1 checkbox_position" hide-details v-model="fields[item.name]" :rules="getValidation(item.validation, 'multiple') || []" :label="options.label" :value="options.value" multiple></v-checkbox>
                          </v-row>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-layout v-else row wrap class="mx-0" :style="`${item.default_value.viewType === 'vertical' ? 'display:block' : ''}`">
                    <v-flex :class="`${item.default_value.viewType === 'vertical' ? 'xs12' : 'xs3'}`" v-for="(options, index) in item.default_value.options.filter( x => x.isactive)" :key="index">
                      <v-checkbox v-if="options.value" class="checkbox_position" hide-details v-model="fields[item.name]" :rules="getValidation(item.validation, 'multiple') || []" :label="options.label" :value="options.value" multiple></v-checkbox>
                    </v-flex>
                  </v-layout>
                </template>
              </template>
              <template v-if="item.type === 4">
                <label>{{ item.label }}:</label>
                <v-radio-group dense row hide-details v-model="fields[item.name]" :rules="getValidation(item.validation) || []">
                  <v-radio v-for="(options, index) in item.default_value.options" :key="index" :label="options.label" :value="options.value"></v-radio>
                </v-radio-group>
              </template>
              <template v-if="item.type === 5">
                <template v-if="item.default_value.isAPI">
                  <v-autocomplete dense outlined :items="item.default_value.options" :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" :key="autoCompleteRender"
                  @keyup="getValuesFromAPI(`searchInput${index}`, item.default_value, index)" autocomplete="off" :ref="`searchInput${index}`"
                  :placeholder="item.placeholder" clearable hide-details :item-text="item.default_value.name" :item-value="item.default_value.name"></v-autocomplete>
                </template>
                <template v-else>
                  <v-autocomplete dense outlined :items="item.default_value.options" :rules="item.validation ? $_multiSelectValidation : []" :label="item.label"
                    :multiple="item.default_value.is_multiselect" v-model="fields[item.name]" :placeholder="item.placeholder" clearable hide-details
                    item-text="label" item-value="value"></v-autocomplete>
                </template>
              </template>
              <template v-if="item.type === 6">
                <v-menu v-model="item.date_menu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense outlined hide-details v-model="fields[item.name]" :placeholder="item.placeholder" :rules="getValidation(item.validation) || []" clearable prepend-icon="mdi-calendar" :label="item.label" readonly v-on="on" @click:clear="fields[item.name] = null"></v-text-field>
                  </template>
                  <v-date-picker v-model="item.date_value" @input="fields[item.name] = parseDate(item.date_value), item.date_menu = false"></v-date-picker>
                </v-menu>
              </template>
              <template v-if="item.type === 7">
                <v-menu :ref="`time_menu_${index}`" v-model="item.time_menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="fields[item.name]" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense outlined hide-details v-model="fields[item.name]" :rules="getValidation(item.validation) || []" :placeholder="item.placeholder" clearable prepend-icon="mdi-timer" :label="item.label" readonly v-on="on" @click:clear="fields[item.name] = null"></v-text-field>
                  </template>
                  <v-time-picker v-if="item.time_menu" v-model="fields[item.name]" format="24hr" full-width @click:minute="$refs[`time_menu_${index}`][0].save(fields[item.name])"></v-time-picker>
                </v-menu>
              </template>
              <template v-if="item.type === 8">
                <v-switch dense inset :label="item.label" :rules="getValidation(item.validation) || []" v-model="fields[item.name]" hide-details></v-switch>
              </template>
              <template v-if="item.type === 9">
                <v-file-input outlined :label="item.label" multiple @change="uploadDocumentHandler(item._id, item.name, fields[item.name]['attachments'])" :rules="getValidation(item.validation) || []" v-model="fields[item.name]['attachments']" hide-details :placeholder="item.placeholder"></v-file-input>
                <v-row class="mx-0 ml-8 mt-3" :key="reRender">
                  <v-col cols="12" class="pa-0" v-for="(document, docIndex) in listOfDocuments[item.name]" :key="document._id">
                    <v-layout>
                      <p class="pa-0 ma-0 my-auto mr-3">{{document.file_original_name}}</p>
                      <v-spacer></v-spacer>
                      <v-btn icon color="info" @click="downloadDocument(document._id)">
                        <v-scale-transition>
                          <v-icon>mdi-cloud-download</v-icon>
                        </v-scale-transition>
                      </v-btn>
                      <v-btn icon color="error" @click="deleteDocument(document._id, item.name)">
                        <v-scale-transition>
                          <v-icon>mdi-delete-circle</v-icon>
                        </v-scale-transition>
                      </v-btn>
                    </v-layout>
                    <v-divider v-if="listOfDocuments[item.name].length > (docIndex + 1)"></v-divider>
                  </v-col>
                </v-row>
              </template>
              <template v-if="item.type === 10">
                <v-card outlined class="pa-0">
                  <v-card-title class="pa-1 subtitle-1">{{ item.label }}
                    <v-radio-group dense v-model="fields[item.name]['answer']" row class="ml-2 mt-6">
                      <v-radio :label="$t(item.text)" :value="item.value" v-for="(item, index) in statusList" :key="index"></v-radio>
                    </v-radio-group>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="(item.default_value.hideComments && item.default_value.hideAttachments) || hasCommentOrAttachment(fields[item.name], item)" x-small fab text color="primary" @click="$set(item, 'expandsurveysection', !item.expandsurveysection)">
                      <v-icon>{{ `${ item.expandsurveysection ? 'mdi-arrow-up' : 'mdi-arrow-down'}`}}</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-divider v-show="(!item.default_value.hideComments || !item.default_value.hideAttachments) || item.html"></v-divider>
                  <v-card-text v-if="item.expandsurveysection" class="pa-0" v-show="(!item.default_value.hideComments || !item.default_value.hideAttachments) || item.html">
                    <v-tabs>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab v-if="!item.default_value.hideComments">
                        <v-icon>mdi-comment-text-outline</v-icon>
                        {{$t('comments')}}
                      </v-tab>
                      <v-tab v-if="!item.default_value.hideAttachments">
                        <v-icon class="mdi-rotate-270">mdi-attachment</v-icon>
                        {{$t('attachments')}}
                        <v-avatar left class="primary ml-2" for size="25"><span class="white--text">{{ listOfDocuments[item.name] && listOfDocuments[item.name].length > 0 ? (listOfDocuments[item.name].length) : '0' }}</span></v-avatar>
                      </v-tab>
                      <v-tab-item v-if="!item.default_value.hideComments">
                        <v-divider></v-divider>
                        <v-card flat tile>
                          <v-card-text>
                            <v-textarea dense hide-details outlined v-model="fields[item.name]['comments']"></v-textarea>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item v-if="!item.default_value.hideAttachments">
                        <v-divider></v-divider>
                        <v-card flat tile :key="reRender">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-file-input label="Documents" hide-details multiple @change="uploadDocumentHandler(item._id, item.name, fields[item.name]['attachments'])" v-model="fields[item.name]['attachments']" outlined dense></v-file-input>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col cols="12" lg="1" md="2" sm="3" v-for="(document) in listOfDocuments[item.name]" :key="document._id" class="pa-0 pl-2 mt-1">
                                <v-hover v-slot:default="{ hover }">
                                  <v-card :elevation="hover ? 14 : 1" shaped flat>
                                    <v-card-text class="text-center pa-1">
                                      <template v-if="document.imgObj && document.imgObj.url">
                                        <v-img contain height="50" :lazy-src="document.imgObj.url" :src="document.imgObj.url"></v-img>
                                      </template>
                                      <v-avatar size="52" class="profile" v-else>
                                        <v-icon :size="50" :color="document.imgObj.color">{{ document.imgObj.icon }}</v-icon>
                                      </v-avatar>
                                    </v-card-text>
                                    <v-card-subtitle class="pa-1 text-center text-truncate">
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <span v-on="on" class="font-weight-bold">{{ document.file_original_name }}</span>
                                        </template>
                                        <span>{{ document.file_original_name }}</span>
                                      </v-tooltip>
                                    </v-card-subtitle>
                                    <v-divider></v-divider>
                                    <v-card-actions class="pa-0">
                                      <v-spacer></v-spacer>
                                      <v-btn icon color="primary" @click="editDocument(document, item.name)">
                                        <v-scale-transition>
                                          <v-icon v-if="hover">mdi-lead-pencil</v-icon>
                                        </v-scale-transition>
                                      </v-btn>
                                      <v-btn icon color="info" @click="downloadDocument(document._id)">
                                        <v-scale-transition>
                                          <v-icon v-if="hover">mdi-cloud-download</v-icon>
                                        </v-scale-transition>
                                      </v-btn>
                                      <v-btn icon color="error" @click="deleteDocument(document._id, item.name, index)">
                                        <v-scale-transition>
                                          <v-icon v-if="hover">mdi-delete-circle</v-icon>
                                        </v-scale-transition>
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-hover>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                </v-card>
              </template>
              <template v-if="item.type === 11">
                <v-subheader v-if="item.label" class='pa-0'><strong>{{ item.label }}:</strong></v-subheader>
                <v-divider></v-divider>
              </template>
              <template v-if="item.type === 12">
                <v-card class="d-flex align-content-start flex-wrap pa-0 businessflow_form_grid" flat tile>
                  <v-card min-width="80" max-width="80" :title="grid.label" v-for="(grid, gIndex) in item.default_value.grid_data.labels" :key="gIndex" class="pa-0 pl-1 text-left text-truncate" outlined tile>
                    <span class="subtitle-2">{{ grid.label }}</span>
                  </v-card>
                </v-card>
                <v-card class="d-flex align-content-start flex-wrap pa-0 businessflow_form_grid" flat tile v-for="(row, rIndex) in item.default_value.grid_data.data" :key="rIndex">
                  <v-card max-width="80" class="pa-0" outlined tile v-for="(grid, dIndex) in row" :key="dIndex">
                    <v-text-field single-line dense flat full-width v-model="grid.value" solo hide-details></v-text-field>
                  </v-card>
                </v-card>
                <v-btn class="mt-2" fab dark small color="primary" @click="addnewLineOnGrid(index, item.label)">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <template v-if="item.type === 18"><!-- DataGrid Component -->
                <v-simple-table class="bordered--table scroll-styles" v-if="item.default_value && item.default_value.grid_data && item.default_value.grid_data.labels">
                  <thead>
                    <tr>
                      <th id="" style="height:34px" :style="`min-width:${grid.width}px`" v-for="(grid, index) in item.default_value.grid_data.labels" :key="index">{{ grid.label }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(trData, trIndex, rowIndexValue) in item.default_value.grid_data.data" :key="trIndex">
                      <td v-for="(tdGrid, tdIndex) in item.default_value.grid_data.labels" :key="tdIndex">
                        <template v-if="trData[tdIndex]">
                          <template v-if="tdGrid.field.type === fieldTypeConstants.STRING || tdGrid.field.type === fieldTypeConstants.NUMBER">
                            <v-text-field v-model="trData[tdIndex].value" :placeholder="tdGrid.field.type === fieldTypeConstants.STRING ? 'Text' : 'Number'" dense hide-details :type="tdGrid.field.type === fieldTypeConstants.NUMBER ? 'number' : 'text'"></v-text-field>
                          </template>
                          <template v-if="tdGrid.field.type === fieldTypeConstants.CHECKBOX && tdGrid.field.typedata">
                            <v-layout wrap class="col" v-if="tdGrid.field.typedata.listItems">
                              <v-checkbox v-for="(options, index) in tdGrid.field.typedata.listItems" :key="index" v-model="trData[tdIndex].value" class="mt-0  pa-1 mx-1" hide-details :label="options.label" :value="options.value" multiple></v-checkbox>
                            </v-layout>
                          </template>
                          <template v-if="tdGrid.field.type === fieldTypeConstants.RADIOBOX && tdGrid.field.typedata">
                            <v-row class="mx-0" v-if="tdGrid.field.typedata.listItems">
                              <v-radio-group class="mt-0" row hide-details v-model="trData[tdIndex].value">
                                <v-radio v-for="(options, index) in tdGrid.field.typedata.listItems" :key="index" :label="options.label" :value="options.value"></v-radio>
                              </v-radio-group>
                            </v-row>
                          </template>
                          <template v-if="tdGrid.field.type === fieldTypeConstants.SELECT && tdGrid.field.typedata">
                            <v-autocomplete dense :items="tdGrid.field.typedata.listItems" :multiple="tdGrid.field.typedata.isMultiSelect" clearable hide-details
                            item-text="label" item-value="value" v-model="trData[tdIndex].value">
                            <template v-slot:selection="{ item, index }">
                              <span v-if="index === 0">{{ item.label }}</span>
                              <span v-if="index === 1" class="grey--text caption">(+{{ trData[tdIndex].value.length - 1 }} {{ $t('others') }})</span>
                            </template>
                            </v-autocomplete>
                          </template>
                          <template v-if="tdGrid.field.type === fieldTypeConstants.LABEL">
                            <label v-if="tdGrid.field.typedata">
                              <span v-if="tdGrid.field.typedata.calculatingValue && tdGrid.field.typedata.insertPosition === 'prefix'">{{+tdGrid.field.typedata.calculatingValue * (+rowIndexValue + 1)}}</span>
                              {{tdGrid.field.typedata.labeltext}}
                              <span v-if="tdGrid.field.typedata.calculatingValue && tdGrid.field.typedata.insertPosition === 'suffix'">{{+tdGrid.field.typedata.calculatingValue * (+rowIndexValue + 1)}}</span>
                            </label>
                          </template>
                          <template v-if="tdGrid.field.type === fieldTypeConstants.DATE">
                            <v-menu v-model="trData[tdIndex].date_menu" :close-on-content-click="false" max-width="290">
                              <template v-slot:activator="{ on }">
                                <v-text-field dense hide-details v-model="trData[tdIndex].value" clearable prepend-icon="mdi-calendar" readonly v-on="on" @click:clear="trData[tdIndex].value = null"></v-text-field>
                              </template>
                              <v-date-picker v-model="trData[tdIndex].date_value" @input="trData[tdIndex].value = parseDate(trData[tdIndex].date_value), trData[tdIndex].date_menu = false"></v-date-picker>
                            </v-menu>
                          </template>
                        </template>
                      </td>
                    </tr>
                    <tr style="background: lightgrey" v-if="item.showSum.showSumRow && !$formatter.isEmptyObject(item.default_value.grid_data.data)">
                      <!-- class text-right -->
                      <th id="" style="height:30px" v-for="(sumRow, sumIndex) in item.default_value.grid_data.labels" :key="sumIndex">
                        <span v-if="item.showSum.sumColIndexes.includes(sumIndex)" style="font-size: 0.9rem;">
                          {{ getGridDataValuesTotal(sumIndex, item.default_value.grid_data.data) }}
                        </span><!-- dotWithComma -->
                      </th>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-btn class="mt-2" fab dark small color="primary" @click="addnewLineOnGrid(index, item.label)">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              <template v-if="item.type === 13">
                <v-subheader v-if="item.label" class='pa-0'><strong>{{ item.label }}:</strong></v-subheader>
                <VueSignaturePad width="100%" height="180px" class="signature--pad" :ref="`signaturePad_${item._id}`"/>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn color="info" fab x-small dark v-on="on" class="mt-1" @click="clearThisSignature(item._id)">
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Reset</span>
                </v-tooltip>
              </template>
              <template v-if="item.type === 14">
                <v-card class="pa-1">
                  <v-card-title class="pa-1 caption font-weight-bold">
                    {{ item.label }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pa-1">
                    <form-render
                      :key="item._id" :ref="`${index}_${item.name}`"
                      :itemId="item._id"
                      :token="token"
                      :panelFields="item.panel_fields"
                      :isPanelFieldsRender="true"
                      class="pa-1" :fromPanel="true">
                    </form-render>
                  </v-card-text>
                </v-card>
              </template>
              <!-- label component -->
              <template v-if="item.type === 17">
                <span class='pa-0 subtitle-2' :style="{ fontSize: `${item.default_value.font_size}em !important` }">{{ item.label }}</span>
              </template>
              <!-- html component -->
              <template v-if="item.type === 16">
                <div v-html="item.default_value.html_content"></div>
              </template>
              <!-- advanced survey component -->
              <template v-if="item.type === 19">
                <v-card class="d-flex flex-row" flat>
                  <v-card flat outlined width="100%">
                  <v-row class="mx-0" no-gutters>
                    <v-layout class="cols" style="align-items:center">
                      <v-spacer></v-spacer>
                      <v-btn :disabled="(item.default_value.hideComments && item.default_value.hideAttachments) || hasCommentOrAttachment(fields[item.name], item)" x-small fab text color="primary" @click="$set(item, 'expandsurveysection', !item.expandsurveysection)">
                        <v-icon>{{ `${ item.expandsurveysection ? 'mdi-arrow-up' : 'mdi-arrow-down'}`}}</v-icon>
                      </v-btn>
                    </v-layout>
                    </v-row>
                    <v-row class="mx-0" no-gutters>
                      <v-col class="pa-0 pr-3 pb-2" :cols="`${['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : getCols(item)}`">
                        <v-layout row class="mx-0" wrap>
                          <v-col class="pa-0" cols="12" xs="12" sm="12" md="8" lg="8">
                            <v-card-title class="pt-0 pa-1 subtitle-1">{{ item.label }}</v-card-title>
                          </v-col>
                          <v-col class="pa-0" cols="12" xs="12" sm="12" md="4" lg="4">
                            <v-radio-group class="pa-0 ma-0 pl-1" v-model="fields[item.name].answer" hide-details row>
                              <v-radio class="pa-0 ma-0" :label="item.label" :value="item.value" v-for="(item, index) in (item.default_value.options.filter( x => x.isactive).filter( x => x.isactive))" :key="index"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-layout>
                      </v-col>
                      <v-col class="pa-0 my-auto" :cols="`${['xs', 'sm'].includes($vuetify.breakpoint.name) ? 12 : 12 - getCols(item)}`" v-if="item.default_value.checkboxFlags.signature || item.default_value.checkboxFlags.date">
                        <v-layout row wrap class="mx-0">
                          <v-col class="pa-0 px-1" v-if="item.default_value.checkboxFlags.signature">
                            <v-text-field outlined hide-details :label="$t('signature')" v-model="fields[item.name].signature" dense></v-text-field>
                          </v-col>
                          <v-col class="pa-0 px-1" v-if="item.default_value.checkboxFlags.date">
                            <v-menu :close-on-content-click="false" v-model="fields[item.name].date_menu" max-width="290">
                              <template v-slot:activator="{ on }">
                                <v-text-field dense outlined v-model="fields[item.name].date" hide-details clearable prepend-inner-icon="mdi-calendar" :label="$t('date')" v-on="on" @click:clear="fields[item.name].date = null"></v-text-field>
                              </template>
                              <v-date-picker v-model="fields[item.name].date_value" @input="fields[item.name].date_menu = false, fields[item.name].date = parseDate(fields[item.name].date_value)"></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-layout>
                      </v-col>
                    </v-row>
                    <v-divider v-show="(!item.default_value.hideComments || !item.default_value.hideAttachments) || item.html"></v-divider>
                    <v-card class="component-link" v-if="item.html">
                      <v-card-text class="pa-2">
                        <p v-html="item.html"></p>
                      </v-card-text>
                      <v-divider></v-divider>
                    </v-card>
                    <v-expand-transition>
                      <v-card v-if="item.expandsurveysection">
                        <v-card-text class="pa-0" v-show="(!item.default_value.hideComments || !item.default_value.hideAttachments) || item.html">
                          <v-tabs>
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab v-if="!item.default_value.hideComments">
                              <v-icon>mdi-comment-text-outline</v-icon>
                              {{ $t('comments') }}
                            </v-tab>
                            <v-tab v-if="!item.default_value.hideAttachments">
                              <v-icon class="mdi-rotate-270">mdi-attachment</v-icon>
                              {{ $t('attachments') }}
                              <v-avatar left class="primary ml-2" for size="25"><span class="white--text" v-if="listOfDocuments[item.name]">{{ (listOfDocuments[item.name].length) }}</span><span v-else class="white--text">0</span></v-avatar>
                            </v-tab>
                            <v-tab-item v-if="!item.default_value.hideComments">
                              <v-divider></v-divider>
                              <v-card flat tile>
                                <v-card-text>
                                  <v-textarea v-model="fields[item.name]['comments']" hide-details outlined ></v-textarea>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                            <v-tab-item v-if="!item.default_value.hideAttachments">
                              <v-divider></v-divider>
                              <v-card flat tile :key="reRender">
                                <v-card-text>
                                  <v-row>
                                    <v-col cols="12" md="6">
                                      <v-file-input v-model="fields[item.name]['attachments']" :label="$t('documents')" hide-details multiple @change="uploadDocumentHandler(item._id, item.name, fields[item.name]['attachments'])" outlined dense></v-file-input>
                                    </v-col>
                                  </v-row>
                                  <v-row no-gutters>
                                    <v-col cols="12" lg="1" md="2" sm="3" v-for="(document) in listOfDocuments[item.name]" :key="document._id" class="pa-0 pl-2 mt-1">
                                      <v-hover v-slot:default="{ hover }">
                                        <v-card :elevation="hover ? 14 : 1" shaped flat>
                                          <v-card-text class="text-center pa-1">
                                            <template v-if="mimeTypeOfAttachment(document).url">
                                              <v-img contain height="50" :lazy-src="mimeTypeOfAttachment(document).url" :src="mimeTypeOfAttachment(document).url"></v-img>
                                            </template>
                                            <v-avatar size="52" class="profile" v-else>
                                              <v-icon :size="50" :color="mimeTypeOfAttachment(document).color">{{ mimeTypeOfAttachment(document).icon }}</v-icon>
                                            </v-avatar>
                                          </v-card-text>
                                          <v-card-subtitle class="pa-1 text-center text-truncate">
                                            <v-tooltip bottom>
                                              <template v-slot:activator="{ on }">
                                                <span v-on="on" class="font-weight-bold">{{ document.file_original_name }}</span>
                                              </template>
                                              <span>{{ document.file_original_name }}</span>
                                            </v-tooltip>
                                          </v-card-subtitle>
                                          <v-divider></v-divider>
                                          <v-card-actions class="pa-0">
                                            <v-spacer></v-spacer>
                                            <v-btn icon color="primary" @click="editDocument(document, item.name)">
                                              <v-scale-transition>
                                                <v-icon v-if="hover">mdi-lead-pencil</v-icon>
                                              </v-scale-transition>
                                            </v-btn>
                                            <v-btn icon color="info" @click="downloadDocument(document._id)">
                                              <v-scale-transition>
                                                <v-icon v-if="hover">mdi-cloud-download</v-icon>
                                              </v-scale-transition>
                                            </v-btn>
                                            <v-btn icon color="error" @click="deleteDocument(document._id, item.name)">
                                              <v-scale-transition>
                                                <v-icon v-if="hover">mdi-delete-circle</v-icon>
                                              </v-scale-transition>
                                            </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-hover>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs>
                        </v-card-text>
                      </v-card>
                    </v-expand-transition>
                  </v-card>
                </v-card>
              </template>
              <template v-if="item.type === 15">
                <div class="proptable">
                  <table width="100%" border="0" class="dense_table">
                    <thead>
                      <th id="" width="20%" v-for="(grid, index) in item.default_value.grid_header.labels" :key="index">
                        {{ grid.label}}
                      </th>
                    </thead>
                    <tbody>
                      <tr v-for="(row, rIndex) in item.default_value.grid_data.values" :key="rIndex">
                        <td v-for="(grid, dIndex) in item.default_value.grid_header.labels" :key="dIndex" width="20%" :style="dIndex === 0 ? 'padding:0px': 'padding:0px;border: none'">
                          <template v-if="dIndex === 0">
                            {{ row.name }}
                          </template>
                          <template v-else>
                            <tr>
                              <td width="60%" class="pa-1">
                                <v-text-field dense flat full-width v-model="row[`compValue_${dIndex}`]" solo hide-details></v-text-field>
                              </td>
                              <td  width="30%" class="pa-1">
                                <v-select dense flat full-width hide-details :items="inventCompItem" v-model="row[`compSelect_${dIndex}`]"></v-select>
                              </td>
                            </tr>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </v-col>
          </template>
          <v-dialog v-model="updateFileDialog" width="500">
            <v-card>
              <v-form ref="updateAttachment">
                <v-card-title class="headline grey lighten-2 pa-2" primary-title> {{$t('updateDocument')}}</v-card-title>
                <v-card-text class="mt-2">
                  <p>{{$t('document')}}: <strong>{{ updateDocument.file_original_name }}</strong></p>
                  <v-file-input class="mt-2" v-model="updateFilePicker" :label="$t('document')" outlined dense></v-file-input>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="updateDocumentHandler" :loading="fileLoader">{{$t('update')}}</v-btn>
                  <v-btn color="error" @click="updateFileDialog = false, $refs.updateAttachment.reset()">{{$t('close')}}</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-row>
        <v-divider class="mt-3 mb-1" v-if="!fromPanel"></v-divider>
        <v-row v-if="!fromPanel">
          <v-col class="text-center">
            <v-btn color="success" :loading="loading" @click="saveUpdateChecklistHandler(true)" class="mr-1">{{$t('save')}}</v-btn>
            <v-btn color="primary" :loading="loading" @click="saveUpdateChecklistHandler(true, true)" class="mr-1">{{$t('saveAndClose')}}</v-btn>
          </v-col>
        </v-row>
      <!-- </v-container> -->
    </v-form>
  <!-- </v-app> -->
</template>
<script>
import { componentStatusList } from '../../utils/support_list'
// eslint-disable-next-line camelcase
import { checklistApi, document_url } from '@/plugins/axios_settings'
import dataGridConstants from '@/mixins/data_grid_component'
export default {
  mixins: [dataGridConstants],
  props: {
    checklistTemplateObj: {
      type: Object
    },
    token: {
      type: String,
      default: () => ''
    },
    panelFields: {
      type: Array,
      default: () => []
    },
    isPanelFieldsRender: {
      type: Boolean,
      default: () => false
    },
    panelForm: {
      type: String,
      default: () => ''
    },
    fromPanel: {
      type: Boolean,
      default: false
    },
    isBooking: {
      type: Boolean,
      default: false
    },
    dataValue: Object
  },
  data () {
    return {
      reRender: 0,
      autoCompleteRender: 0,
      deleteDocumentId: 0,
      deleteDocumentName: '',
      statusList: componentStatusList,
      updateFileDialog: false,
      updateDocument: {},
      updateFilePicker: [],
      updateDocumentProp: '',
      fileLoader: false,
      aliasFieldsList: [],
      fieldsData: {},
      listOfFields: [],
      listOfDocuments: {},
      loading: false,
      progress_loading: false
    }
  },
  computed: {
    fieldsList: {
      get () {
        return this.aliasFieldsList
      },
      set (val) {
        this.aliasFieldsList = val
      }
    }
  },
  components: {
    'form-render': () => import('./ChecklistForm.vue')
  },
  mounted () {
    this.fields = {}
    if (!this.panelForm) {
      this.getFormProperties()
    }
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === 'documents') {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteMsg' })
        this.$store.commit('hideDialog')
        const documents = this.$formatter.cloneVariable(this.listOfDocuments[this.deleteDocumentName])
        if (this.$formatter.isArrayHasData(documents)) {
          const index = documents.findIndex(x => x._id === this.deleteDocumentId)
          documents.splice(index, 1)
          this.listOfDocuments[this.deleteDocumentName] = documents
          this.reRender++
        }
      }
    })
    this.$root.$on('callRerender', () => {
      this.reRender++
    })
    this.$root.$on('saveChecklists', () => {
      this.saveUpdateChecklistHandler(true, true)
    })
    // if (!this.checklistTemplateObj.checklist_id && !this.isBooking) this.saveUpdateChecklistHandler(false, true)
  },
  methods: {
    initWithValues () {
      this.manipulateFields()
      this.manipulateFieldsData()
    },
    getFormProperties () {
      this.progress_loading = true
      if (this.isPanelFieldsRender) {
        this.listOfFields = this.$formatter.cloneVariable(this.panelFields)
        this.initWithValues()
      } else {
        checklistApi.get(`/formproperties/get_by_form/${this.checklistTemplateObj.form_id}`)
          .then((result) => {
            if (result && result.data) {
              this.listOfFields = result.data
              if (this.checklistTemplateObj.checklist_id) {
                // this.fieldsData = {
                //   data: JSON.stringify(this.dataValue)
                // }
                this.getFieldsData()
                this.getDocumentsHandler()
              } else this.initWithValues()
            }
          })
      }
    },
    /* TO MODIFY AND UPDATE LIST OF FIELDS */
    manipulateFields () {
      this.aliasFieldsList = this.listOfFields
      const getFields = this.$formatter.cloneVariable(this.aliasFieldsList)
      const list = getFields
      for (let i = 0; i < list.length; i++) {
        if (!this.isPanelFieldsRender) list[i].default_value = list[i].default_value ? JSON.parse(list[i].default_value) : null
        if (list[i].type === 3 || list[i].type === 4 || list[i].type === 5) {
          if (list[i].default_value.is_multiselect) {
            this.fields[list[i].name] = []
            if (list[i].default_value.default_value) this.fields[list[i].name].push(list[i].default_value.default_value)
          } else if (list[i].type !== 3) this.fields[list[i].name] = list[i].default_value.default_value
        } else if (list[i].type === 9) {
          this.listOfDocuments[list[i].name] = []
          this.fields[list[i].name] = { }
          this.fields[list[i].name].attachments = []
        } else if (list[i].type === 10) {
          this.listOfDocuments[list[i].name] = []
          this.fields[list[i].name] = { answer: '', comments: '', attachments: [] }
        } else if (list[i].type === 19) {
          this.listOfDocuments[list[i].name] = []
          this.fields[list[i].name] = { answer: '', comments: '', attachments: [], date_menu: '', date: '', signature: '' }
        } else if (list[i].type === 15) {
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
              switch (list[i].default_value.grid_header.labels.length) {
                case 2 :
                  list[i].tableWidth = 98
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 8
                  break
                case 3 :
                  list[i].tableWidth = 150
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                case 4 :
                  list[i].tableWidth = 210
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                default:
                  list[i].tableWidth = 400
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
              }
              break
            case 'sm':
              switch (list[i].default_value.grid_header.labels.length) {
                case 2 :
                  list[i].tableWidth = 99
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                case 3 :
                  list[i].tableWidth = 130
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                case 4 :
                  list[i].tableWidth = 140
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                default:
                  list[i].tableWidth = 150
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
              }
              break
            case 'md':
              switch (list[i].default_value.grid_header.labels.length) {
                case 2 :
                  list[i].tableWidth = 99
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                case 3 :
                  list[i].tableWidth = 130
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                case 4 :
                  list[i].tableWidth = 110
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                default:
                  list[i].tableWidth = 100
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
              }
              break
            case 'lg':
              switch (list[i].default_value.grid_header.labels.length) {
                case 2 :
                  list[i].tableWidth = 60
                  list[i].headerWidth = 25
                  list[i].nameWidth = 35
                  break
                case 3 :
                  list[i].tableWidth = 130
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                case 4 :
                  list[i].tableWidth = 99
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                default:
                  list[i].tableWidth = 99
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
              }
              break
            case 'xl':
              switch (list[i].default_value.grid_header.labels.length) {
                case 2 :
                  list[i].tableWidth = 60
                  list[i].headerWidth = 25
                  list[i].nameWidth = 35
                  break
                case 3 :
                  list[i].tableWidth = 80
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                case 4 :
                  list[i].tableWidth = 99
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 10
                  break
                default:
                  list[i].tableWidth = 99.9
                  list[i].headerWidth = 22.5
                  list[i].nameWidth = 9.9
                  break
              }
              break
          }
        } else if (list[i].type === 18) {
          const defaultValue = list[i].default_value
          if (defaultValue.grid_data && defaultValue.grid_data.labels) list[i].showSum = this.checkAndGetSumRowData(defaultValue.grid_data.labels)
          if (defaultValue.grid_data && Object.keys(defaultValue.grid_data.data).length === 0) {
            list[i].default_value.grid_data.data = this.addGridFirstRow(+list[i].label, defaultValue.grid_data.data)
          }
        } else this.fields[list[i].name] = list[i].default_value ? list[i].default_value.default_value : null
        // separete panel fields and other fields
        const getPanelFieldIndex = list.findIndex(x => x._id === list[i].default_value.panelId)
        if (getPanelFieldIndex >= 0) {
          list[i].isPanelField = true
          if (!list[getPanelFieldIndex].panel_fields) list[getPanelFieldIndex].panel_fields = []
          list[getPanelFieldIndex].panel_fields.push(Object.assign({ ...list[i] }, { isPanelField: false }))
        }
      }
      this.aliasFieldsList = list
      // if (this.dataValue && this.$formatter.isEmptyObject(this.dataValue)) this.fields = this.$formatter.isEmptyObject(this.dataValue)
    },
    /* TO MODIFY AND UPDATE LIST OF FIELD\'S DATA */
    manipulateFieldsData () {
      const fields = this.fieldsData
      const data = fields.data ? JSON.parse(fields.data) : null
      // Following logic added for assigning values to file input
      const tempFieldsWithData = Object.assign({ ...this.fields }, { ...data })
      for (const i in tempFieldsWithData) {
        this.aliasFieldsList.forEach((element) => {
          if (i === element.name && element.type === 9) tempFieldsWithData[i] = []
        })
      }
      this.fields = this.$formatter.cloneVariable(tempFieldsWithData)
      // To be called when it uses live API records
      if (data && data.values && data.values.length > 0) {
        this.aliasFieldsList.forEach((element, index) => {
          data.values.forEach(values => {
            if (element.name === values.name) {
              element.default_value.options = []
              element.default_value.options = values.options
              this.$set(this.aliasFieldsList, index, { ...element })
            }
          })
        })
      }
      // To load all the mapped data to grid list
      this.aliasFieldsList.forEach((element, index) => {
        if (element.type === 12) {
          element.default_value.grid_data.data = (data && data[element.name]) ? data[element.name] : {}
          this.$set(this.aliasFieldsList, index, { ...element })
        }
        // if (element.type === 8) {
        //   this.fields[element.name] = []
        // }
        if (element.type === 13) {
          const reference = `signaturePad_${element._id}`
          setTimeout(() => {
            if (data && data[element.name]) this.$refs[reference][0].fromDataURL(data[element.name])
            // this.$refs[reference][0].lockSignaturePad()
          }, 100)
        }
        if (element.type === 15) {
          element.default_value = (data && data[element.name]) ? (data && data[element.name]) : element.default_value
          this.$set(this.aliasFieldsList, index, { ...element })
        }
        if (element.type === 18) {
          element.default_value.grid_data.data = (data && data[element.name]) ? this.$formatter.cloneVariable(data[element.name]) : {}
          if (element.default_value.grid_data && Object.keys(element.default_value.grid_data.data).length === 0) {
            element.default_value.grid_data.data = this.addGridFirstRow(+element.label, element.default_value.grid_data.data)
          } else if (element.default_value.grid_data && Object.keys(element.default_value.grid_data.data).length > 0) {
            // if already has data and td length differs from value then add extra empty value object
            const gridData = element.default_value.grid_data.data
            const length = !this.$formatter.isEmptyObject(gridData) ? Object.keys(gridData).length : 0
            if (length) {
              const lastRowString = `row_${length - 1}`
              const rowCols = element.default_value.grid_data.data[lastRowString]
              const labels = element.default_value.grid_data.labels
              // compare rowcols length with header lables length
              if (labels && labels.length > rowCols.length) {
                const emptyValueArray = [{ value: '' }]
                Object.keys(gridData).forEach((key) => {
                  gridData[key] = this.$formatter.cloneVariable([...gridData[key], ...emptyValueArray])
                })
              } else if (labels && labels.length < rowCols.length) {
                // if less then data remove not implemented for now
              }
            }
          }
          this.$set(this.aliasFieldsList, index, { ...element })
        }
      })
      this.progress_loading = false
    },
    getValidation (validate, type = null) {
      if (validate === 'required') {
        if (type) return this.$_multiSelectValidation
        else return this.$_requiredValidation
      } else return null
    },
    parseDate (date) {
      return this.$formatter.formatDate(date, 'YYYY-MM-DD', 'DD.MM.YYYY')
    },
    getValuesFromAPI (searchText, value, index) {
      let options = []
      this.$execute.execute('get', value.apiUrl, { headers: '' }).then(response => {
        if (value.objectName) options = response.data[value.objectName]
        else options = response.data
        const fieldItem = this.aliasFieldsList[index]
        fieldItem.default_value.options = []
        fieldItem.default_value.options = options
        this.$set(this.aliasFieldsList, index, { ...fieldItem })
      })
    },
    uploadDocumentHandler (propId, propName, files) {
      if (this.checklistTemplateObj.checklist_id) {
        this.fields[propName].attachments = null
        var formData = new FormData()
        if (files != null && files.length > 0) {
          for (const file of files) {
            formData.append(file.name, file)
          }
          checklistApi.post(`documents/upload?checklist_id=${this.checklistTemplateObj.checklist_id}&property_id=${propId}`, formData)
            .then((response) => {
              if (response.data) {
                this.listOfDocuments[propName].push(...response.data)
                if (this.listOfDocuments[propName] && this.listOfDocuments[propName].length > 0) {
                  this.listOfDocuments[propName].forEach(element => {
                    element.imgObj = this.mimeTypeOfAttachment(element)
                  })
                }
                this.reRender++
                this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
              }
            })
        }
      }
    },
    updateDocumentHandler () {
      if (this.updateFilePicker) {
        const formData = new FormData()
        formData.append(this.updateFilePicker.name, this.updateFilePicker)
        this.fileLoader = true
        checklistApi.post(`documents/update_attachment/${this.updateDocument._id}`, formData, this.token)
          .then((response) => {
            if (response.data) {
              const documents = this.$formatter.cloneVariable(this.listOfDocuments[this.updateDocumentProp])
              if (this.$formatter.isArrayHasData(documents)) {
                const index = documents.findIndex(x => x._id === this.updateDocument._id)
                documents.splice(index, 1, response.data[0])
                this.listOfDocuments[this.updateDocumentProp] = documents
                if (this.listOfDocuments[this.updateDocumentProp] && this.listOfDocuments[this.updateDocumentProp].length > 0) {
                  this.listOfDocuments[this.updateDocumentProp].forEach(element => {
                    element.imgObj = this.mimeTypeOfAttachment(element)
                  })
                }
                this.reRender++
              }
            }
          })
          .finally(() => {
            this.$refs.updateAttachment.reset()
            this.fileLoader = false
            this.updateFileDialog = false
          })
      }
    },
    getFieldsData () {
      checklistApi.get(`/checklists/${this.checklistTemplateObj.checklist_id}`)
        .then((result) => {
          if (result && result.data) {
            this.fieldsData = result.data
          }
        })
        .finally(() => {
          this.initWithValues()
        })
    },
    getDocumentsHandler () {
      checklistApi.get(`/documents/get_checklist_documents/${this.checklistTemplateObj.checklist_id}`)
        .then((response) => {
          if (response && response.data) {
            const result = response.data
            if (result.length > 0) {
              result.forEach(element => {
                element.imgObj = this.mimeTypeOfAttachment(element)
              })
            }
            setTimeout(() => {
              const getDocuments = result ? this.$formatter.groupBy(result, 'property_name') : {}
              this.listOfDocuments = Object.assign({ ...this.listOfDocuments }, { ...getDocuments })
              this.reRender = this.reRender + 1
            }, 200)
          }
        })
    },
    clearThisSignature (id) {
      const self = this
      const reference = `signaturePad_${id}`
      setTimeout(() => {
        self.$refs[reference][0].clearSignature()
      }, 100)
    },
    saveUpdateChecklistHandler (checkValidation, canClose) {
      const condition = checkValidation ? this.$refs.checkListForm.validate() : true
      if (condition) {
        this.loading = true
        const model = { title: '', data: [], form_id: this.checklistTemplateObj.form_id, _id: this.checklistTemplateObj.checklist_id }
        // eslint-disable-next-line
        this.fields['values'] = []
        this.aliasFieldsList.forEach((element, index) => {
          if (element.type === 5 && element.default_value && element.default_value.isAPI) {
            if (this.fields[element.name]) {
              const fieldsValue = this.fields[element.name]
              const key = element.default_value.name
              // eslint-disable-next-line
              this.fields['values'].push({ name: element.name, options: [{ [key]: fieldsValue }] })
            }
          } else if (element.type === 12) {
            this.fields[element.name] = element.default_value.grid_data.data
          } else if (element.type === 18) {
            this.fields[element.name] = element.default_value.grid_data.data
          } else if (element.type === 1 && (element.default_value && element.default_value.use_as_title)) {
            model.title = this.fields[element.name]
          } else if (element.type === 13) {
            const reference = `signaturePad_${element._id}`
            if (this.$refs[reference]) {
              const { isEmpty, data } = this.$refs[reference][0].saveSignature()
              if (isEmpty) this.fields[element.name] = ''
              else this.fields[element.name] = data
            } else {
              this.fields[element.name] = ''
            }
          } else if (element.type === 15) {
            this.fields[element.name] = element.default_value
          } else if (element.type === 3) {
            if (element.default_value.checkboxtype !== 1 && this.fields[element.name] && this.fields[element.name].length) {
              this.fields[element.name] = this.fields[element.name].filter(x => x)
            }
          }
          // else if (element.type === 14) {
          //  let ref = `${index}_${element.name}`
          // }
        })
        model.data = JSON.stringify(this.fields)
        const method = model._id ? 'put' : 'post'
        const url = model._id ? `checklists/${model._id}` : 'checklists'
        checklistApi[method](url, model)
          .then(response => {
            this.$emit('update', { data: response.data, status: 'success', action: model._id ? 'update' : 'add', canClose })
          }).catch((error) => {
            this.$emit('update', { data: error, status: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    downloadDocument (id) {
      checklistApi.get(`documents/download/${id}`, this.token).then(response => {
        const filename = response.data
        window.open(`${checklistApi.defaults.baseURL}files/download?fileName=${filename}`, '_blank')
      })
    },
    editDocument (document, propName) {
      this.updateFileDialog = true
      this.updateDocument = document
      this.updateDocumentProp = propName
    },
    deleteDocument (id, propName, index) {
      this.deleteDocumentId = id
      this.deleteDocumentName = propName
      const payload = { url: `documents/${id}`, module: 'documents', fromCheckList: true }
      this.$store.commit('showDeleteDialog', payload)
      // checklistApi.delete(`/documents/${id}`, this.token)
      //   .then(response => {
      //     this.getDocumentsHandler()
      //   })
    },
    closeHandler () {
      this.$emit('back')
    },
    addnewLineOnGrid (index, size) {
      const addLength = Object.keys(this.aliasFieldsList[index].default_value.grid_data.data).length
      const getGrid = this.aliasFieldsList[index].default_value.grid_data.data
      const gridData = []
      for (let i = 0; i < size; i++) {
        gridData.push({ value: '' })
      }
      const resultGrid = Object.assign({ ...getGrid }, { [`row_${addLength}`]: [...gridData] })
      this.aliasFieldsList[index].default_value.grid_data.data = resultGrid
    },
    // eslint-disable-next-line camelcase
    mimeTypeOfAttachment ({ file_original_name, file_guid }) {
      let image = ''
      const ext = file_original_name.split('.').pop()
      const extension = ext ? ext.toString().toLowerCase() : ''
      switch (extension) {
        case 'png':
        case 'jpeg':
        case 'jpg':
        case 'gif':
          // eslint-disable-next-line camelcase
          image = { icon: 'mdi-file-image', color: null, url: `${document_url}/Documents/${file_guid}?t=${new Date().getTime()}` }
          break
        default:
          image = { icon: 'mdi-file-document', color: null, url: null }
          break
      }
      return image
    },
    checkAndGetSumRowData (labels) {
      const obj = { showSumRow: false, sumColIndexes: [] }
      labels.forEach((label, index) => {
        const { field } = label
        if (field && field.typedata && field.typedata.isshowsum) {
          obj.showSumRow = true
          obj.sumColIndexes.push(index)
        }
      })
      return obj
    },
    addGridFirstRow (size, dataRowValues) {
      const getGrid = dataRowValues
      const gridData = []
      for (let i = 0; i < size; i++) {
        gridData.push({ value: '' })
      }
      return Object.assign({ ...getGrid }, { [`row_${0}`]: [...gridData] })
    },
    getGridDataValuesTotal (sumIndex, data) {
      let total = 0
      const rows = Object.keys(data)
      const allRowValues = []
      rows.forEach(key => {
        var obj = data[key].find((x, y) => y === sumIndex)
        if (obj) allRowValues.push(parseFloat(obj.value || 0))
      })
      total = this.$formatter.getSum(allRowValues)
      return total
    },
    hasCommentOrAttachment (surveyObj, item) {
      let hasData = surveyObj && (surveyObj.comments || (this.listOfDocuments[item.name] && this.listOfDocuments[item.name].length > 0))
      hasData = Boolean(hasData)
      if (hasData) item.expandsurveysection = true
      return hasData
    },
    getCols (item) { // for component 19
      const flagObj = item.default_value.checkboxFlags
      return (flagObj.signature || flagObj.date) ? (flagObj.signature && flagObj.date) ? 8 : 10 : 12
    },
    getCheckboxLabelWidth (options) {
      options.label = options.label || ''
      return (options.label.length + 100) > 200 ? 350 : (options.label.length + 100)
    }
  },
  beforeDestroy () {
    this.$root.$off('callRerender')
    this.$root.$off('saveChecklists')
  }
}
</script>
<style>
.attachment-icon-delete {
  float: right !important;
}
.signature--pad {
  border: 1px solid grey;
}
</style>
